export function getNextStepLink(item) {
  if (item.application_type == 'Subsidy') {
    if (item.submitted_at) {
      return {
        name: 'ManageChildSubsidy',
        params: {
          subsidyId: item.id,
        },
      };
    } else {
      return {
        name: 'SubsidyContinue',
        params: {
          subsidyId: item.id,
        },
      };
    }
  } else if (item.application_type == 'FamilySubsidy') {
    if (item.submitted_at) {
      return {
        name: 'ManageFamilySubsidy',
        params: {
          id: item.id,
        },
      };
    } else {
      return {
        name: 'FamilySubsidyContinue',
        params: {
          id: item.id,
        },
      };
    }
  }
}
