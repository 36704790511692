import axios from 'axios';
import { EventBus } from '@/plugins/event-bus';

export const handleError = (error) => {
  // if (process.env.NODE_ENV === 'test') throw error;
  EventBus.$emit('error', error);
};

export const asyncApi = ({ endpoint, payloadKey }) => {
  return {
    endpoint,
    payloadKey,

    baseUrl(id = null) {
      let url = `${window.api_url}${this.endpoint}`;
      if (id) url += `/${id}`;
      return url;
    },

    createPayload(value) {
      if (!this.payloadKey) return { data: value };

      return { [this.payloadKey]: value };
    },

    async get(id, params = {}, successCallback = null, failureCallback = null) {
      return axios
        .get(this.baseUrl(id), { params })
        .then((response) => {
          if (successCallback) successCallback(response);
          return response;
        })
        .catch((error) => {
          if (failureCallback) return failureCallback(error);
          return handleError(error);
        });
    },

    async create(createObject, successCallback = null, failureCallback = null) {
      return axios
        .post(this.baseUrl(), this.createPayload(createObject))
        .then((response) => {
          if (successCallback) successCallback(response);
          return response;
        })
        .catch((error) => {
          if (failureCallback) return failureCallback(error);
          return handleError(error);
        });
    },

    async index(params = {}, successCallback = null, failureCallback = null) {
      return axios
        .get(this.baseUrl(), { params })
        .then((response) => {
          if (successCallback) successCallback(response);
          return response;
        })
        .catch((error) => {
          if (failureCallback) return failureCallback(error);
          return handleError(error);
        });
    },

    async update(id, updateObject, params = {}, successCallback = null, failureCallback = null) {
      return axios
        .patch(this.baseUrl(id), this.createPayload(updateObject), { params })
        .then((response) => {
          if (successCallback) successCallback(response);
          return response;
        })
        .catch((error) => {
          if (failureCallback) return failureCallback(error);
          return handleError(error);
        });
    },

    async destroy(id, successCallback = null, failureCallback = null) {
      return axios
        .delete(this.baseUrl(id))
        .then((response) => {
          if (successCallback) successCallback(response);
          return response;
        })
        .catch((error) => {
          if (failureCallback) return failureCallback(error);
          return handleError(error);
        });
    },
  };
};
