<template>
  <v-btn
    @click="emit('click', props.value)"
    :data-cy="`${props.value}_tab`"
    :variant="props.currentValue != props.value ? 'outlined' : 'flat'"
    class="focus-very-visible me-3 my-1"
    color="primary"
  >
    {{ t(label) }}
  </v-btn>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  currentValue: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['click']);
</script>
