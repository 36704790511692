<template>
  <v-row
    v-if="props.label"
    dense
  >
    <v-col class="y-0 c-black fs-16 ta-left">
      {{ t(props.label) }}
    </v-col>
  </v-row>
  <v-row dense>
    <v-col>
      <v-autocomplete
        v-model="location"
        v-model:search="searchText"
        @keyup.enter="handleEnter"
        @update:model-value="handleUpdate"
        :id="props.id"
        :aria-label="ariaLabel"
        :items="options"
        :placeholder="t('Enter a location')"
        class="dark-placeholder mt-0 ta-left"
        color="primary"
        data-cy="enter-location-auto"
        item-title="value"
        item-value="id"
        variant="outlined"
        auto-select-first
        autofocus
        hide-details
        hide-no-data
        no-filter
        return-object
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { getSuggestions } from '@/plugins/get-suggestions';
import { useStore } from 'vuex';

const store = useStore();
const { t } = useI18n();

const location = ref(null);
const options = ref([]);
const searchText = ref('');
const emit = defineEmits(['change', 'done']);

const props = defineProps({
  ariaLabel: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
});

function handleEnter() {
  emit('done', location.value);
}

function handleUpdate() {
  emit('change', location.value);
}

function handleSearchInputChange(newVal) {
  if (!newVal || newVal.length <= 2) return;

  getSuggestions(newVal, {
    resultsForState: store.state.config.operating_states,
    operatingCountry: store.state.config.operating_country,
  })
    .then((res) => {
      options.value = res ?? [];
    })
    .catch((err) => {
      console.error('Location suggestion error:', err);
    });
}

watch(searchText, handleSearchInputChange);
</script>
