<template>
  <FormQuestion
    @back="$emit('back')"
    @click:notes="$emit('click:notes')"
    @next="$emit('next')"
    :bold="question.is_bold"
    :class="landscape ? 'my-0' : 'mb-6'"
    :color="color"
    :conditional="question.conditions.length > 0"
    :dense="dense"
    :display-notes="displayNotes"
    :elevation="elevation"
    :header="header"
    :hide-actions="hideActions"
    :landscape="landscape"
    :liquid-rendering-enabled="liquidRenderingEnabled"
    :mandatory="question.mandatory"
    :model-value="modelValue"
    :next-disabled="nextDisabled"
    :notes-count="notesCount"
    :outlined="outlined"
    :paddingless="paddingless"
    :passing="answerScore() > 0"
    :processing="processing"
    :published="question.published"
    :reversible="reversible"
    :show-indicators="showIndicators"
    :show-passing="showPassing"
    :subtitle="question.subtitle"
    :tile="tile"
    :title="question.title"
    :verification="question.verification"
    :very-dense="veryDense"
  >
    <template
      v-if="inlineVerification && question.verification"
      #append-header
    >
      <v-btn
        @click="verificationIsVisible = !verificationIsVisible"
        :prepend-icon="verificationIsVisible ? 'expand_less' : 'expand_more'"
        class="px-0"
        color="primary"
        variant="text"
      >
        {{ $t('Upload verification documents') }}
      </v-btn>
    </template>

    <template v-if="!question.is_section && !hideField">
      <v-row
        class="d-flex align-end"
        dense
      >
        <DefinedPropertyElement
          v-for="attribute in syncedAttributes"
          :key="attribute.name"
          :attribute-name="attribute.name"
          :cols="attributeProperties(attribute.name).type === 'boolean' ? '12' : undefined"
          :custom-attribute="maybeCustomAttribute(attribute.name)"
          :properties="attributeProperties(attribute.name)"
        >
          <template #default="{ customAttribute, properties }">
            <v-row>
              <LabeledControl
                :mandatory="isMandatory(attribute)"
                :message="messageForAttribute(properties)"
              >
                <template v-if="customAttribute">
                  <Field
                    v-model="modelValue.custom[customAttribute]"
                    @change="handleChange('change', $event, attribute)"
                    @change:input="handleChange('change:input', $event, attribute)"
                    :booleans-as-checkboxes="question.enable_display_booleans_as_checkboxes"
                    :condensed="condensed || syncedAttributes.length > 1"
                    :dense="dense"
                    :false-label="question.false_label"
                    :landscape="landscape"
                    :mandatory="question.mandatory"
                    :multiple-boolean-fields="multipleBooleanFields"
                    :processing="processing"
                    :properties="properties"
                    :readonly="readonly"
                    :sibling-values="modelValue.custom"
                    :true-label="question.true_label"
                    :very-dense="veryDense"
                  />
                </template>

                <template v-else>
                  <Field
                    v-model="modelValue[attribute.name]"
                    @change="handleChange('change', $event, attribute)"
                    @change:input="handleChange('change:input', $event, attribute)"
                    :booleans-as-checkboxes="question.enable_display_booleans_as_checkboxes"
                    :condensed="condensed || syncedAttributes.length > 1"
                    :dense="dense"
                    :false-label="question.false_label"
                    :landscape="landscape"
                    :mandatory="question.mandatory"
                    :multiple-boolean-fields="multipleBooleanFields"
                    :processing="processing"
                    :properties="properties"
                    :readonly="readonly"
                    :sibling-values="modelValue"
                    :true-label="question.true_label"
                    :very-dense="veryDense"
                  />
                </template>
              </LabeledControl>
            </v-row>
          </template>
        </DefinedPropertyElement>
      </v-row>
    </template>

    <template
      v-if="inlineVerification && question.verification"
      #append-content
    >
      <div
        v-show="verificationIsVisible"
        class="px-0 py-6"
      >
        <div
          v-if="question.verification_title || question.verification_subtitle"
          class="mb-2"
        >
          <div
            v-if="question.verification_title"
            v-text="question.verification_title"
            class="fs-16 fw-500"
          />

          <div
            v-if="question.verification_subtitle"
            v-text="question.verification_subtitle"
            class="fs-14 c-light-black"
          />
        </div>

        <AttachmentUploader
          @uploaded="$emit('change:attachments')"
          :ref="['uploader', question.id].join('')"
          :owner="owner"
        />

        <div
          v-show="localAttachments.length > 0"
          class="mt-4"
        >
          <AttachmentList
            @change="$emit('change:attachments')"
            @delete="$emit('change:attachments')"
            :attachments="localAttachments"
            :processing="processing"
            status
          />
        </div>
      </div>
    </template>
  </FormQuestion>
</template>

<script setup>
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import DefinedPropertyElement from '@/shared/components/DefinedPropertyElement.vue';
import Field from '@/shared/components/form/Field.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import { validateInputAgainstAttributeMask } from '@/shared/services/MaskValidator';

const props = defineProps({
  attachmentGroupId: {
    type: String,
    default: null,
  },
  attachmentOwnerId: {
    type: String,
    default: null,
  },
  attachmentOwnerType: {
    type: String,
    default: null,
  },
  attachmentTagsSupplements: {
    type: Array,
    default: () => [],
  },
  attachments: {
    type: Array,
    default: () => [],
  },
  attachmentStatus: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'transparent',
  },
  condensed: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  displayAnswer: {
    type: Boolean,
    default: false,
  },
  displayNotes: {
    type: Boolean,
    default: false,
  },
  veryDense: {
    type: Boolean,
    default: false,
  },
  elevation: {
    type: Number,
    default: 0,
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  liquidRenderingEnabled: {
    type: Boolean,
    default: true,
  },
  header: {
    type: String,
    default: '',
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  hideField: {
    type: Boolean,
    default: false,
  },
  showIndicators: {
    type: Boolean,
    default: false,
  },
  showPassing: {
    type: Boolean,
    default: false,
  },
  inlineVerification: {
    type: Boolean,
    default: false,
  },
  landscape: {
    type: Boolean,
    default: false,
  },
  notesCount: {
    type: Number,
    default: 0,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  paddingless: {
    type: Boolean,
    default: false,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  question: {
    type: Object,
    default: () => ({}),
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  reversible: {
    type: Boolean,
    default: true,
  },
  schema: {
    type: Object,
    default: () => ({}),
  },
  tile: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({ type: Object });

const emit = defineEmits([
  'back',
  'change',
  'change:attachments',
  'change:input',
  'click:notes',
  'next',
]);

const localAttachments = ref([]);
const verificationIsVisible = ref(false);

const allBooleanFields = computed(() => {
  return syncedAttributes.value.every((attribute) => {
    return attributeProperties(attribute.name).type === 'boolean';
  });
});

const mandatoryAttributes = computed(() => {
  if (props.question.mandatory) return syncedAttributes.value;

  return props.question.mandatory_attributes || [];
});

const multipleBooleanFields = computed(() => {
  return syncedAttributes.value.length > 1 && allBooleanFields.value;
});

const nextDisabled = computed(() => {
  return mandatoryAttributes.value.some((attribute) => {
    let value;

    if (attribute.name?.includes('custom.')) {
      value = modelValue.value.custom[attribute.name.split('.')[1]];
    } else {
      value = modelValue.value[attribute.name];
    }

    if (value == null || value.toString().length === 0) return true;

    const properties = attributeProperties(attribute.name);

    if (Array.isArray(value)) {
      if (value.length === 0) return true;

      const itemsProperties = properties?.items?.properties;

      return value.some((item) => {
        const entries = Object.entries(item);

        if (entries.length === 0) return true;
        if (entries.some((value) => value[1] == null || value[1].toString().length === 0))
          return true;

        if (!itemsProperties) return false;

        const invalidEntries = entries.filter((entry) => {
          const itemProperties = itemsProperties[entry[0]];
          const itemValue = entry[1];
          return !validateInputAgainstAttributeMask(itemProperties, itemValue);
        });

        if (invalidEntries.length !== 0) return true;

        return false;
      });
    }

    return !validateInputAgainstAttributeMask(properties, value);
  });
});

const owner = computed(() => {
  return {
    group_id: props.attachmentGroupId,
    type: props.attachmentOwnerType,
    id: props.attachmentOwnerId,
    tag: props.question.id,
    tags: [props.question.id, props.attachmentOwnerId].concat(props.attachmentTagsSupplements),
  };
});

const syncedAttributes = computed(() => {
  if (props.question.synced_attributes.length > 0) return props.question.synced_attributes;

  return [{ name: props.question.synced_attribute }];
});

watch(
  () => props.attachments,
  () => {
    localAttachments.value =
      props.attachments?.filter((attachment) => attachment.tag === props.question.id) || [];
  },

  { immediate: true },
);

onMounted(() => {
  verificationIsVisible.value = localAttachments.value.length > 0;
});

function answerScore() {
  if (!modelValue.value) return;

  let answerScore = 0;

  if (syncedAttributes.value && props.question?.answer_key) {
    syncedAttributes.value.forEach((attribute) => {
      const customAttribute = maybeCustomAttribute(attribute.name);
      const answerValue = customAttribute
        ? modelValue.value.custom[customAttribute]
        : modelValue.value[attribute.name];

      props.question.answer_key.forEach((answerKey) => {
        if (answerKey.synced_attribute !== attribute.name) return;
        if (answerKey.text !== answerValue) return;

        answerScore += answerKey.value;
      });
    });
  }

  return answerScore;
}

function attributeProperties(attributeName) {
  const customAttribute = maybeCustomAttribute(attributeName);

  if (customAttribute) return props.schema.properties.custom.properties[customAttribute] || {};

  return props.schema.properties[attributeName] || {};
}

function handleChange(emitType, value, attribute) {
  let empty = false;

  if (isMandatory(attribute)) empty = value == null || value.toString().length === 0;

  return emit(emitType, {
    attribute,
    value,
    empty,
  });
}

function isMandatory(attribute) {
  return !!mandatoryAttributes.value.find((ma) => ma.name === attribute.name);
}

function messageForAttribute(properties) {
  if (syncedAttributes.value.length < 2) return null;

  const allAttributesAreBoolean = syncedAttributes.value.every((attribute) => {
    return attributeProperties(attribute.name).type === 'boolean';
  });
  if (allAttributesAreBoolean) return null;

  return properties.alias || properties.title;
}

function maybeCustomAttribute(attributeName) {
  if (attributeName?.includes('custom')) return attributeName.split('.')[1];

  return null;
}
</script>
