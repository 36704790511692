<template>
  <ResourceDialog
    @cancel="choiceSelected(false)"
    @save="choiceSelected(true)"
    ref="confirmResourceDialog"
    :cancel-button-text="cancelButtonText"
    :save-button-text="confirmButtonText"
    :title="title"
    cancellable
    scrim
  >
    <template #description>
      <p>
        {{ $t(description) }}
      </p>
    </template>
  </ResourceDialog>
</template>

<script setup>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

defineProps({
  cancelButtonText: {
    type: String,
    default: 'Cancel',
  },
  confirmButtonText: {
    type: String,
    default: 'Confirm',
  },
  title: {
    type: String,
    default: null,
  },
});

let resolveChoice = null;

const confirmResourceDialog = ref(null);
const description = ref('');

defineExpose({ confirmWithText });

function choiceSelected(choiceBoolean) {
  if (!resolveChoice) return;
  resolveChoice(choiceBoolean);

  confirmResourceDialog.value.close();
}

function confirmWithText(confirmationText) {
  confirmResourceDialog.value.open();
  description.value = confirmationText;

  return new Promise((resolve) => {
    resolveChoice = resolve;
  });
}
</script>
