import axios from 'axios';
import { handleError } from '@/shared/services/api';

import seatType from '@/specialist/services/api/subsidy-program/seat-type';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/organization/subsidy_programs/${id}`;
    return `${window.api_url}/organization/subsidy_programs`;
  },

  create(subsidyProgram, success, failure) {
    axios.post(this.baseUrl(), { subsidy_program: subsidyProgram }).then(success).catch(failure);
  },

  destroy(subsidyProgramId, success) {
    axios.delete(this.baseUrl(subsidyProgramId)).then(success);
  },

  get(subsidyProgramId, success) {
    if (!success) return axios.get(this.baseUrl(subsidyProgramId)).catch(handleError);
    return axios.get(this.baseUrl(subsidyProgramId)).then(success).catch(handleError);
  },

  index(params = {}) {
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },

  update(id, subsidyProgram, success, failure) {
    if (!success)
      return axios.patch(this.baseUrl(id), { subsidy_program: subsidyProgram }).catch(handleError);
    axios.patch(this.baseUrl(id), { subsidy_program: subsidyProgram }).then(success).catch(failure);
  },

  advanced_filter: {
    baseUrl(subsidyProgramId) {
      return `${window.api_url}/organization/subsidy_programs/${subsidyProgramId}/advanced_filters`;
    },

    index(subsidyProgramId) {
      return axios.get(this.baseUrl(subsidyProgramId)).catch(handleError);
    },
  },

  deferred_acceptance: {
    baseUrl(subsidyProgramId) {
      return `${window.api_url}/organization/subsidy_programs/${subsidyProgramId}/deferred_acceptance`;
    },

    create(subsidyProgramId, params) {
      return axios.post(this.baseUrl(subsidyProgramId), params).catch(handleError);
    },

    destroy(subsidyProgramId) {
      return axios.delete(this.baseUrl(subsidyProgramId)).catch(handleError);
    },

    get(subsidyProgramId) {
      return axios.get(this.baseUrl(subsidyProgramId)).catch(handleError);
    },

    downloadDebugYamlUrl(subsidyProgramId, id) {
      return `${this.baseUrl(subsidyProgramId)}/download_run_summary?id=${id}`;
    },
  },

  enrollment: {
    baseUrl(id) {
      return `${window.api_url}/organization/subsidy_programs/${id}/enrollments`;
    },

    bulkUpdate(subsidyProgramId, params) {
      return axios.patch(this.baseUrl(subsidyProgramId), params).catch(handleError);
    },
  },

  seat_type: seatType,

  upload: {
    baseUrl(id) {
      return `${window.api_url}/organization/subsidy_programs/${id}/uploads`;
    },

    create(id, upload) {
      return axios.post(this.baseUrl(id), { upload }).catch(handleError);
    },
  },
};
