<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
    :sm="sm"
  >
    <v-row dense>
      <v-col
        v-if="label"
        :class="labelClass"
        cols="12"
      >
        <span>
          {{ $t(label) }}
        </span>

        <RequiredIndicator v-if="mandatory" />
      </v-col>

      <template v-if="description">
        <v-col
          class="fs-16 c-light-black my-1"
          cols="12"
        >
          <span>
            {{ $t(description) }}
          </span>
        </v-col>
      </template>

      <v-col cols="12">
        <MaskedInput
          v-model="localValue"
          @update:model-value="handleUpdateModelValue"
          v-slot="{ inputRef, masked }"
          :mask="mask || schemaMask"
        >
          <v-text-field
            v-model="masked.value"
            @blur="handleBlur"
            :ref="inputRef"
            :aria-label="ariaLabel"
            :autofocus="autofocus"
            :class="inputClass"
            :counter="showCounter && maxLength"
            :density="dense || veryDense ? 'compact' : undefined"
            :disabled="locked"
            :message="$t(message)"
            :prefix="prefix"
            :readonly="readonly"
            :rules="rules"
            :type="type"
            data-cy="labeled-text-field"
            data-testid="labeled-text-field"
            hide-details="auto"
            variant="filled"
            tile
            v-bind="filteredAttrs"
          >
            <template
              v-for="(_, name) in $slots"
              #[name]="slotData"
            >
              <slot
                :name="name"
                v-bind="slotData"
              />
            </template>
          </v-text-field>
        </MaskedInput>
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script setup>
import attrsOmitDataPrefix from '@/shared/utils/filteredAttrs';
import MaskedInput from '@/shared/components/form/MaskedInput.vue';
import propsToRefs from '@/shared/utils/propsToRefs';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import useLabeledField from '@/shared/composables/useLabeledField';
import useSchematizedField from '@/shared/composables/useSchematizedField';
import { useI18n } from 'vue-i18n';
import { onUpdated } from 'vue';

const attrs = useAttrs();
const { t } = useI18n();

const emit = defineEmits(useLabeledField.emits);
defineExpose({ emit }); // Emit is used in composable, this is included for linter
const localValue = defineModel({ type: [String, Number] });

const props = defineProps({
  dividedBottom: Boolean,
  mask: {
    type: String,
    default: null,
  },
  maxLength: {
    type: Number,
    default: null,
  },
  readonly: Boolean,
  showCounter: Boolean,
  type: {
    type: String,
    default: null,
  },
  ...useLabeledField.props,
  ...useSchematizedField.props,
});

const filteredAttrs = ref(attrsOmitDataPrefix(attrs));

const { inputClass, labelClass, handleBlur, handleUpdateModelValue } = useLabeledField(
  ...propsToRefs(props, useLabeledField.paramKeys),
);

const { enabled, ariaLabel, label, locked, schemaMask } = useSchematizedField(
  ...propsToRefs(props, useSchematizedField.paramKeys),
);

const rules = computed(() => {
  const rules = [];

  if (props.mandatory) {
    const rule = (value) => !!value || `${t(label.value)} ${t('is required')}`;
    rules.push(rule);
  }

  if (props.maxLength) {
    const rule = (value) => {
      if (!value) return true;

      return (value && value.length <= props.maxLength) || `${t(label.value)} ${t('is too long')}`;
    };
    rules.push(rule);
  }

  return rules;
});

// ? attrs is not a reactive object, so we update the filteredAttrs ref on every DOM tree update
onUpdated(() => {
  filteredAttrs.value = attrsOmitDataPrefix(attrs);
});
</script>
