import $a from '@/shared/services/assets';
import Api from '@/shared/services/all_bright_finder';
import useEventBus from '@/shared/composables/useEventBus';

export default function useGroupAddressUtils({
  forwardFromHomeAddress,
  group,
  subsidyProgram,
  validateAddressDialog,
}) {
  const ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR =
    'Your address could not be validated. Please check for any errors and retry. If you believe this address to be correct, please contact support for assistance.';
  const eventBus = useEventBus();

  const homeAddress = computed(() => {
    if (!group.value || group.value?.experiencing_homelessness === true) return null;

    return [
      group.value.home_address,
      group.value.home_city,
      group.value.home_state,
      group.value.home_zip,
    ].join(', ');
  });

  const stateOptions = computed(() => {
    if (subsidyProgram.value.state_options?.length > 0) {
      return $a.assets.states.filter((state) => subsidyProgram.value.state_options.includes(state));
    }
    return $a.assets.states;
  });

  const validHomeAddress = computed(() => {
    if (!group.value) return false;
    return (
      group.value.experiencing_homelessness ||
      (group.value.home_address &&
        group.value.home_city &&
        group.value.home_state &&
        group.value.home_zip &&
        group.value.home_zip.length >= 5)
    );
  });

  function maybeSetDefaultCityAndState() {
    if (subsidyProgram.value?.state_options?.length === 1 && !group.value.home_state) {
      [group.value.home_state] = subsidyProgram.value.state_options;
    }
    if (subsidyProgram.value?.city_options?.length === 1 && !group.value.home_city) {
      [group.value.home_city] = subsidyProgram.value.city_options;
    }
  }

  async function updateHomeAddress(uspsData) {
    const address = uspsData.standardizedAddress;
    group.value.home_address = address.firstAddressLine;
    group.value.home_city = address.city;
    group.value.home_state = address.state;
    group.value.home_zip = address.zipCode;
    group.value.home_latitude = uspsData.location.latitude;
    group.value.home_longitude = uspsData.location.longitude;
    await forwardFromHomeAddress();
  }

  function validatedAddressMatches(validated) {
    return (
      group.value.home_zip === validated.zipCode &&
      group.value.home_state.toLowerCase() === validated.state.toLowerCase() &&
      group.value.home_city.toLowerCase() === validated.city.toLowerCase() &&
      group.value.home_address.toLowerCase() === validated.firstAddressLine.toLowerCase()
    );
  }

  // TODO: Test when home address validation is enabled
  async function validateAddress() {
    if (
      group.value.experiencing_homelessness ||
      !subsidyProgram.value.enable_home_address_validation
    ) {
      await forwardFromHomeAddress();
      return;
    }

    const response = await Api.member.address_validation.validate({
      addressLines: [group.value.home_address],
      administrativeArea: group.value.home_state,
      locality: group.value.home_city,
      postalCode: group.value.home_zip,
    });

    if (!response.data) {
      return eventBus.chime('Unknown error occurred attempting to validate your address.');
    }

    const { hasUnconfirmedComponents } = response.data.verdict;
    if (hasUnconfirmedComponents) {
      const allSubpremise = response.data.address.addressComponents.every((addressComponent) => {
        if (addressComponent.confirmationLevel === 'CONFIRMED') return true;

        if (addressComponent.componentType === 'subpremise') return true;

        return false;
      });

      if (!allSubpremise) return eventBus.longChime(ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR);
    }

    const { geocode, uspsData } = response.data;
    if (validatedAddressMatches(uspsData.standardizedAddress)) {
      group.value.home_latitude = geocode.location.latitude;
      group.value.home_longitude = geocode.location.longitude;
      await forwardFromHomeAddress();
      return;
    }

    validateAddressDialog.value?.open({ value: group.value, ...uspsData, ...geocode });

    return true;
  }

  return {
    homeAddress,
    maybeSetDefaultCityAndState,
    stateOptions,
    updateHomeAddress,
    validateAddress,
    validHomeAddress,
  };
}
