<template>
  <v-row
    :dense="$vuetify.display.name === 'xs'"
    class="mt-0"
    style="line-height: normal"
  >
    <v-col
      cols="12"
      sm="3"
    >
      <div class="mb-6">
        <div class="fs-16 fw-600">
          {{ $t('Relationship') }}
        </div>
        <div class="fs-16">
          {{ $t(householdMember.relationship) }}
        </div>
      </div>

      <div>
        <div class="fs-16 fw-600">
          {{ $t('Race') }}
        </div>

        <div class="fs-16">
          {{ race }}
          <span
            v-if="!race"
            class="text-grey"
          >
            &#x2014;
          </span>
        </div>
      </div>
    </v-col>

    <v-col
      class="flex align-center"
      cols="12"
      sm="3"
    >
      <v-row>
        <v-col>
          <v-divider vertical />
        </v-col>
        <v-col cols="10">
          <div class="mb-6">
            <div class="fs-16 fw-600">
              {{ $t('SSN') }}
            </div>

            <div class="fs-16">
              {{ maskSSN(householdMember.ssn) }}
              <span
                v-if="!householdMember.ssn"
                class="text-grey"
              >
                &#x2014;
              </span>
              <v-icon
                @click="toggleSSN"
                @keyup.enter="toggleSSN"
                :icon="showSSN ? 'visibility_off' : 'visibility'"
              />
            </div>
          </div>

          <div>
            <div class="fs-16 fw-600">
              {{ $t('Ethnicity') }}
            </div>

            <div class="fs-16">
              {{ $t(householdMember.ethnicity) }}
            </div>
          </div>
        </v-col>
        <v-col>
          <v-divider vertical />
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      sm="3"
    >
      <div class="mb-6">
        <div class="fs-16 fw-600">
          {{ $t('Sex') }}
        </div>
        <div class="fs-16">
          {{ $t(householdMember.gender) }}
        </div>
      </div>

      <div>
        <div class="fs-16 fw-600">
          {{ $t('Date of birth') }}
        </div>

        <div class="fs-16">
          <LongDate
            :date="householdMember.dob"
            class="fs-16"
            timezone="UTC"
          />
        </div>
      </div>
    </v-col>

    <v-col
      class="d-flex justify-end"
      cols="12"
      sm="3"
    >
      <v-btn
        v-if="canEdit"
        @click="edit"
        :ripple="false"
        class="focus-very-visible me-3"
        color="primary"
        size="large"
        variant="outlined"
      >
        {{ $t('Edit') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup>
import LongDate from '@/shared/components/LongDate.vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['edit']);
const props = defineProps({
  householdMember: {
    type: Object,
    required: true,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();

const showSSN = ref(false);

const race = computed(() => {
  return props.householdMember.race?.length > 0
    ? props.householdMember.race.map((race) => t(race)).join(', ')
    : null;
});

function edit() {
  emit('edit', props.householdMember.value);
}

function maskSSN(ssn) {
  if (_.isEmpty(ssn)) return null;

  return showSSN.value ? ssn : `XXX-XX${ssn.substring(6)}`;
}

function toggleSSN() {
  showSSN.value = !showSSN.value;
}
</script>
