import axios from 'axios';

export default {
  baseUrl() {
    return `${window.api_url}/organization/family_subsidies`;
  },

  async create(family_subsidy) {
    return axios.post(this.baseUrl(), { family_subsidy });
  },

  async get(id) {
    return axios.get(`${this.baseUrl()}/${id}`);
  },

  async download(id) {
    return axios
      .get(`${this.baseUrl()}/${id}.pdf?filename=Application.pdf`, { responseType: 'blob' })
      .then((response) => {
        window.open(URL.createObjectURL(response.data));
      });
  },

  async index(filters) {
    return axios.get(this.baseUrl(), { params: filters });
  },

  async update(id, family_subsidy) {
    return axios.patch(`${this.baseUrl()}/${id}`, { family_subsidy });
  },

  async submit(family_subsidy_id, params) {
    return axios.patch(`${this.baseUrl()}/${family_subsidy_id}/submit`, params);
  },

  async destroy(family_subsidy) {
    return axios.delete(`${this.baseUrl()}/${family_subsidy.id}`);
  },
};
