<template>
  <v-card
    class="b-1 mb-4 b-radius-8"
    data-testid="dashboard-task"
    elevation="0"
    style="border-color: #f79009"
  >
    <v-card-text>
      <v-row>
        <v-col class="d-flex align-center">
          <v-avatar
            class="me-3"
            color="#F79009"
            size="40"
            variant="tonal"
          >
            <v-icon
              color="#F79009"
              size="24"
            >
              priority_high
            </v-icon>
          </v-avatar>

          <h3 class="fs-18">
            <span class="fw-500 me-2">{{ $t(item.action) }}:</span>
            <span>{{ taskTitle }}</span>
          </h3>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            v-if="item.schema_id"
            @click="openFormDialog()"
            class="fs-16"
            color="primary"
          >
            {{ $t(item.action) }}
          </v-btn>
          <v-btn
            v-else
            :to="link"
            class="fs-16"
            color="primary"
            role="link"
          >
            {{ $t(item.action) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <FormDialog
      v-if="item.schema_id"
      @close="$emit('reload-tasks')"
      ref="dashboardFormDialog"
      :draft="true"
      :schema-id="item.schema_id"
      :title="taskTitle"
    />
  </v-card>
</template>

<script setup>
import { getNextStepLink } from '@/parent/services/dashboard';
import { useI18n } from 'vue-i18n';
import FormDialog from '@/shared/components/form/FormDialog.vue';

const { t } = useI18n();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

defineEmits(['reload-tasks']);

const dashboardFormDialog = ref(null);

const link = computed(() => getNextStepLink(props.item));

const taskTitle = computed(() => {
  let parts = [];
  if (props.item.child_name) {
    parts.push(`${props.item.child_name}, `);
  }
  parts.push(t(props.item.program_name));
  parts.push(t(props.item.provider_name));
  parts.push(t(props.item.form_name));
  return parts.join(' ');
});

function openFormDialog() {
  dashboardFormDialog.value.open({ id: props.item.id });
}
</script>
