import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/summary_configurations`;
  },

  create(summaryConfiguration) {
    return axios
      .post(this.baseUrl(), { summary_configuration: summaryConfiguration })
      .catch(handleError);
  },

  destroy(summaryConfigurationId) {
    return axios.delete(`${this.baseUrl()}/${summaryConfigurationId}`).catch(handleError);
  },

  get(id) {
    return axios.get(`${this.baseUrl()}/${id}`).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  preview(id) {
    return axios.get(`${this.baseUrl()}/${id}/preview`);
  },

  run(id) {
    return axios.post(`${this.baseUrl()}/${id}/run`).catch(handleError);
  },

  update(summaryConfiguration) {
    return axios
      .patch(`${this.baseUrl()}/${summaryConfiguration.id}`, {
        summary_configuration: summaryConfiguration,
      })
      .catch(handleError);
  },
};
