<!-- eslint-disable vue/no-v-html -->
<template>
  <span
    v-if="translatedAndRenderedHtml"
    v-html="translatedAndRenderedHtml"
    class="markdown-content"
    data-testid="markdown-content"
  />
</template>

<script setup>
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const addTargetAndRelToLinks = (node) => {
  if (node.tagName === 'A') {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'nofollow');
  }
  return node;
};
DOMPurify.addHook('afterSanitizeAttributes', addTargetAndRelToLinks);

const props = defineProps({
  content: {
    type: String,
    default: null,
  },
});

const translatedAndRenderedHtml = computed(() => {
  if (!props.content) return null;
  const html = renderMarkdown(t(props.content));
  return DOMPurify.sanitize(html);
});

function renderMarkdown(string) {
  if (!string) return null;

  const renderer = new marked.Renderer();

  renderer.paragraph = function (text) {
    return `${text}`;
  };
  return marked(string, { renderer });
}
</script>
<style>
.markdown-content > ol,
ul {
  list-style-position: inside;
}
</style>
