<template>
  <div class="ta-center">
    <div class="fs-16 fw-500">
      {{ $t(dayTranslation) }}
    </div>

    <div class="fs-16 mb-2">
      <LongDate
        :date="day.day"
        hide-year
        short-month
      />
    </div>

    <v-btn
      v-for="time in day.times"
      @click="selectTime(time)"
      :key="time"
      :variant="!active || selectedTime != time ? 'outlined' : undefined"
      class="my-2"
      color="primary"
      size="small"
      block
    >
      {{
        new Date(time).toLocaleTimeString($store.state.default_locale, {
          hour: 'numeric',
          minute: '2-digit',
        })
      }}
    </v-btn>

    <div
      v-if="day.times.length == 0"
      class="fs-14 c-light-black"
    >
      <v-icon icon="minimize" />
    </div>

    <v-divider
      v-if="$vuetify.display.smAndDown"
      class="my-4"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  day: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['select']);

const dayAbbreviations = ref(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
const selectedTime = ref(null);

const dayTranslation = computed(() => {
  const selectedDate = new Date(props.day.day);
  const day = selectedDate.getDay();
  const dayAbbreviation = dayAbbreviations.value[day];
  return dayAbbreviation;
});

function selectTime(time) {
  selectedTime.value = time;
  emit('select', time);
}
</script>
