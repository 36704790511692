<template>
  <v-menu
    v-model="menu"
    ref="location_menu"
    :close-on-content-click="false"
    offset="5"
    eager
  >
    <template #activator="{ props: menuProps }">
      <button
        v-bind="menuProps"
        ref="activatorButton"
        :ripple="false"
        class="bg-white c-black fullheight fullwidth rounded pa-3 focus-very-visible"
      >
        <v-row>
          <v-col
            class="ta-left"
            cols="10"
          >
            <div
              class="fs-16 mb-1"
              data-cy="location_menu"
            >
              {{ $t('Search address, city or zip code') }}
            </div>
            <div class="fs-16 fw-600">
              {{ search.formatted_origin || search.zip || $t('Locations near you') }}
            </div>
          </v-col>
          <v-col
            class="d-flex align-center justify-end"
            cols="2"
          >
            <v-icon>expand_more</v-icon>
          </v-col>
        </v-row>
      </button>
    </template>

    <LocationEditor
      @update="focus(true)"
      :open="menu"
      :search="localValue"
    />
  </v-menu>
</template>

<script setup>
import LocationEditor from '@/shared/components/search/LocationEditor.vue';
import { ref, watch, nextTick } from 'vue';

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: true,
  },
  search: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const localValue = ref(props.search);
const menu = ref(false);
const activatorButton = ref(null);

watch(menu, (newVal) => {
  if (!newVal) {
    emit('close');
  }
});

function focus(drawFocus) {
  menu.value = false;
  if (!drawFocus) return;
  nextTick(() => {
    activatorButton.value.focus();
  });
}

// Call focus on mount
focus(props.autofocus);
</script>
