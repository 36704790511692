<template>
  <v-col
    :class="extraClasses"
    :cols="cols"
    :md="md"
    :sm="sm"
    class="fw-600 c-black d-none d-md-flex align-center"
  >
    <slot name="prefix" />
    <span>{{ $t(title) }}</span>
    <slot name="suffix" />
  </v-col>
</template>

<script setup>
const props = defineProps({
  classes: {
    type: String,
    default: null,
  },
  cols: {
    type: String,
    default: null,
  },
  dense: Boolean,
  md: {
    type: String,
    default: null,
  },
  paddingStart: {
    type: String,
    default: null,
  },
  sm: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const extraClasses = computed(() => {
  const ary = [];

  if (props.dense) {
    ary.push('fs-14');
  } else {
    ary.push('fs-16');
  }

  if (props.classes) {
    ary.push(props.classes);
  }

  return ary.join(' ');
});
</script>
