<template>
  <div v-if="localChildren && localChildren.length > 0">
    <v-row
      v-for="(child, index) in localChildren"
      :key="child.id"
    >
      <v-col>
        <SubsidyChild
          @change="updateChild($event)"
          :autofocus="autofocus"
          :index="index"
          :initial-child="child"
          :prevent-update="preventUpdate"
          included-check
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="fs-15">
        {{ $t("Don't see the child you're looking for?") }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          @click="addChild"
          :loading="processing"
          class="focus-very-visible"
          color="primary"
        >
          <v-icon start> add </v-icon>
          <span v-t="'Add another child'" />
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import SubsidyChild from '@/parent/components/subsidy/SubsidyChild.vue';
import { useStore } from 'vuex';

const props = defineProps({
  autofocus: Boolean,
  children: {
    default: null,
    type: Array,
  },
  preventUpdate: Boolean,
});

const emit = defineEmits(['change']);

const store = useStore();
const localChildren = ref(props.children);
const processing = ref(false);

const addChild = () => {
  localChildren.value.push({
    ...defaultChild(),
    included: true,
    editing: true,
  });
};

const defaultChild = () => {
  return {
    schema_id: Object.values(store.state.schemas).filter(
      (schema) => schema.data_type === 'Child',
    )[0].id,
  };
};

const updateChild = (child) => {
  const index = localChildren.value.findIndex((localChild) => {
    return (
      localChild.id === child.id ||
      (localChild.first_name === child.first_name &&
        localChild.last_name === child.last_name &&
        localChild.dob === child.dob)
    );
  });

  if (index > -1) {
    localChildren.value[index] = child;
  } else {
    localChildren.value.push(child);
  }

  emit('change', localChildren.value);
};

watch(
  () => props.children,
  (newVal) => {
    localChildren.value = newVal;
  },
);

onMounted(() => {
  if (localChildren.value.length === 0) {
    addChild();
  }
});
</script>
