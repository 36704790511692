import { asyncApi } from '@/shared/services/api';

export default {
  event: asyncApi({
    endpoint: '/organization/workflow/events',
    payloadKey: 'event',
  }),

  step: asyncApi({
    endpoint: '/organization/workflow/steps',
    payloadKey: 'step',
  }),

  scheduledEvents: asyncApi({
    endpoint: '/organization/workflow/scheduled_events',
  }),
};
