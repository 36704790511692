<!-- eslint-disable max-len -->
<template>
  <v-container class="mxw-800 mx-auto py-12 px-1">
    <SubsidyProgramValidation :subsidy-program="subsidyProgram">
      <template #open>
        <span class="c-primary font-weight-bold px-2"> {{ subsidyProgram?.name }} </span>
        <div
          ref="progressRef"
          :aria-label="$t('Progress indicator - question') + ' ' + currentStep"
          class="px-2 focus-invisible"
          tabindex="0"
          aria-live
        >
          <v-progress-linear
            v-if="!!progress"
            v-model="progress"
            :indeterminate="!loaded"
            class="focus-invisible mb-3"
            color="primary"
          />
        </div>

        <div
          v-if="loaded"
          class="px-md-2"
        >
          <FormQuestion
            v-show="section === subsidySections.groupHomeAddress"
            @next="validateAddress()"
            :key="subsidySections.groupHomeAddress"
            :next-disabled="!validHomeAddress"
            :processing="processing"
            :reversible="false"
            :subtitle="
              subsidyProgram.home_address_subtitle ||
              'Let\'s start with your family\'s primary home address.'
            "
            :title="
              subsidyProgram.home_address_title || 'Welcome! We\'ll make it fast and easy to apply.'
            "
          >
            <template #preamble>
              <p>
                <span class="c-red me-1">*</span>
                <span
                  v-t="'Indicates a required field'"
                  class="c-light-black"
                />
              </p>
            </template>
            <v-row dense>
              <LabeledTextfield
                v-model="group.home_address"
                data-cy="address"
                message="Address"
                mandatory
              />
              <template v-if="subsidyProgram.city_options?.length > 0">
                <LabeledSimpleSelect
                  v-model="group.home_city"
                  :items="subsidyProgram.city_options"
                  data-cy="city-input"
                  md="4"
                  message="City"
                  mandatory
                />
              </template>
              <template v-else>
                <LabeledTextfield
                  v-model="group.home_city"
                  data-cy="city"
                  md="4"
                  message="City"
                  mandatory
                />
              </template>
              <LabeledSimpleSelect
                v-model="group.home_state"
                :items="stateOptions"
                data-cy="state-input"
                md="4"
                message="State"
                mandatory
              />
              <template v-if="subsidyProgram.zip_options?.length > 0">
                <LabeledSimpleSelect
                  v-model="group.home_zip"
                  :items="subsidyProgram.zip_options"
                  data-cy="zip-input"
                  md="4"
                  message="Zip"
                  mandatory
                />
              </template>
              <template v-else>
                <LabeledTextfield
                  v-model="group.home_zip"
                  data-cy="zip"
                  mask="#####"
                  md="4"
                  message="Zip"
                  mandatory
                />
              </template>
            </v-row>
            <v-row
              v-if="subsidyProgram.enable_homeless_address_option"
              dense
            >
              <v-checkbox
                v-model="group.experiencing_homelessness"
                :label="
                  $t(
                    subsidyProgram.homeless_attestation ||
                      'My family is experiencing homelessness.',
                  )
                "
              />
            </v-row>
          </FormQuestion>

          <FormQuestion
            v-if="section === 'children'"
            @back="backFromChildren"
            @next="forwardFromChildren"
            :key="'children'"
            :next-disabled="!validChildren"
            :processing="processing"
            :subtitle="subsidyProgram.child_subtitle"
            :title="subsidyProgram.child_title || 'Add your children'"
          >
            <SubsidyChildren
              @change="children = $event"
              :children="children"
            />
          </FormQuestion>

          <QuestionSet
            v-model="group"
            @back="saveGroupStepBack"
            @change:attachments="loadAttachments"
            @next="saveGroupLoadSubsidiesStepForward"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :processing="processing"
            :questions="groupEligibilityQuestions"
            :schema="groupSchema.definition"
            :section="section"
            key-name="GroupEligibility"
            transition-name="none"
          />

          <div
            v-for="(child, childIndex) in childrenWithSubsidies"
            :key="'child-eligibility' + child.id"
          >
            <QuestionSet
              v-if="childrenEligibilityQuestions[child.id]"
              @back="saveChildByIdStepBack({ childId: child.id })"
              @change="maybeClearPreferences(child, $event)"
              @change:attachments="loadAttachments"
              @input="child = $event"
              @next="saveChildByIdLoadSubsidiesStepForward({ childId: child.id })"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidyForChild(child).id]"
              :attachments="attachments"
              :header="$t('Child') + ' - ' + child.first_name"
              :key-name="'ChildEligibility' + childIndex.toString()"
              :model-value="child"
              :processing="processing"
              :questions="childrenEligibilityQuestions[child.id]"
              :schema="childSchema.definition"
              :section="section"
              transition-name="none"
            />
          </div>

          <template
            v-if="subsidyProgram.eligibility && subsidies?.length > 0 && section == 'eligibility'"
          >
            <FormQuestion
              @back="stepBack"
              @finish="exit"
              @next="saveGroupStepForward"
              key="eligibility"
              :finishable="eligibleSubsidies?.length === 0"
              :forwardable="eligibleSubsidies?.length > 0"
              :processing="processing"
              :subtitle="
                subsidyProgram.eligibility_subtitle ||
                'Based on the answers you supplied, we\'ve determined your eligibility. If you think this determination is incorrect, please message our support team.'
              "
              :title="subsidyProgram.eligibility_title || 'Here is your eligibility report:'"
              class="focus-very-visible"
              finish-text="Exit"
              next-text="Continue for eligible children"
              next-arrow
            >
              <div
                v-if="eligibleSubsidies?.length > 0"
                class="mb-8"
              >
                <div
                  v-t="'These children are eligible:'"
                  class="fs-20 fw-500 mb-4"
                />
                <SubsidyEligibilityCard
                  v-for="subsidy in eligibleSubsidies"
                  :key="subsidy.id"
                  :elevation="2"
                  :funding-sources="subsidy.meta.funding_sources"
                  :name="childForSubsidy(subsidy).name"
                  :projected-eligibility="subsidy.projected_eligibility"
                  outlined
                />
              </div>

              <div v-if="ineligibleSubsidies?.length > 0">
                <div
                  v-t="'These children are not eligible:'"
                  class="fs-20 fw-500 mb-4"
                />
                <div
                  v-for="subsidy in ineligibleSubsidies"
                  :key="subsidy.id"
                >
                  <SubsidyEligibilityCard
                    :elevation="2"
                    :funding-sources="subsidy.meta.funding_sources"
                    :name="childForSubsidy(subsidy).name"
                    :projected-eligibility="subsidy.projected_eligibility"
                    outlined
                  />
                </div>
              </div>

              <v-alert
                v-if="eligibleSubsidies?.length == 0"
                border="start"
                class="w-100pc mt-6 fs-20"
                color="primary"
                type="info"
              >
                <div>
                  <div
                    v-t="
                      'You are not eligible for this program at this time. Please contact support if you believe this is incorrect.'
                    "
                  />
                </div>
              </v-alert>
            </FormQuestion>
          </template>

          <ThirdPartyQuestions
            v-if="section == 'third-party-questions'"
            @back="stepBack"
            @input="thirdPartyApplication = $event"
            @next="forwardFromThirdPartyQuestions"
            key="third-party-questions"
            :model-value="thirdPartyApplication"
            :next-disabled="thirdPartyContinueDisabled()"
            :processing="processing"
            forwardable
          />

          <QuestionSet
            v-model="group"
            @back="saveGroupStepBack"
            @change:attachments="loadAttachments"
            @next="saveGroupStepForward"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :processing="processing"
            :questions="groupOtherQuestions"
            :schema="groupSchema.definition"
            :section="section"
            key-name="Group"
            transition-name="none"
          />

          <div
            v-for="(child, childIndex) in eligibleChildren"
            :key="'child' + child.id"
          >
            <QuestionSet
              v-if="childrenOtherQuestions[child.id]"
              @back="saveChildByIdStepBack({ childId: child.id })"
              @change:attachments="loadAttachments"
              @input="child = $event"
              @next="saveChildByIdStepForward({ childId: child.id })"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidyForChild(child).id]"
              :attachments="attachments"
              :header="$t('Child') + ' - ' + child.first_name"
              :key-name="'Child' + childIndex"
              :model-value="child"
              :processing="processing"
              :questions="childrenOtherQuestions[child.id]"
              :schema="childSchema.definition"
              :section="section"
              transition-name="none"
            />
          </div>

          <template v-if="favoritesSectionEnabled">
            <SubsidyWrapper
              v-for="(child, childIndex) in eligibleChildrenWithPreferencesAllowed"
              :key="`preference-review-${child.id}`"
              :child="child"
              :previous-child="eligibleChildrenWithPreferencesAllowed[childIndex - 1]"
              :subsidies="subsidies"
            >
              <template #default="{ previousChild, previousChildName, previousSubsidy, subsidy }">
                <template v-if="subsidyProgram.enable_advanced_selector">
                  <template v-if="subsidyProgram.allow_program_preference">
                    <ProgramPreferenceEditor
                      v-show="section == 'favorites-' + childIndex"
                      @back="stepBack"
                      @next="forwardFromFavoriteQuestion({ subsidy })"
                      @reorder-programs="
                        (from, to) => handleReorderSelectedPrograms(from, to, subsidy)
                      "
                      @selected-current-provider-id="handleSelectedCurrentProvider($event, subsidy)"
                      @selected-program-ids="handleSelectedPrograms($event, subsidy)"
                      @selected-sibling-ids="handleSelectedSiblings($event, subsidy)"
                      @selected-staff-ids="handleSelectedStaff($event, subsidy)"
                      :key="'favorites-' + childIndex"
                      :child-data="child"
                      :current-provider="subsidy.current_provider_id"
                      :default-query="homeAddress"
                      :previous-child-name="previousChildName"
                      :previous-subsidy-program-ids="previousSubsidy?.program_ids"
                      :processing="processing"
                      :sibling-providers="subsidy.sibling_provider_ids"
                      :staff-providers="subsidy.staff_provider_ids"
                      :subsidy="subsidy"
                      :subsidy-program="subsidyProgram"
                    />
                  </template>

                  <template v-else>
                    <ProviderPreferenceEditor
                      v-show="section == 'favorites-' + childIndex"
                      @back="stepBack"
                      @next="forwardFromFavoriteQuestion({ subsidy })"
                      :key="'favorites-' + childIndex"
                      :child-data="child"
                      :default-query="homeAddress"
                      :previous-child-name="previousChildName"
                      :previous-subsidy-provider-ids="previousSubsidy?.provider_ids"
                      :processing="processing"
                      :subsidy="subsidy"
                      :subsidy-program="subsidyProgram"
                    />
                  </template>
                </template>

                <template v-else>
                  <template v-if="subsidyProgram.allow_preference">
                    <FormQuestion
                      v-show="section == 'favorites-' + childIndex"
                      @back="stepBack"
                      @next="forwardFromFavoriteQuestion({ subsidy })"
                      :key="'favorites-' + childIndex"
                      :header="$t('Child') + ' - ' + child.first_name"
                      :next-disabled="subsidy.provider_ids?.length == 0"
                      :processing="processing"
                      :subtitle="
                        subsidyProgram.preference_subtitle ||
                        (subsidyProgram.allow_preference_order
                          ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                          : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                      "
                      :title="
                        subsidyProgram.preference_title ||
                        (subsidyProgram.allow_preference_order
                          ? 'Select and rank the providers you’d like to include in the application for this child'
                          : 'Select the providers you’d like to include in the application for this child')
                      "
                    >
                      <FavoritesSelector
                        :child-data="child"
                        :default-query="homeAddress"
                        :ordered="subsidyProgram.allow_preference_order"
                        :previous-child="previousChild"
                        :subsidies="subsidies"
                        :subsidy="subsidy"
                        :subsidy-program="subsidyProgram"
                        class="mt-4 mb-4"
                      />
                    </FormQuestion>
                  </template>

                  <template v-else-if="subsidyProgram.allow_enrolled">
                    <FormQuestion
                      v-show="section == 'favorites-' + childIndex"
                      @back="stepBack"
                      @next="forwardFromFavoriteQuestion({ subsidy })"
                      :key="'favorites-' + childIndex"
                      :header="$t('Child') + ' - ' + child.first_name"
                      :processing="processing"
                      :subtitle="subsidyProgram.preference_subtitle"
                      :title="
                        subsidyProgram.preference_title ||
                        'If your child is already enrolled in care, add the provider below.'
                      "
                    >
                      <ProviderSelector
                        :child="child"
                        :subsidy="subsidy"
                      />
                    </FormQuestion>
                  </template>
                </template>
              </template>
            </SubsidyWrapper>
          </template>

          <div
            v-for="(question, index) in groupVerificationQuestions"
            v-show="section == ['group-verification', index].join('-')"
            :key="['group-verification', index].join('-')"
          >
            <FormQuestion
              @back="stepBack"
              @next="stepForward"
              :next-disabled="
                question.verification_mandatory && hasNoAttachmentsWithTag(question.id)
              "
              :processing="processing"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: question.id,
                  tags: subsidies.map((subsidy) => subsidy.id).concat([question.id]),
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachmentsWithTag(question.id)"
                :processing="processing"
                class="mb-6"
              />
            </FormQuestion>
          </div>

          <div
            v-for="(child, childIndex) in eligibleChildren"
            :key="`child-verification${childIndex}`"
          >
            <div
              v-for="(question, index) in childrenVerificationQuestions[child.id]"
              v-show="section == `child-verification${childIndex}-${index}`"
              :key="`child-verification${childIndex}-${index}`"
            >
              <FormQuestion
                @back="stepBack"
                @next="stepForward"
                :header="$t('Child') + ' - ' + child.first_name"
                :next-disabled="
                  question.verification_mandatory && hasNoAttachmentsWithTag(child.id + question.id)
                "
                :processing="processing"
                :schema="childSchema.definition"
                :subtitle="question.verification_subtitle"
                :title="question.verification_title"
              >
                <AttachmentUploader
                  @uploaded="attachments.push($event)"
                  :ref="['uploader', question.id, child.id].join('')"
                  :owner="{
                    type: 'SubsidyProgram',
                    id: subsidyProgram.id,
                    tag: child.id + question.id,
                    tags: [child.id, subsidyForChild(child).id, question.id],
                  }"
                  class="mb-4"
                />
                <AttachmentList
                  @delete="loadAttachments"
                  :attachments="attachmentsWithTag(child.id + question.id)"
                  :processing="processing"
                  class="mb-6"
                />
              </FormQuestion>
            </div>
          </div>

          <div
            v-for="(child, childIndex) in eligibleChildren"
            :key="'child-documents-' + childIndex"
          >
            <FormQuestion
              v-if="subsidyProgram.verify_child"
              v-show="section == 'child-documents-' + childIndex"
              @back="stepBack"
              @next="stepForward"
              :header="$t('Child') + ' - ' + child.first_name"
              :next-disabled="
                subsidyProgram.verify_child_mandatory &&
                hasNoAttachmentsWithTag('child-documents-' + child.id)
              "
              :processing="processing"
              :subtitle="subsidyProgram.verify_child_subtitle"
              :title="subsidyProgram.verify_child_title"
            >
              <p class="fs-15 c-light-black" />
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: 'child-documents-' + child.id,
                  tags: ['child-documents', child.id, subsidyForChild(child).id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachmentsWithTag('child-documents-' + child.id)"
                :processing="processing"
              />
            </FormQuestion>
          </div>

          <FormQuestion
            v-show="section == 'proof-of-residency'"
            @back="stepBack"
            @next="stepForward"
            key="proof-of-residency"
            :next-disabled="
              subsidyProgram.verify_home_address_mandatory &&
              hasNoAttachmentsWithTag('proof-of-residency')
            "
            :processing="processing"
            :subtitle="
              subsidyProgram.verify_home_address_subtitle ||
              'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
            "
            :title="
              subsidyProgram.verify_home_address_title ||
              'Upload a document to verify your current address'
            "
          >
            <p class="fs-15 c-light-black" />
            <AttachmentUploader
              @uploaded="attachments.push($event)"
              ref="uploader_residency"
              :owner="{
                type: 'SubsidyProgram',
                id: subsidyProgram.id,
                tag: 'proof-of-residency',
                tags: subsidies.map((subsidy) => subsidy.id).concat(['proof-of-residency']),
              }"
              class="mb-4"
            />
            <AttachmentList
              @delete="loadAttachments"
              :attachments="attachmentsWithTag('proof-of-residency')"
              :processing="processing"
            />
          </FormQuestion>

          <FormQuestion
            v-if="section == subsidySections.confirm"
            @back="backFromConfirm"
            @finish="finish"
            key="confirm"
            :finish-disabled="!confirmed"
            :forwardable="false"
            :next-disabled="!confirmed"
            :processing="processing"
            :title="subsidyProgram.confirm_title || 'Confirm your application information'"
            header="Last step"
            finishable
          >
            <template v-if="subsidyProgram.allow_parent_review">
              <FormQuestion
                :processing="processing"
                :subtitle="subsidyProgram.home_address_subtitle"
                :title="
                  subsidyProgram.home_address_title ||
                  'What is your family\'s primary home address.'
                "
                class="mb-4"
                dense
                expanded
                hide-actions
                paddingless
              >
                <v-row>
                  <LabeledTextfield
                    v-model="group.home_address"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_address"
                    message="Address"
                    dense
                    mandatory
                  />
                  <LabeledTextfield
                    v-model="group.home_city"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_city"
                    md="4"
                    message="City"
                    dense
                    mandatory
                  />
                  <LabeledSimpleSelect
                    v-model="group.home_state"
                    :hard-lock="reviewLocked"
                    :items="$a.assets.states"
                    :schema-id="group.schema_id"
                    field="home_state"
                    md="4"
                    message="State"
                    placeholder="Select one"
                    dense
                    mandatory
                  />
                  <LabeledTextfield
                    v-model="group.home_zip"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_zip"
                    md="4"
                    message="Zip"
                    dense
                    mandatory
                  />
                </v-row>
                <v-row
                  v-if="subsidyProgram.enable_homeless_address_option"
                  dense
                >
                  <v-checkbox
                    v-model="group.experiencing_homelessness"
                    :disabled="reviewLocked"
                    :label="
                      $t(
                        subsidyProgram.homeless_attestation ||
                          'My family is experiencing homelessness.',
                      )
                    "
                  />
                </v-row>
              </FormQuestion>

              <v-divider class="mb-4" />

              <div
                v-for="child in eligibleChildren"
                :key="`child-${child.id}`"
              >
                <FormQuestion
                  :header="[$t('Child'), child.first_name].join(' - ')"
                  :processing="processing"
                  class="mb-4"
                  title="Name and date of birth"
                  break-after
                  dense
                  hide-actions
                  paddingless
                >
                  <Child
                    :hard-lock="reviewLocked"
                    :initial-child="child"
                    dense
                    remove-disabled
                  />
                </FormQuestion>
                <v-divider class="mb-4" />
              </div>

              <QuestionSet
                v-model="group"
                @change="reloadGroupQuestionsForDraftData(group)"
                @change:attachments="loadAttachments"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :processing="processing"
                :questions="groupEligibilityQuestions"
                :readonly="reviewLocked"
                :schema="groupSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <div
                v-for="child in eligibleChildren"
                :key="child.id + '-eligibility'"
              >
                <QuestionSet
                  v-if="childrenEligibilityQuestions[child.id]"
                  @change="reloadChildQuestionsForDraftData(child.id)"
                  @input="child = $event"
                  :attachment-group-id="group.id"
                  :attachment-owner-id="subsidyProgram.id"
                  :attachment-owner-type="'SubsidyProgram'"
                  :attachment-tags-supplements="[subsidyForChild(child).id]"
                  :attachments="attachments"
                  :header="$t('Child') + ' - ' + child.first_name"
                  :model-value="child"
                  :processing="processing"
                  :questions="childrenEligibilityQuestions[child.id]"
                  :readonly="reviewLocked"
                  :schema="childSchema.definition"
                  dense
                  divided
                  expanded
                  hide-actions
                  paddingless
                />
              </div>

              <QuestionSet
                v-model="group"
                @change="reloadGroupQuestionsForDraftData(group)"
                @change:attachments="loadAttachments"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :processing="processing"
                :questions="groupOtherQuestions"
                :readonly="reviewLocked"
                :schema="groupSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <div
                v-for="child in eligibleChildren"
                :key="child.id + '-other'"
              >
                <QuestionSet
                  v-if="childrenOtherQuestions[child.id]"
                  @change="reloadChildQuestionsForDraftData(child.id)"
                  @input="child = $event"
                  :attachment-group-id="group.id"
                  :attachment-owner-id="subsidyProgram.id"
                  :attachment-owner-type="'SubsidyProgram'"
                  :attachment-tags-supplements="[subsidyForChild(child).id]"
                  :attachments="attachments"
                  :header="$t('Child') + ' - ' + child.first_name"
                  :model-value="child"
                  :processing="processing"
                  :questions="childrenOtherQuestions[child.id]"
                  :readonly="reviewLocked"
                  :schema="childSchema.definition"
                  dense
                  divided
                  expanded
                  hide-actions
                  paddingless
                />
              </div>

              <template
                v-if="
                  subsidyProgram.allow_preference ||
                  subsidyProgram.allow_program_preference ||
                  subsidyProgram.allow_enrolled
                "
              >
                <SubsidyWrapper
                  v-for="(child, childIndex) in eligibleChildrenWithPreferencesAllowed"
                  :key="`preference-${child.id}`"
                  :child="child"
                  :previous-child="eligibleChildrenWithPreferencesAllowed[childIndex - 1]"
                  :subsidies="subsidies"
                >
                  <template #default="{ previousChild, previousSubsidy, subsidy }">
                    <template v-if="subsidyProgram.enable_advanced_selector">
                      <template v-if="subsidyProgram.allow_program_preference">
                        <ProgramPreferenceReview
                          :key="'favorites-' + childIndex"
                          :child-name="child.first_name"
                          :current-provider-id="subsidy.current_provider_id"
                          :previous-child-name="previousChild?.first_name"
                          :previous-subsidy-program-ids="previousSubsidy?.program_ids"
                          :program-ids="subsidy.program_ids"
                          :sibling-provider-ids="subsidy.sibling_provider_ids"
                          :staff-provider-ids="subsidy.staff_provider_ids"
                          :subsidy-program="subsidyProgram"
                        />
                      </template>
                      <template v-else>
                        <ProviderPreferenceReview
                          :key="'favorites-' + childIndex"
                          :child-name="child.first_name"
                          :current-provider-id="subsidy.current_provider_id"
                          :previous-child-name="previousChild?.first_name"
                          :previous-subsidy-provider-ids="previousSubsidy?.provider_ids"
                          :provider-ids="subsidy.provider_ids"
                          :subsidy-program="subsidyProgram"
                        />
                      </template>
                    </template>

                    <template v-else>
                      <template v-if="subsidyProgram.allow_preference">
                        <FormQuestion
                          @back="stepBack"
                          :key="'favorites-' + childIndex"
                          :header="$t('Child') + ' - ' + child.first_name"
                          :processing="processing"
                          :subtitle="
                            subsidyProgram.preference_subtitle ||
                            (subsidyProgram.allow_preference_order
                              ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                              : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                          "
                          :title="
                            subsidyProgram.preference_title ||
                            (subsidyProgram.allow_preference_order
                              ? 'Select and rank the providers you’d like to include in the application for this child'
                              : 'Select the providers you’d like to include in the application for this child')
                          "
                          hide-actions
                        >
                          <template v-if="subsidyProgram.allow_preference">
                            <FavoritesSelector
                              :child-data="child"
                              :default-query="homeAddress"
                              :ordered="subsidyProgram.allow_preference_order"
                              :previous-child="previousChild"
                              :subsidies="subsidies"
                              :subsidy="subsidy"
                              :subsidy-program="subsidyProgram"
                              class="mt-4 mb-4"
                            />
                          </template>
                        </FormQuestion>
                      </template>

                      <template v-else-if="subsidyProgram.allow_enrolled">
                        <FormQuestion
                          :key="'favorites-' + childIndex"
                          :header="$t('Child') + ' - ' + child.first_name"
                          :processing="processing"
                          :subtitle="subsidyProgram.preference_subtitle"
                          :title="
                            subsidyProgram.preference_title ||
                            'If your child is already enrolled in care, add the provider below.'
                          "
                          hide-actions
                        >
                          <ProviderSelector
                            :child="child"
                            :subsidy="subsidy"
                          />
                        </FormQuestion>
                      </template>
                    </template>
                  </template>
                </SubsidyWrapper>
              </template>

              <div
                v-for="question in groupVerificationQuestions"
                :key="question.id"
              >
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  dense
                  hide-actions
                  paddingless
                >
                  <AttachmentList
                    :attachments="attachmentsWithTag(question.id)"
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </div>

              <div
                v-for="child in eligibleChildren"
                :key="child.id + '-verification'"
              >
                <template v-if="childrenVerificationQuestions[child.id]">
                  <div
                    v-for="question in childrenVerificationQuestions[child.id].filter(
                      (questionObject) => questionObject.valid,
                    )"
                    :key="question.id + child.id"
                    no-print
                  >
                    <v-divider class="my-4" />

                    <FormQuestion
                      :subtitle="question.verification_subtitle"
                      :title="question.verification_title"
                      dense
                      hide-actions
                      paddingless
                    >
                      <AttachmentList
                        :attachments="attachmentsWithTag(child.id + question.id)"
                        :empty-label="$t('No documents provided.')"
                        :processing="processing"
                        dense
                        hide-remove
                      />
                    </FormQuestion>
                  </div>
                </template>
              </div>

              <template v-if="subsidyProgram.verify_child">
                <div
                  v-for="child in eligibleChildren"
                  :key="child.id + '-documents'"
                >
                  <div no-print>
                    <v-divider class="my-4" />
                    <FormQuestion
                      :header="$t('Child') + ' - ' + child.first_name"
                      :subtitle="subsidyProgram.verify_child_subtitle"
                      :title="subsidyProgram.verify_child_title"
                      dense
                      hide-actions
                      paddingless
                    >
                      <AttachmentList
                        :attachments="attachmentsWithTag('child-documents-' + child.id)"
                        :empty-label="$t('No documents provided.')"
                        :processing="processing"
                        dense
                        hide-remove
                      />
                    </FormQuestion>
                  </div>
                </div>
              </template>

              <template v-if="subsidyProgram.verify_home_address">
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="
                    subsidyProgram.verify_home_address_subtitle ||
                    'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
                  "
                  :title="
                    subsidyProgram.verify_home_address_title ||
                    'Upload a document to verify your current address'
                  "
                  dense
                  hide-actions
                  no-print
                  paddingless
                >
                  <AttachmentList
                    :attachments="attachmentsWithTag('proof-of-residency')"
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </template>
              <v-divider class="my-8" />
            </template>

            <ThirdPartyQuestions
              v-if="subsidyProgram.enable_third_party_applications"
              key="third-party-questions"
              :model-value="thirdPartyApplication"
              :processing="processing"
              :readonly="true"
            />

            <v-divider
              v-if="subsidyProgram.enable_third_party_applications"
              class="my-8"
            />

            <UserAttestation
              v-if="
                thirdPartyApplication.third_party_application &&
                subsidyProgram.enable_third_party_esign
              "
              @change="confirmed = $event"
              ref="attestation"
              :attestation-label="
                subsidyProgram.third_party_attestation_label[$store.state.profile.default_locale]
              "
              :attestation-text="subsidyProgram.third_party_attestation_text"
              :enable-esign="true"
            />

            <UserAttestation
              v-else
              @change="confirmed = $event"
              ref="attestation"
              :attestation-label="subsidyProgram.attestation_label"
              :attestation-text="subsidyProgram.attestation_text"
              :enable-esign="subsidyProgram.enable_esign"
            />
          </FormQuestion>

          <AddressVerificationDialog
            @cancel="saveGroupLoadSubsidiesStepForward"
            @confirm="updateHomeAddress"
            ref="validateAddressDialog"
            cancel-button-text="Keep original"
            confirm-button-text="Use recommended"
            title="Verify address"
          />

          <ResourceDialog
            @save="confirmReorderReferredProgram()"
            ref="programPositionDialog"
            :cancellable="true"
            :closeable="true"
            :max-width="600"
            save-button-text="Continue with re-ranking"
            title="Are you sure you want to continue?"
          >
            <template #form>
              <v-row data-testid="programPositionDialog">
                <v-col cols="12">
                  <div class="fs-14">
                    {{
                      $t(
                        "Important: If you don't rank the referred provider as your first choice, your priority at this site won't be guaranteed.",
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </ResourceDialog>
        </div>
      </template>
    </SubsidyProgramValidation>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import $a from '@/shared/services/assets';
import Api from '@/shared/services/bright_finder';
import AddressVerificationDialog from '@/shared/components/AddressVerificationDialog.vue';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import Child from '@/parent/components/children/Child.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SubsidyChildren from '@/parent/components/subsidy/SubsidyChildren.vue';
import FavoritesSelector from '@/parent/components/favorites/FavoritesSelector.vue';
import ProgramPreferenceEditor from '@/parent/components/ProgramPreferenceEditor.vue';
import ProgramPreferenceReview from '@/parent/components/subsidy/ProgramPreferenceReview.vue';
import ProviderPreferenceEditor from '@/parent/components/ProviderPreferenceEditor.vue';
import ProviderPreferenceReview from '@/parent/components/subsidy/ProviderPreferenceReview.vue';
import ProviderSelector from '@/parent/components/ProviderSelector.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SubsidyWrapper from '@/parent/components/subsidy/SubsidyWrapper.vue';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import SubsidyProgramValidation from '@/shared/components/SubsidyProgramValidation.vue';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { SUBSIDY_EVENTS } from '@/parent/services/constants';
import { useStore } from 'vuex';
import useSubsidyData from '@/shared/composables/useSubsidyData';
import useChildSubsidyStepUtils from '@/shared/composables/child-subsidy/useChildSubsidyStepUtils';
import useGroupAddressUtils from '@/shared/composables/child-subsidy/useGroupAddressUtils';

const LEAVE_WARNING =
  'You have unsaved changes and have not submitted your application. Are you sure you want to leave your application?';
const NOW_INELIGIBLE_ERROR =
  'Application data has changed that has resulted in your application becoming ineligible. Please review your application or contact support if you believe this is incorrect.';

const attachments = ref([]);
const attestation = ref(null);
const children = ref([]);
const confirmDialog = ref(null);
const confirmed = ref(false);
const eventBus = useEventBus();
const finished = ref(false);
const group = ref(null);
const loadFinished = ref(false);
const pendingReorder = ref({});
const processing = ref(false);
const programPositionDialog = ref(null);
const progress = computed(() => (currentStep.value / stepTotal.value) * 100);
const progressRef = ref(null);
const reviewLocked = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();
const subsidies = ref([]);
const subsidyProgram = ref(null);
const subsidyProgramId = ref(null);
const thirdPartyApplication = ref(null);
const validateAddressDialog = ref(null);

const eligibleSubsidies = computed(() => {
  return subsidies.value.filter((subsidy) => subsidy.projected_eligibility);
});

const defaultSubsidyId = computed(() => {
  // We use this to get the group questions scoped to a subsidy in the group
  return subsidies.value?.[0]?.id;
});

const eligibleChildren = computed(() => {
  return children.value.filter(
    (child) =>
      subsidyForChildId(child.id) && subsidyForChildId(child.id).projected_eligibility !== false,
  );
});
const childrenWithSubsidies = computed(() => {
  return children.value.filter((child) => !!subsidyForChild(child));
});
const transitionName = ref('slide-left');
const eligibleChildrenWithPreferencesAllowed = computed(() => {
  if (!favoritesSectionEnabled.value) return [];
  if (!subsidyProgram.value?.enable_iep_direct_placement) return eligibleChildren.value;

  return eligibleChildren.value?.filter((child) => !child.individualized_education_plan);
});
const {
  childSchema,
  groupEligibilityQuestions,
  groupOtherQuestions,
  groupSchema,
  groupVerificationQuestions,
  loadChildrenQuestions,
  loadGroupQuestions,
  loadSubsidyProgramSchemas,
  childrenEligibilityQuestions,
  childrenOtherQuestions,
  childrenVerificationQuestions,
} = useSubsidyData({ includeUnpublished: { value: false } }, defaultSubsidyId, subsidyProgram);

const { currentStep, section, step, stepForward, stepBack, stepTotal, subsidySections } =
  useChildSubsidyStepUtils({
    childrenEligibilityQuestions,
    childrenOtherQuestions,
    childrenVerificationQuestions,
    childrenWithSubsidies,
    eligibleChildren,
    eligibleChildrenWithPreferencesAllowed,
    groupEligibilityQuestions,
    groupOtherQuestions,
    groupVerificationQuestions,
    includeChildrenSection: true,
    processing,
    subsidyProgram,
    transitionName,
  });

const {
  homeAddress,
  maybeSetDefaultCityAndState,
  stateOptions,
  updateHomeAddress,
  validateAddress,
  validHomeAddress,
} = useGroupAddressUtils({
  forwardFromHomeAddress: saveGroupLoadSubsidiesStepForward,
  group,
  subsidyProgram,
  validateAddressDialog,
});

onBeforeRouteLeave(async (_to, _from, next) => {
  if (!finished.value) {
    const confirm = await confirmDialog.value.confirmWithText(LEAVE_WARNING);
    if (!confirm) return next(false);
  }

  next();
});

const favoritesSectionEnabled = computed(() => {
  return (
    subsidyProgram.value?.allow_preference ||
    subsidyProgram.value?.allow_program_preference ||
    !!subsidyProgram.value?.allow_enrolled
  );
});

const ineligibleSubsidies = computed(() => {
  return subsidies.value.filter((subsidy) => !subsidy.projected_eligibility);
});

const loaded = computed(() => {
  if (!loadFinished.value) return false;

  return group.value && subsidyProgram.value && groupSchema.value && childSchema.value;
});

const validChildren = computed(() => {
  const childrenList = children.value;

  const isAnyChildEditing = childrenList.some((child) => child.editing);
  if (isAnyChildEditing) {
    return false;
  }

  const includedChildren = childrenList.filter((child) => child.included);
  if (includedChildren.length === 0) {
    return false;
  }

  const areAllIncludedChildrenValid = includedChildren.every(
    (child) => child.first_name && child.last_name && child.dob,
  );

  return areAllIncludedChildrenValid;
});

onMounted(async () => {
  await identifyMemberOnMount();
});

function childById(childId) {
  return children.value.find((child) => child.id == childId);
}

function subsidyForChild(child) {
  return subsidies.value.find((subsidy) => subsidy.child_id == child.id);
}

function subsidyForChildId(childId) {
  return subsidies.value.find((subsidy) => subsidy.child_id == childId);
}

function childForSubsidy(subsidy) {
  return children.value.find((child) => child.id == subsidy.child_id);
}

function createOrDestroySubsidies() {
  return Promise.all(
    children.value.map((child) => {
      const subsidy = subsidyForChild(child);
      if (subsidy && !child.included) {
        return Api.subsidy.promiseDestroy(subsidy.id);
      } else if (!subsidy && child.included) {
        Api.subsidy
          .create({
            test_code: route.query.test,
            child_id: child.id,
            subsidy_program_id: subsidyProgram.value.id,
          })
          .then((newSubsidy) => {
            loadGroupQuestions(newSubsidy.data.id); // ensure we've loaded group questions if we haven't already
            return loadChildrenQuestions({ subId: newSubsidy.data.id, childId: child.id }); // returning async function
          });
      }
    }),
  );
}

async function identifyMemberOnMount() {
  store.dispatch('identify', {
    api: Api,
    async success() {
      if (store.state.is_anonymous) {
        finished.value = true;
        router.replace({
          name: 'Signup',
          query: {
            action: 'apply',
            subsidyProgramId: route.params.subsidyProgramId,
            redirect_url: route.fullPath,
          },
        });
        return;
      }
      await load();
    },
    failure() {
      finished.value = true;
      router.replace({
        name: 'Signup',
        query: {
          action: 'apply',
          subsidyProgramId: route.params.subsidyProgramId,
        },
      });
    },
  });
}

async function maybeClearPreferences(child, event) {
  if (!subsidyProgram.value.enable_iep_direct_placement) return;

  if (
    child.individualized_education_plan === false &&
    event?.attribute?.name === 'individualized_education_plan' &&
    event?.value === true
  ) {
    const subsidy = subsidyForChild(child);

    subsidy.current_provider_id = null;
    subsidy.program_ids = [];
    subsidy.provider_ids = [];
    subsidy.sibling_provider_ids = [];
    subsidy.staff_provider_ids = [];

    await Api.subsidy.update(subsidy.id, {
      current_provider_id: subsidy.current_provider_id,
      program_ids: subsidy.program_ids,
      provider_ids: subsidy.provider_ids,
      sibling_provider_ids: subsidy.sibling_provider_ids,
      staff_provider_ids: subsidy.staff_provider_ids,
    });
  }
}

function handleSelectedPrograms({ type, id }, subsidy) {
  const subsidyIndex = subsidies.value.findIndex((sub) => sub.id === subsidy.id);

  switch (type) {
    case SUBSIDY_EVENTS.ADD: {
      const updatedSubsidy = orderAddedSubsidyIds(id, subsidy);
      return (subsidies.value[subsidyIndex] = updatedSubsidy);
    }
    case SUBSIDY_EVENTS.REMOVE:
      return subsidy.program_ids.splice(
        subsidy.program_ids.findIndex((programId) => programId === id),
        1,
      );
    default:
      return null;
  }
}

function orderAddedSubsidyIds(id, subsidy) {
  const programId = route.query?.programId;

  if (programId === id) {
    // We know the programId being added is the program referral, therefore it gets priority
    return { ...subsidy, program_ids: [id, ...subsidy.program_ids] };
  }

  return { ...subsidy, program_ids: [...subsidy.program_ids, id] };
}

function handleReorderSelectedPrograms(fromIndex, toIndex, subsidy, { confirmed = false } = {}) {
  const reorderedProgramId = subsidy.program_ids[fromIndex];

  const isPendingPosition = pendingReferredProgramPosition({
    fromIndex,
    toIndex,
    reorderedProgramId,
    subsidy,
  });

  if (isPendingPosition === false || confirmed === true) {
    subsidy.program_ids.splice(fromIndex, 1);
    subsidy.program_ids.splice(toIndex, 0, reorderedProgramId);
  }
}

function pendingReferredProgramPosition({ fromIndex, toIndex, reorderedProgramId, subsidy } = {}) {
  const programId = route.query?.programId;
  const currentFirstPositionProgramId = subsidy.program_ids[0];

  // Check if another program is being moved to index 0 while the programId is in current first position
  // (another program being moved up to first position replacing the referred program)
  // Check if the reorderedProgramId is the programId and is being moved fromIndex 0 (moved down)

  if (
    programId &&
    ((currentFirstPositionProgramId === programId && toIndex === 0) ||
      (fromIndex === 0 && reorderedProgramId === programId))
  ) {
    // The referred program is being moved from position one, open the program position info dialog
    programPositionDialog.value?.open();
    // Create a pendingReorder state so we can resume reordering if the user confirms
    pendingReorder.value = {
      fromIndex,
      toIndex,
      reorderedProgramId,
      subsidyId: subsidy?.id,
    };

    return true;
  }

  return false;
}

function confirmReorderReferredProgram() {
  // The user is confirming they want to reorder the referred program so use the pendingReorder state
  const { fromIndex, toIndex, subsidyId } = pendingReorder.value;
  const subsidy = subsidies.value.find((sub) => sub.id === subsidyId);

  handleReorderSelectedPrograms(fromIndex, toIndex, subsidy, { confirmed: true });

  // Reset the pending state to fresh and close the dialog
  pendingReorder.value = null;
  programPositionDialog.value?.close();
}

function handleSelectedCurrentProvider(id, subsidy) {
  const index = subsidies.value.findIndex((sub) => sub.id === subsidy.id);
  const updatedSubsidy = { ...subsidy, current_provider_id: id };
  subsidies.value[index] = updatedSubsidy;
}

function handleSelectedSiblings(ids, subsidy) {
  const index = subsidies.value.findIndex((sub) => sub.id === subsidy.id);
  const updatedSubsidy = { ...subsidy, sibling_provider_ids: ids };
  subsidies.value[index] = updatedSubsidy;
}

function handleSelectedStaff(ids, subsidy) {
  const index = subsidies.value.findIndex((sub) => sub.id === subsidy.id);
  const updatedSubsidy = { ...subsidy, staff_provider_ids: ids };
  subsidies.value[index] = updatedSubsidy;
}

function thirdPartyContinueDisabled() {
  if (
    thirdPartyApplication.value.third_party_application === false ||
    (thirdPartyApplication.value.third_party_application &&
      thirdPartyApplication.value.third_party_email &&
      thirdPartyApplication.value.third_party_first_name &&
      thirdPartyApplication.value.third_party_last_name &&
      thirdPartyApplication.value.third_party_phone_number)
  ) {
    return false;
  }

  return true;
}

async function eligibleSubsidiesAreStillEligible() {
  const subsidiesToBeChecked = eligibleSubsidies.value;
  const promises = subsidiesToBeChecked.map(async (subsidy) => {
    const response = await Api.subsidy.get(subsidy.id);
    return response.data;
  });

  const responses = await Promise.all(promises);
  return responses.every((response) => response.projected_eligibility !== false);
}

async function exit() {
  processing.value = true;
  finished.value = true;
  if (subsidyProgram.value.enable_submit_signout) {
    await Api.member.session.promiseDestroy();
    store.commit('setAuthenticated', false);
    store.commit('setProfile', null);
    if (subsidyProgram.value.submit_title) {
      eventBus.longChime('You have been automatically logged out for security.');
    }
    router.push({ path: '/' });
  } else {
    router.push({ name: 'Dashboard' });
  }
}

async function exitToDashboard() {
  if (subsidyProgram.value.submit_title) {
    eventBus.longChime(subsidyProgram.value.submit_title);
  }
  router.push({
    name: 'Dashboard',
    query: {
      submittedSurveySchemaId: subsidyProgram.value.submitted_survey_schema_id,
    },
  });
}

async function exitByLogout() {
  await Api.member.session.promiseDestroy();
  store.commit('setAuthenticated', false);
  store.commit('setProfile', null);
  if (subsidyProgram.value.submit_title) {
    eventBus.longChime(subsidyProgram.value.submit_title);
  }
  router.push({ path: '/' });
}

async function finish() {
  processing.value = true;
  if (!subsidyProgram.value.enable_parent_review_locked) {
    await saveGroup();
    await saveAllEligibleChildren();
  }

  if (!(await eligibleSubsidiesAreStillEligible())) {
    eventBus.longChime(NOW_INELIGIBLE_ERROR);
    transitionName.value = 'slide-right';
    return step({ sectionName: 'group' });
  }

  await submitAllEligibleSubsidies();

  finished.value = true;
  if (subsidyProgram.value.enable_submit_signout) {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    exitByLogout();
  } else {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    exitToDashboard();
  }

  return true;
}

async function backFromChildren() {
  processing.value = true;
  await saveChildren();
  await stepBack();
}

async function backFromConfirm() {
  // Reload all questions in case there were unsaved changes in review mode
  processing.value = true;
  await loadGroupQuestions();
  await loadQuestionsForAllChildren();
  await stepBack();
}

async function forwardFromChildren() {
  await saveChildren();
  await saveGroup();

  await createOrDestroySubsidies();
  // In local development mode, this wait is needed to ensure newly-created subsidies are returned by loadSubsidies
  await new Promise((resolve) => setTimeout(resolve, 100));
  await loadSubsidies();

  await loadChildren();
  await loadGroupQuestions();
  await loadQuestionsForAllChildren();

  await stepForward();
}

async function forwardFromFavoriteQuestion({ subsidy }) {
  processing.value = true;
  await Api.subsidy.update(subsidy.id, {
    current_provider_id: subsidy.current_provider_id,
    program_ids: subsidy.program_ids,
    provider_ids: subsidy.provider_ids,
    sibling_provider_ids: subsidy.sibling_provider_ids,
    staff_provider_ids: subsidy.staff_provider_ids,
  });
  await stepForward();
}

async function forwardFromThirdPartyQuestions() {
  processing.value = true;
  const updatePromises = eligibleSubsidies.value.map((subsidy) =>
    Api.subsidy.update(subsidy.id, {
      third_party_application: thirdPartyApplication.value.third_party_application,
      third_party_email: thirdPartyApplication.value.third_party_email,
      third_party_first_name: thirdPartyApplication.value.third_party_first_name,
      third_party_last_name: thirdPartyApplication.value.third_party_last_name,
      third_party_phone_number: thirdPartyApplication.value.third_party_phone_number,
    }),
  );
  const responses = await Promise.all(updatePromises);
  const anyResponsesUnsuccessful = responses.some((resp) => resp?.status !== 200);
  if (anyResponsesUnsuccessful) {
    processing.value = false;
    return;
  }
  await stepForward();
}

async function load() {
  loadFinished.value = false;
  const groupResponse = await Api.group.get();
  group.value = groupResponse.data;
  subsidyProgramId.value = route.params.subsidyProgramId;
  await loadSubsidies();

  if (subsidies.value?.length > 0 && subsidies.value.some((subsidy) => subsidy.submitted_at)) {
    finished.value = true;
    router.replace({ name: 'Dashboard' });
    return;
  }

  await loadChildren();
  await loadSubsidyProgram();
  await loadSubsidyProgramSchemas();

  await loadAttachments();
  if (subsidies.value[0]?.id) {
    await loadGroupQuestions(subsidies.value[0]?.id);
  }

  await loadQuestionsForAllChildren();
  maybeSetDefaultCityAndState();

  await updateQueryIfNeeded();
  loadFinished.value = true;
}

watch(() => loaded.value, updateQueryIfNeeded, { immediate: true });
watch(() => route.query.section, updateQueryIfNeeded, { immediate: true, deep: true });

async function updateQueryIfNeeded() {
  // TODO: Pull this into the useSubsidyStepUtils composable
  // We must be loaded before using a route query to set the section.
  // We don't want the section ref to have a default value or it loads the default before the route section
  if (!loaded.value) return;

  if (route.query.section && route.query.section !== section.value) {
    await step({ sectionName: route.query.section });
  } else if (!section.value) {
    // Wait until we've loaded and checked the route to set the default initial question:
    await step({ sectionName: subsidySections.groupHomeAddress });
  }
}

async function loadQuestionsForAllChildren() {
  for (const subsidy of subsidies.value) {
    await loadChildrenQuestions({ subId: subsidy.id, childId: subsidy.child_id });
  }
}

async function loadSubsidies() {
  const resp = await Api.subsidy.index({ subsidy_program_id: subsidyProgramId.value });
  subsidies.value = resp.data;
  updateThirdPartyApplicationValue();
}

function updateThirdPartyApplicationValue() {
  const subsidy = eligibleSubsidies.value[0];
  thirdPartyApplication.value = {
    third_party_application: subsidy?.third_party_application,
    third_party_email: subsidy?.third_party_email,
    third_party_first_name: subsidy?.third_party_first_name,
    third_party_last_name: subsidy?.third_party_last_name,
    third_party_phone_number: subsidy?.third_party_phone_number,
  };
}

async function loadSubsidyProgram() {
  const resp = await Api.public_api.organization.subsidy_program.get(subsidyProgramId.value);
  subsidyProgram.value = resp.data;
  reviewLocked.value = subsidyProgram.value.enable_parent_review_locked;
  document.title = `New application - ${subsidyProgram.value.name}`;
}

async function loadAttachments() {
  processing.value = true;
  const params = {
    owner_type: 'SubsidyProgram',
    owner_id: route.params.subsidyProgramId,
  };
  const resp = await Api.member.attachment.index(params);
  if (resp?.status !== 200) {
    processing.value = false;
    return;
  }

  attachments.value = resp.data;
  processing.value = false;
  return;
}

function attachmentsWithTag(tag) {
  return attachments.value.filter((attachment) => attachment.tag == tag);
}

function hasNoAttachmentsWithTag(tag) {
  return attachmentsWithTag(tag).length == 0;
}

async function loadChildren() {
  const resp = await Api.child.index();
  children.value = resp.data
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) // Ensure the order doesn't change
    .map((childData) => {
      const child = {
        ...childData,
        included: !!subsidyForChild(childData),
        editing: false,
      };
      return child;
    });
}

async function saveAllEligibleChildren() {
  await Promise.all(
    eligibleChildren.value.map(async (child) => {
      await saveChild(child);
    }),
  );
}

async function saveGroup() {
  const { data } = await Api.group.update(group.value);
  group.value = data;
}

async function saveChild(child) {
  // TODO: Add test coverage confirming changes to children and group persist
  await Api.child.update(child.id, child);
}

async function saveChildren() {
  await Promise.all(
    childrenWithSubsidies.value.map(async (child) => {
      await saveChild(child);
    }),
  );
}

async function saveGroupStepBack() {
  processing.value = true;
  await saveGroupLoadQuestions();
  await stepBack();
}
async function saveGroupStepForward() {
  processing.value = true;
  await saveGroupLoadQuestions();
  await stepForward();
}

async function saveGroupLoadSubsidiesStepForward() {
  processing.value = true;
  await loadSubsidies();
  await saveGroupLoadQuestions();
  await stepForward();
}

async function saveGroupLoadQuestions() {
  processing.value = true;
  await saveGroup();
  await loadGroupQuestions();
}

async function saveChildLoadQuestions(subId, childId) {
  processing.value = true;
  const child = childById(childId);
  await saveChild(child);
  await loadChildrenQuestions({ subId, childId });
}

async function saveChildByIdStepBack({ childId }) {
  processing.value = true;
  const subId = subsidyForChildId(childId).id;
  await saveChildLoadQuestions(subId, childId);
  await stepBack();
}

async function saveChildByIdStepForward({ childId }) {
  processing.value = true;
  const subId = subsidyForChildId(childId).id;
  await saveChildLoadQuestions(subId, childId);
  await stepForward();
}

async function saveChildByIdLoadSubsidiesStepForward({ childId }) {
  processing.value = true;
  const subId = subsidyForChildId(childId).id;
  await saveChildLoadQuestions(subId, childId);
  await loadSubsidies();
  await stepForward();
}

async function submitAllEligibleSubsidies() {
  const revisionParams = {
    revision_author_signed_name: null,
    revision_author_signature_image: null,
  };
  if (subsidyProgram.value.enable_esign) {
    revisionParams.revision_author_signed_name = attestation.value.esignName;
    revisionParams.revision_author_signature_image = attestation.value.getEsignSignature();
  }

  const currentSubsidies = eligibleSubsidies.value;
  // TODO: Do not change any program/provider ids when submitting. Do it before
  await Promise.all(
    currentSubsidies.map((subsidy) => {
      return Api.subsidy.update(subsidy.id, {
        test_code: route.query.test,
        revision_author_signed_name: revisionParams.revision_author_signed_name,
        revision_author_signature_image: revisionParams.revision_author_signature_image,
        submitted: true,
        program_ids: subsidy.program_ids,
        provider_ids: subsidy.provider_ids,
        sibling_provider_ids: subsidy.sibling_provider_ids,
        staff_provider_ids: subsidy.staff_provider_ids,
      });
    }),
  );
}

// TODO: Add test coverage for this
function reloadGroupQuestionsForDraftData() {
  // Wait briefly for the draft data to be updated
  setTimeout(() => {
    const subId = eligibleSubsidies.value[0]?.id;
    loadGroupQuestions(subId, group.value);
  }, 50);
}

// TODO: Add test coverage for this
async function reloadChildQuestionsForDraftData(childId) {
  const subId = subsidyForChildId(childId)?.id;
  // Wait briefly for the child data to be updated in eligibleChildren before using it to fetch questions
  setTimeout(() => {
    const draftChildData = eligibleChildren.value.find((child) => child.id === childId);
    loadChildrenQuestions({ subId, childId: draftChildData.id, draftData: draftChildData });
  }, 50);
}
</script>
