<template>
  <div class="w-100pc h-100pc bg-super-light-blue">
    <v-row
      class="w-100pc h-100pc bg-white oy-hidden"
      no-gutters
    >
      <v-col
        v-show="display.mdAndUp || activeThread == false"
        class="br-1 bc-extra-light-gray"
        cols="12"
        md="4"
      >
        <h1
          ref="title"
          class="bb-1 bc-extra-light-gray pb-2 fs-24 fw-600 mt-2"
        >
          <span class="ml-4">{{ $t('My messages') }}</span>
        </h1>
        <div class="pa-4 bb-1 bc-extra-light-gray d-flex align-center">
          <v-text-field
            v-model="query"
            :label="$t('Search by provider name')"
            data-cy="search_provider"
            density="comfortable"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />
          <v-btn
            @click="load"
            :loading="processing"
            class="ms-1"
            variant="text"
            icon
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </div>
        <div
          v-if="$store.state.pages.Messaging.features.enable_support"
          @click="switchThread()"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="support-thread"
          data-testid="support-thread"
        >
          <v-icon
            class="me-2"
            size="26"
          >
            support_agent
          </v-icon>
          <div>
            <div class="fs-18 fw-500">
              {{ $t($store.state.brand.organization_name) }}
            </div>
            <div class="fs-16 c-light-black">
              {{ $t('Need assistance? Message one of our staff members.') }}
            </div>
          </div>
          <v-spacer />
          <v-icon
            v-if="
              messagesWithOrg.length > 0 &&
              [messagesWithOrg[messagesWithOrg.length - 1]].some(
                (message) =>
                  (message.meta.member.is_provider || message.meta.member.is_specialist) &&
                  !message.read_at,
              )
            "
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
        <div
          v-for="provider in filteredProviderThreads"
          @click="switchThread(provider)"
          :key="provider.id"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="provider-thread"
        >
          <v-icon
            class="me-2"
            size="26"
          >
            storefront
          </v-icon>
          <div>
            <div class="fs-18 fw-500">
              {{ $t(provider.name) }}
            </div>
            <MarkdownContent
              :content="
                messagesWithProviders[provider.id][messagesWithProviders[provider.id].length - 1]
                  ?.text
              "
              class="fs-16 c-light-black h-20 oy-hidden"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="
              [
                messagesWithProviders[provider.id][messagesWithProviders[provider.id].length - 1],
              ].some(
                (message) =>
                  (message.meta.member.is_provider || message.meta.member.is_specialist) &&
                  !message.read_at,
              )
            "
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
      </v-col>

      <v-col
        v-show="display.mdAndUp || activeThread != false"
        class="p-relative"
        cols="12"
        data-cy="conversation"
        md="8"
      >
        <div class="h-100pc w-100pc d-flex flex-column">
          <MessageThreadHeader
            @back="switchThread(false)"
            :subtitle="$t(messageHeaderSubtitle)"
            :title="$t(messageHeaderTitle)"
          />

          <div
            v-if="$store.state.pages.Messaging.features.enable_support && activeThread == null"
            id="messages_list"
            :class="messageListClass"
            style="flex: 1 1 0"
          >
            <MessageItem
              v-for="message in messagesWithOrg"
              :key="message.id"
              :message="message"
            />
          </div>

          <div
            v-else
            id="messages_list"
            :class="messageListClass"
            style="flex: 1 1 0"
          >
            <div
              v-for="provider in filteredProviderThreads"
              :key="provider.id"
            >
              <div v-if="activeThread == provider">
                <MessageItem
                  v-for="message in messagesWithProviders[provider.id]"
                  :key="message.id"
                  :message="message"
                />
              </div>
            </div>
          </div>

          <div
            v-if="activeThread || $store.state.pages.Messaging.features.enable_support"
            class="bt-0 bc-extra-light-gray bg-white w-100pc"
            data-cy="message-composer"
          >
            <div class="pa-2">
              <v-row dense>
                <v-col>
                  <v-card
                    class="bb-1 bc-very-light-grey"
                    style="background: rgba(0, 0, 0, 0.04)"
                    flat
                    tile
                  >
                    <v-btn
                      @click="$refs.attachmentDialog.open({})"
                      class="mb-2"
                      data-cy="attach-button"
                      prepend-icon="attachment"
                      variant="flat"
                    >
                      {{ $t('Attach document') }}
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
              <AttachmentDialog
                @save="createAttachmentMessages"
                @upload="uploadedFiles.push($event)"
                ref="attachmentDialog"
                :processing="processing"
              />
              <v-textarea
                v-model="newMessageText"
                :aria-label="$t('Type message')"
                :disabled="noMessages"
                :label="$t('Message')"
                class="mb-2"
                data-cy="message-textarea"
                rows="3"
                variant="filled"
                hide-details
              />
              <v-btn
                @click="createMessage"
                :disabled="submitDisabled"
                :loading="processing"
                color="primary"
                data-cy="send-message-button"
                size="x-large"
                block
              >
                {{ $t('Send message') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import AttachmentDialog from '@/shared/components/attachments/AttachmentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MessageItem from '@/shared/components/MessageItem.vue';
import MessageThreadHeader from '@/shared/components/MessageThreadHeader.vue';
import { useDisplay } from 'vuetify';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const display = useDisplay();
const store = useStore();
const route = useRoute();
useI18n;

const activeThread = ref(display.mdAndUp.value ? null : false);
const messageListClass = ref('bg-white w-100pc d-flex flex-column pa-4 top-100 oy-scroll');
const messages = ref([]);
const messagesWithOrg = ref([]);
const messagesWithProviders = ref({});
const newMessageText = ref(null);
const processing = ref(false);
const providerThreads = ref([]);
const query = ref(null);
const uploadedFiles = ref([]);

// Template refs
const attachmentDialog = ref(null);

const filteredProviderThreads = computed(() => {
  if (query.value && query.value !== '') {
    return providerThreads.value.filter((thread) =>
      thread.name.toLowerCase().includes(query.value.toLowerCase()),
    );
  }
  return providerThreads.value;
});

const messageHeaderSubtitle = computed(() => {
  if (activeThread.value) {
    return activeThread.value.address;
  }
  if (store.state.pages.Messaging.features.enable_support) {
    return 'Support staff will message you on this channel.';
  }
  return 'Messages will appear here when you send or receive a message.';
});

const messageHeaderTitle = computed(() => {
  if (activeThread.value) {
    return activeThread.value.name;
  }
  if (store.state.pages.Messaging.features.enable_support) {
    return store.state.brand.organization_name;
  }
  return 'No messages yet!';
});

const noMessages = computed(() => {
  return activeThread.value == null && !store.state.pages.Messaging.features.enable_support;
});

const submitDisabled = computed(() => {
  return !newMessageText.value || newMessageText.value.trim().length === 0 || noMessages.value;
});

// --------------------------------------------------------------------------------------------------------------------------
function createMessage() {
  processing.value = true;
  Api.message.create(
    { text: newMessageText.value, provider_id: activeThread.value ? activeThread.value.id : null },
    (resp) => {
      messages.value.push(resp.data);
      processing.value = false;
      newMessageText.value = null;
      sortMessages();
      scrollToBottom();
    },
  );
}

async function createAttachmentMessages() {
  processing.value = true;

  const params = { provider_id: activeThread.value ? activeThread.value.id : null };

  const responses = await Promise.all(
    uploadedFiles.value.map(async (asset) => {
      const attachmentResponse = await Api.member.attachment.create(asset);
      if (attachmentResponse?.status !== 201) {
        return;
      }
      const messageResponse = await Api.message.promiseCreate({
        ...params,
        attachment_id: attachmentResponse.data.id,
      });
      messages.value.push(messageResponse.data);
      return messageResponse;
    }),
  );

  const allSuccessful = responses.every((resp) => resp?.status === 201);
  if (!allSuccessful) {
    attachmentDialog.value.cancelAll();
  } else {
    attachmentDialog.value.close();
  }

  uploadedFiles.value = [];
  processing.value = false;
  newMessageText.value = null;
  sortMessages();
  scrollToBottom();
}

function load() {
  messages.value = [];
  messagesWithOrg.value = [];
  messagesWithProviders.value = {};
  providerThreads.value = [];

  processing.value = true;
  Api.message.index({}, (resp) => {
    messages.value = resp.data;
    processing.value = false;
    sortMessages();
    scrollToBottom();
  });
}

function scrollToBottom() {
  nextTick(() => {
    const list = document.getElementById('messages_list');
    if (list) {
      list.scrollTop = list.scrollHeight;
    }
  });
}

function sortMessages() {
  messages.value.forEach((message) => {
    if (message.provider_id == null) {
      if (!messagesWithOrg.value.includes(message)) {
        messagesWithOrg.value.push(message);
      }
    } else {
      if (messagesWithProviders.value[message.provider_id] === undefined) {
        messagesWithProviders.value[message.provider_id] = [];
        if (!providerThreads.value.includes(message.meta.provider)) {
          providerThreads.value.push(message.meta.provider);
        }
      }

      if (!messagesWithProviders.value[message.provider_id].includes(message)) {
        messagesWithProviders.value[message.provider_id].push(message);
      }
    }
  });

  if (activeThread.value) {
    switchThread(
      filteredProviderThreads.value.find((thread) => thread.id === activeThread.value.id),
    );
  } else if (route.query.provider_id) {
    switchThread(
      filteredProviderThreads.value.find((thread) => thread.id === route.query.provider_id),
    );
  } else if (!store.state.pages.Messaging.features.enable_support && activeThread.value == null) {
    switchThread(providerThreads.value[0]);
  }
}

function switchThread(thread) {
  activeThread.value = thread;
  if (thread !== false) {
    scrollToBottom();
    let msg;
    if (thread) {
      msg =
        messagesWithProviders.value[activeThread.value.id][
          messagesWithProviders.value[activeThread.value.id].length - 1
        ];
    } else {
      msg = messagesWithOrg.value[messagesWithOrg.value.length - 1];
    }
    if (msg) {
      if (!msg.read_at) {
        Api.message.get(msg.id, () => {});
        msg.read_at = true;
      }
    }
  }
}

onMounted(() => {
  store.commit('setNewMessage', false);
  load();
});
</script>
