const ENROLLMENT_STATUSES = Object.freeze({
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  ENROLLED: 'Enrolled',
  GRADUATED: 'Graduated',
  OFFERED: 'Offered',
  PLACED: 'Placed',
  PROPOSED: 'Proposed',
  SELECTED: 'Selected',
  STANDBY: 'Standby',
  UNENROLLED: 'Unenrolled',
  WAITLISTED: 'Waitlisted',
  WITHDRAWN: 'Withdrawn',
});

const LABELS = Object.freeze({
  NOT_APPLICABLE: 'Not applicable',
});

const ROLES = Object.freeze({
  MANAGER: 'manager',
  PARENT: 'parent',
  SPECIALIST: 'specialist',
});

const STATUSES_BY_MODEL = Object.freeze({
  Agreement: [
    { value: 'accepted', name: 'Accepted' },
    { value: 'denied', name: 'Denied' },
    { value: 'drafted', name: 'Drafted' },
    { value: 'sent', name: 'Draft' },
    { value: 'signed', name: 'Signed' },
  ],
  Assessment: [
    { value: 'completed', name: 'Completed' },
    { value: 'in_progress', name: 'In progress' },
    { value: 'requested', name: 'Requested' },
  ],
  BackgroundCheck: [
    { value: 'pending', name: 'Pending' },
    { value: 'conditional', name: 'Conditional' },
    { value: 'enrolled', name: 'Enrolled' },
    { value: 'suspended', name: 'Suspended' },
    { value: 'expired', name: 'Expired' },
  ],
  Complaint: [
    { value: 'submitted', name: 'Submitted' },
    { value: 'in_review', name: 'In review' },
    { value: 'substantiated', name: 'Substantiated' },
    { value: 'unsubstantiated', name: 'Unsubstantiated' },
  ],
  Enrollment: [
    { value: 'accepted', name: 'Accepted' },
    { value: 'declined', name: 'Declined' },
    { value: 'enrolled', name: 'Enrolled' },
    { value: 'graduated', name: 'Graduated' },
    { value: 'offered', name: 'Offered' },
    { value: 'placed', name: 'Placed' },
    { value: 'proposed', name: 'Proposed' },
    { value: 'selected', name: 'Selected' },
    { value: 'standby', name: 'Standby' },
    { value: 'unenrolled', name: 'Unenrolled' },
    { value: 'waitlisted', name: 'Waitlisted' },
    { value: 'withdrawn', name: 'Withdrawn' },
  ],
  FamilySubsidy: [
    { value: 'approved', name: 'Approved' },
    { value: 'awaiting_documents', name: 'Awaiting documents' },
    { value: 'denied', name: 'Denied' },
    { value: 'reviewed', name: 'Reviewed' },
    { value: 'submitted', name: 'Submitted' },
    { value: 'waitlist', name: 'Waitlist' },
    { value: 'withdrawn', name: 'Withdrawn' },
  ],
  Form: [
    { value: 'approved', name: 'Approved' },
    { value: 'awaiting_documents', name: 'Awaiting documents' },
    { value: 'denied', name: 'Denied' },
    { value: 'in_progress', name: 'In progress' },
    { value: 'reviewed', name: 'Reviewed' },
    { value: 'submitted', name: 'Submitted' },
    { value: 'withdrawn', name: 'Withdrawn' },
  ],
  FundsTransfer: [
    { value: 'draft', name: 'Draft' },
    { value: 'paid', name: 'Paid' },
  ],
  Grant: [
    { value: 'in_progress', name: 'In progress' },
    { value: 'submitted', name: 'Submitted' },
    { value: 'in_review', name: 'In review' },
    { value: 'awaiting_documents', name: 'Awaiting documents' },
    { value: 'denied', name: 'Denied' },
    { value: 'withdrawn', name: 'Withdrawn' },
    { value: 'approved', name: 'Approved' },
  ],
  License: [
    { value: 'active', name: 'Active' },
    { value: 'expired', name: 'Expired' },
    { value: 'conditional', name: 'Conditional' },
  ],
  Payment: [
    { value: 'draft', name: 'Draft' },
    { value: 'approved', name: 'Approved' },
    { value: 'canceled', name: 'Cancelled' },
    { value: 'processing', name: 'Processing' },
    { value: 'processed', name: 'Processed' },
  ],
  Review: [
    { value: 'approved', name: 'Approved' },
    { value: 'awaiting_documents', name: 'Awaiting documents' },
    { value: 'denied', name: 'Denied' },
    { value: 'in_progress', name: 'In progress' },
    { value: 'reviewed', name: 'Reviewed' },
    { value: 'submitted', name: 'Submitted' },
    { value: 'withdrawn', name: 'Withdrawn' },
  ],
  Subsidy: [
    { value: 'awaiting_documents', name: 'Awaiting documents' },
    { value: 'in_progress', name: 'In progress' },
    { value: 'submitted', name: 'Submitted' },
    { value: 'denied', name: 'Denied' },
    { value: 'withdrawn', name: 'Withdrawn' },
    { value: 'in_review', name: 'In review' },
    { value: 'reviewed', name: 'Reviewed' },
    { value: 'approved', name: 'Approved' },
    { value: 'waitlist', name: 'Waitlist' },
  ],
});

const WORKFLOW_ACTION_NAMES = Object.freeze({
  CREATE: 'create_record',
  NOTIFY: 'notify',
  ASSIGN: 'assign',
  UPDATE: 'update',
});

// Defines a subset of the allowed extensions in the asset upload API as of 2024-05-21
//   Omissions: mov, mpg, mpeg, avi, ogv, webm
//   See https://bridgecare.atlassian.net/browse/CARE-578 for follow-up
const SUPPORTED_DOCUMENT_EXTENSIONS = ['csv', 'doc', 'docx', 'pdf', 'xls', 'xlsx'];
const SUPPORTED_VIDEO_EXTENSIONS = ['mp4'];
const SUPPORTED_IMAGE_EXTENSIONS = [
  'heic',
  'heif',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'tif',
  'tiff',
  'webp',
];

const ALL_SUPPORTED_EXTENSIONS = [
  ...SUPPORTED_DOCUMENT_EXTENSIONS,
  ...SUPPORTED_IMAGE_EXTENSIONS,
  ...SUPPORTED_VIDEO_EXTENSIONS,
];

const ALL_SUPPORTED_EXTENSIONS_SET = new Set(ALL_SUPPORTED_EXTENSIONS);

const UNSUPPORTED_LMS_EXTENSIONS = new Set(['csv', 'xls', 'xlsx', 'doc', 'docx']);

export {
  ENROLLMENT_STATUSES,
  LABELS,
  ROLES,
  STATUSES_BY_MODEL,
  WORKFLOW_ACTION_NAMES,
  ALL_SUPPORTED_EXTENSIONS,
  ALL_SUPPORTED_EXTENSIONS_SET,
  SUPPORTED_DOCUMENT_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS,
  SUPPORTED_IMAGE_EXTENSIONS,
  UNSUPPORTED_LMS_EXTENSIONS,
};
