import Api from '@/manager/services/bright_finder';
import SubsidyQuestions from '@/shared/services/subsidy/questions.js';
import SubsidyApplicationData from '@/shared/services/subsidy/application-data.js';

export default function useSubsidyData(includeUnpublished, subsidyId, subsidyProgram) {
  const childData = ref(null);
  const childSchema = ref(null);
  const groupData = ref(null);
  const groupSchema = ref(null);
  // Arrays of group questions
  const groupEligibilityQuestions = ref(null);
  const groupOtherQuestions = ref(null);
  const groupVerificationQuestions = ref(null);
  // Arrays of child questions for one subsidy
  const childEligibilityQuestions = ref(null);
  const childOtherQuestions = ref(null);
  const childVerificationQuestions = ref(null);
  // Objects of child questions indexed by child ID (handling multiple children)
  const childrenEligibilityQuestions = ref({});
  const childrenOtherQuestions = ref({});
  const childrenVerificationQuestions = ref({});

  // Set child and group data for 1 subsidy
  async function loadChildDataAndGroupData(subId) {
    const currentSubsidyId = subId || subsidyId.value;
    if (!currentSubsidyId) {
      return;
    }

    const [childApplicationDataResponse, groupApplicationDataResponse] = await Promise.all([
      SubsidyApplicationData.getChildData(currentSubsidyId),
      SubsidyApplicationData.getGroupData(currentSubsidyId),
    ]);

    childData.value = childApplicationDataResponse.data;
    groupData.value = groupApplicationDataResponse.data;
  }

  // Set child and group questions for 1 subsidy
  async function loadChildAndGroupQuestions(subId) {
    const currentSubsidyId = subId || subsidyId.value;
    if (!currentSubsidyId) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value };
    const [childQuestions, groupQuestions] = await Promise.all([
      SubsidyQuestions.loadChildQuestions(currentSubsidyId, options),
      SubsidyQuestions.loadGroupQuestions(currentSubsidyId, options),
    ]);

    childEligibilityQuestions.value = childQuestions.childEligibilityQuestions;
    childOtherQuestions.value = childQuestions.childOtherQuestions;
    childVerificationQuestions.value = childQuestions.childVerificationQuestions;
    groupEligibilityQuestions.value = groupQuestions.groupEligibilityQuestions;
    groupOtherQuestions.value = groupQuestions.groupOtherQuestions;
    groupVerificationQuestions.value = groupQuestions.groupVerificationQuestions;
  }

  // Set child questions for 1 subsidy based on persisted or draft data
  async function loadChildQuestions(subId, draftData = null) {
    const currentSubsidyId = subId || subsidyId.value;
    if (!currentSubsidyId) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value, draftData };
    const childQuestions = await SubsidyQuestions.loadChildQuestions(currentSubsidyId, options);
    childEligibilityQuestions.value = childQuestions.childEligibilityQuestions;
    childOtherQuestions.value = childQuestions.childOtherQuestions;
    childVerificationQuestions.value = childQuestions.childVerificationQuestions;
  }

  // Set child questions for 1 subsidy (when there may be multiple)
  async function loadChildrenQuestions({ subId, childId, draftData = null }) {
    if (!subId || !childId) return;

    const options = { includeUnpublished: includeUnpublished.value, draftData };
    const childQuestions = await SubsidyQuestions.loadChildQuestions(subId, options);
    childrenEligibilityQuestions.value[childId] = childQuestions.childEligibilityQuestions;
    childrenOtherQuestions.value[childId] = childQuestions.childOtherQuestions;
    childrenVerificationQuestions.value[childId] = childQuestions.childVerificationQuestions;
  }

  // Load questions for group based on persisted or draft data
  async function loadGroupQuestions(subId, draftData = null) {
    const currentSubsidyId = subId || subsidyId.value;
    if (!currentSubsidyId) {
      console.log("Can't load group questions without a subsidy ID");
      return;
    }
    const options = { includeUnpublished: includeUnpublished.value, draftData };
    const groupQuestions = await SubsidyQuestions.loadGroupQuestions(currentSubsidyId, options);

    groupEligibilityQuestions.value = groupQuestions.groupEligibilityQuestions;
    groupOtherQuestions.value = groupQuestions.groupOtherQuestions;
    groupVerificationQuestions.value = groupQuestions.groupVerificationQuestions;
  }

  // Specialists in reviewer mode only use API-filtered questions in the Documents section
  // without needing to call the API for other question sections
  async function loadVerificationQuestions(subId) {
    const currentSubsidyId = subId || subsidyId.value;
    if (!currentSubsidyId) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value };

    childVerificationQuestions.value = (
      await SubsidyQuestions.getChildVerificationQuestions(currentSubsidyId, options)
    )?.data;
    groupVerificationQuestions.value = (
      await SubsidyQuestions.getGroupVerificationQuestions(currentSubsidyId, options)
    )?.data;
  }

  function loadSubsidyProgramSchemas() {
    if (!subsidyProgram.value) {
      return;
    }

    Api.public_api.organization.schema.get(subsidyProgram.value.group_schema_id, (resp) => {
      groupSchema.value = resp.data;
    });

    Api.public_api.organization.schema.get(subsidyProgram.value.child_schema_id, (resp) => {
      childSchema.value = resp.data;
    });

    // add provider schema for when we'll need to pass it through to ProviderMatch
    // https://bridgecare-finance.slack.com/archives/C02HE12EYFJ/p1728497719545089?thread_ts=1728447035.808729&cid=C02HE12EYFJ
  }

  return {
    childData,
    childEligibilityQuestions,
    childOtherQuestions,
    childrenEligibilityQuestions,
    childrenOtherQuestions,
    childrenVerificationQuestions,
    childSchema,
    childVerificationQuestions,
    groupData,
    groupEligibilityQuestions,
    groupOtherQuestions,
    groupSchema,
    groupVerificationQuestions,
    loadChildAndGroupQuestions,
    loadChildDataAndGroupData,
    loadChildQuestions,
    loadChildrenQuestions,
    loadGroupQuestions,
    loadSubsidyProgramSchemas,
    loadVerificationQuestions,
  };
}
