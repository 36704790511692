<template>
  <v-row dense>
    <v-col
      :class="dense ? 'fs-14' : 'fs-18'"
      class="d-flex align-center word-break-word"
      cols="12"
      data-cy="document_name"
      md="8"
    >
      <ActionMenu
        v-if="removable"
        @click:action:delete="$emit('remove')"
        :items="[
          {
            event: 'delete',
            title: 'Delete',
            icon: 'delete',
          },
        ]"
        button-icon="more_vert"
      />

      <div
        class="d-inline-block"
        style="overflow: none"
      >
        <a
          v-text="att.file.metadata.filename"
          :href="att.url"
          class="focus-very-visible ms-2 me-1"
          target="_blank"
        />

        <div
          v-if="uploadedBy"
          class="fs-14 ms-2"
        >
          <span
            v-text="uploadedBy"
            class="me-1"
          />

          <LongDateTime :date="att.created_at" />
        </div>
      </div>
    </v-col>

    <v-col
      v-if="status"
      class="ta-right"
      cols="4"
    >
      <template v-if="isSpecialist">
        <v-menu
          location="bottom right"
          dense
        >
          <template #activator="{ props }">
            <v-btn
              @click.stop.prevent
              v-bind="props"
              :color="statusColor"
              class="ms-1 me-3"
              variant="flat"
            >
              <span v-t="att.status" />
              <v-icon end> expand_more </v-icon>
            </v-btn>
          </template>

          <v-list
            class="py-0"
            border
            tile
          >
            <v-list-item @click="$emit('change:status', 'Submitted')">
              <span
                v-t="'Submitted'"
                class="fs-14"
              />
            </v-list-item>

            <v-divider />

            <v-list-item @click="$emit('change:status', 'Accepted')">
              <span
                v-t="'Accepted'"
                class="fs-14"
              />
            </v-list-item>

            <v-divider />

            <v-list-item @click="$emit('change:status', 'Rejected')">
              <span
                v-t="'Rejected'"
                class="fs-14"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-else>
        <v-chip
          :color="statusColor"
          :ripple="false"
          class="me-3"
          variant="outlined"
        >
          <span v-t="att.status" />
        </v-chip>
      </template>
    </v-col>

    <v-col
      v-if="att.status == 'Rejected'"
      class="mt-2"
      cols="12"
    >
      <v-row dense>
        <template v-if="isSpecialist">
          <LabeledTextfield
            v-model="note"
            @input="noteChanged = true"
            data-testid="note"
            message="Rejection reason"
          />
        </template>

        <template v-else>
          <p
            v-text="note"
            class="pa-4 bg-super-light-gray fs-16 w-100pc b-1 bc-light-gray"
            data-testid="note"
          />
        </template>
      </v-row>

      <v-row
        v-if="isSpecialist"
        dense
      >
        <v-col>
          <v-btn
            @click="saveNote()"
            :disabled="!noteChanged"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  att: {
    type: Object,
    default: null,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  removable: {
    type: Boolean,
    default: true,
  },
  status: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change:note', 'change:status', 'remove']);

const member = ref(null);
const note = ref(null);
const noteChanged = ref(false);

const isSpecialist = computed(() => role.value === 'specialist');
const role = computed(() => store.state.role);

const statusColor = computed(() => {
  if (props.att.status === 'Accepted') return 'green';
  if (props.att.status === 'Rejected') return 'red';
  return 'grey-lighten-1';
});

const uploadedBy = computed(() => {
  if (member.value) return `Uploaded by ${member.value.name} on`;
  return null;
});

onMounted(async () => {
  if (isSpecialist.value) await loadMember();

  note.value = props.att.note;
});

async function loadMember() {
  if (!props.att.member_id) return;

  const response = await Api.organization.member.get(props.att.member_id);
  if (response?.status !== 200) return;
  member.value = response.data;
}

function saveNote() {
  emit('change:note', note.value);
  noteChanged.value = false;
}
</script>
