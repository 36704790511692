<template>
  <v-col
    :class="colClasses"
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <template v-if="checkable">
      <template v-if="checkLocked">
        <v-checkbox-btn
          @click.stop="$emit('check')"
          :model-value="checkValue"
          class="me-2 py-1 mt-0 flex-0-0"
          data-cy="checkbox"
          data-testid="checkbox"
          dense
          hide-details
          readonly
        />
      </template>

      <template v-else>
        <v-checkbox-btn
          v-model="checked"
          @click.stop
          @update:model-value="$emit('check')"
          class="me-2 py-1 mt-0 flex-0-0"
          data-cy="checkbox"
          data-testid="checkbox"
          dense
          hide-details
        />
      </template>
    </template>

    <div role="cell">
      <slot>
        <template v-if="to">
          <router-link
            :class="contentClasses"
            :target="target"
            :to="to"
          >
            {{ content }}
          </router-link>
        </template>

        <template v-else>
          <div :class="link ? 'c-primary underlined pointer' : ''">
            <template v-if="transform == 'currency'">
              <div
                @click="$emit('click')"
                :class="contentClasses"
              >
                {{ currency(content) }}
              </div>
            </template>

            <template v-else-if="transform == 'date'">
              <LongDate
                @click="$emit('click')"
                :class="contentClasses"
                :date="content"
                short-month
              />
            </template>

            <template v-else-if="transform == 'date-time'">
              <LongDateTime
                @click="$emit('click')"
                :class="contentClasses"
                :date="content"
                short-month
              />
            </template>

            <template v-else>
              <div
                @click="$emit('click')"
                v-text="content == null ? content : $t(String(content))"
                :class="contentClasses"
              />
            </template>
          </div>
        </template>
      </slot>
    </div>
  </v-col>
</template>

<script setup>
import LongDate from '@/shared/components/LongDate.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import { currency } from '@/plugins/filters';

const props = defineProps({
  checkable: {
    type: Boolean,
    default: false,
  },
  checkLocked: {
    type: Boolean,
    default: false,
  },
  checkValue: {
    type: Boolean,
    default: false,
  },
  contentClasses: {
    type: String,
    default: '',
  },
  cols: {
    type: String,
    default: null,
  },
  content: {
    type: [String, Number],
    default: '',
  },
  horizontalAlign: {
    type: String,
    default: null,
  },
  link: {
    type: Boolean,
    default: false,
  },
  md: {
    type: String,
    default: null,
  },
  sm: {
    type: String,
    default: null,
  },
  target: {
    type: String,
    default: '_blank',
  },
  to: {
    type: Object,
    default: null,
  },
  transform: {
    type: String,
    default: null,
  },
});

defineEmits(['check', 'click']);

const checked = ref(false);

const colClasses = computed(() => {
  const classes = 'align-center c-black fs-16 ox-hidden'.split(' ');

  switch (props.horizontalAlign) {
    case 'center':
      classes.push('d-flex-center');
      break;
    case 'right':
      classes.push('ta-right');
      break;
    default:
      classes.push('d-flex');
      break;
  }

  return classes.join(' ');
});

function check(value) {
  checked.value = value;
}

defineExpose({ check });
</script>
