<template>
  <span v-text="fullString"></span>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const haitianCreoleFormatting = {
  long: [
    'Janvye',
    'Fevriye',
    'Mas',
    'Avil',
    'Me',
    'Jen',
    'Jiyè',
    'Out',
    'Septanm',
    'Oktòb',
    'Novamn',
    'Desamn',
  ],
};
const props = defineProps({
  date: {
    type: String,
    default: null,
  },
  hideYear: Boolean,
  nulltext: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  prefixSeparator: {
    type: String,
    default: '',
  },
  suffix: {
    type: String,
    default: '',
  },
  shortMonth: {
    boolean: false,
    type: Boolean,
  },
  timezone: {
    default() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    type: String,
  },
});

const fullString = computed(() => {
  const str = [];
  if (props.prefix) {
    str.push(`${t(props.prefix)}${props.prefixSeparator}`);
  }

  str.push(localeDateTimeString.value);

  if (props.suffix) {
    str.push(t(props.suffix));
  }

  return str.join(' ');
});

const localeDateTimeString = computed(() => {
  if (!props.date) {
    return '';
  }
  const date = new Date(props.date);
  const day = date.getUTCDate();

  if (locale.value === 'ht') {
    const month = haitianCreoleFormatting.long[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const haitianTimeZone = date.toLocaleTimeString('ht', {
      timeZone: 'America/Port-au-Prince',
    });
    return `${day} ${month} ${year}, ${haitianTimeZone}`;
  } else {
    const options = {
      month: props.shortMonth ? 'short' : 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: props.timezone,
    };
    if (!props.hideYear) {
      options.year = 'numeric';
    }
    return date.toLocaleDateString(locale.value, options);
  }
});
</script>
