import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/manager/grants/${id}`;
    return `${window.api_url}/manager/grants`;
  },

  create(grant, success, failure) {
    axios.post(this.baseUrl(), { grant }).then(success).catch(failure);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(params) {
    return axios.get(this.baseUrl(), { params });
  },

  update(id, grant) {
    return axios.patch(this.baseUrl(id), { grant }).catch(handleError);
  },

  submit(id, params) {
    return axios.patch(`${this.baseUrl(id)}/submit`, params).catch(handleError);
  },
};
