<template>
  <FormQuestion
    @back="$emit('back')"
    @next="$emit('next')"
    :condensed="condensed"
    :dense="dense"
    :forwardable="forwardable"
    :hide-actions="readonly"
    :next-disabled="nextDisabled"
    :processing="processing"
    :readonly="readonly"
    :tile="readonly"
    class="focus-very-visible"
    next-text="Continue"
    title="Are you someone other than a family member, like a community volunteer or a school staff member, filling out this application on their behalf?"
  >
    <v-row dense>
      <v-radio-group
        v-model="subsidy.third_party_application"
        :readonly="readonly"
        class="mt-0"
      >
        <v-radio
          :label="$t('Yes')"
          :value="true"
          class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
        />
        <v-radio
          :label="$t('No')"
          :value="false"
          class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
        />
      </v-radio-group>
    </v-row>
    <v-row
      v-if="subsidy.third_party_application"
      class="px-2"
      dense
    >
      <p
        v-if="!readonly"
        class="mb-2"
      >
        {{
          $t(
            'Please provide the following information about yourself to track who entered this application.',
          )
        }}
      </p>
      <LabeledTextfield
        v-model="subsidy.third_party_first_name"
        :hard-lock="readonly"
        aria-label="Enter first name"
        data-cy="First Name"
        message="First name"
      />
      <LabeledTextfield
        v-model="subsidy.third_party_last_name"
        :hard-lock="readonly"
        aria-label="Enter last name"
        data-cy="Last Name"
        message="Last name"
      />
      <LabeledTextfield
        v-model="subsidy.third_party_email"
        :hard-lock="readonly"
        aria-label="Enter email"
        data-cy="email"
        message="Email"
      />
      <LabeledTextfield
        v-model="subsidy.third_party_phone_number"
        :hard-lock="readonly"
        aria-label="Enter phone number"
        data-cy="phone number"
        message="Phone number"
      />
    </v-row>
  </FormQuestion>
</template>

<script>
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormQuestion,
    LabeledTextfield,
  },

  props: {
    condensed: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forwardable: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['back', 'next'],

  data() {
    return {
      subsidy: this.modelValue,
    };
  },
};
</script>
