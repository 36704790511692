<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        :class="labelClass"
        cols="12"
      >
        {{ $t(label) }}
      </v-col>
      <v-col>
        <v-select
          v-model="localValue"
          @blur="handleBlur"
          @update:model-value="handleUpdateModelValue"
          :aria-label="ariaLabel"
          :chips="propertyIsArray || undefined"
          :closable-chips="propertyIsArray || undefined"
          :density="dense ? 'compact' : undefined"
          :disabled="locked || undefined"
          :item-title="capitalizeAndTranslate"
          :items="translatedItems"
          :menu-icon="locked ? 'lock' : 'arrow_drop_down'"
          :multiple="propertyIsArray || undefined"
          :placeholder="$t(placeholderText)"
          :prefix="prefixText"
          variant="solo-filled"
          data-cy
          flat
          hide-details
          tile
          v-bind="$attrs"
        >
          <template
            v-if="selectAll"
            #prepend-item
          >
            <v-list-item
              @click="toggleSelectAll()"
              :prepend-icon="selectAllIcon"
            >
              <v-list-item-title>{{ $t('Select all') }}</v-list-item-title>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-col>
</template>

<script setup>
import attrsOmitDataPrefix from '@/shared/utils/filteredAttrs';
import propsToRefs from '@/shared/utils/propsToRefs';
import useLabeledField from '@/shared/composables/useLabeledField';
import useSchematizedField from '@/shared/composables/useSchematizedField';
import { useI18n } from 'vue-i18n';
import { onUpdated } from 'vue';

const localValue = defineModel({ type: undefined });

const props = defineProps({
  capitalize: {
    type: Boolean,
    default: true,
  },
  items: {
    type: Array,
    default: null,
  },
  multiple: {
    type: Boolean,
    default: null,
  },
  prefixText: {
    type: String,
    default: null,
  },
  selectAll: {
    type: Boolean,
    default: false,
  },
  ...useLabeledField.props,
  ...useSchematizedField.props,
});

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['change:clear-all', 'change:select-all'].concat(useLabeledField.emits));
const attrs = useAttrs();
const { t } = useI18n();

const filteredAttrs = ref(attrsOmitDataPrefix(attrs));
const selectAllActive = ref(false);

const { labelClass, handleBlur, handleUpdateModelValue } = useLabeledField(
  ...propsToRefs(props, useLabeledField.paramKeys),
);
const { ariaLabel, enabled, label, locked, propertyBySchema } = useSchematizedField(
  ...propsToRefs(props, useSchematizedField.paramKeys),
);

const propertyIsArray = computed(() => {
  if (props.multiple != null) return props.multiple;
  return propertyBySchema.value?.type === 'array';
});

const placeholderText = computed(() => {
  if (propertyIsArray.value) return 'Select all that apply';
  return 'Select';
});

const selectAllIcon = computed(() => {
  if (selectAllActive.value) return 'check_box';
  return 'check_box_outline_blank';
});

const translatedItems = computed(() => {
  const selectItems =
    props.items || propertyBySchema.value?.enum || propertyBySchema.value?.items?.enum || [];

  if (
    filteredAttrs.value['item-text'] ||
    filteredAttrs.value['item-title'] ||
    selectItems[0]?.text
  ) {
    return selectItems;
  }
  return selectItems.map((item) => ({ text: item, value: item }));
});

function capitalizeAndTranslate(item) {
  const itemTitle = filteredAttrs.value['item-text'] || filteredAttrs.value['item-title'];

  let translation = t(itemTitle ? item[itemTitle] : item.text);

  if (props.capitalize && translation) {
    return translation.charAt(0).toUpperCase() + translation.slice(1);
  }

  return translation;
}

function toggleSelectAll() {
  selectAllActive.value = !selectAllActive.value;

  if (selectAllActive.value === false) emit('change:clear-all');
  else emit('change:select-all');
}

onUpdated(() => {
  filteredAttrs.value = attrsOmitDataPrefix(attrs);
});
</script>
