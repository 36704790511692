<template>
  <v-container class="mxw-800 mx-auto py-12 px-1">
    <template v-if="!isTest && isClosed">
      <p v-t="'Program closed'" />
    </template>

    <template v-else-if="!isTest && !isOpen">
      <p v-t="'Program not open'" />
    </template>

    <template v-else>
      <slot name="open" />
    </template>
  </v-container>
</template>

<script setup>
import { useRoute } from 'vue-router';
const route = useRoute();

const props = defineProps({
  subsidyProgram: {
    type: Object,
    default: null,
  },
});
const isTest = computed(() => {
  return route.query.test === 'daremightythings';
});

const isClosed = computed(() => {
  if (props.subsidyProgram?.closes_at) {
    return convertToUTC(new Date()) > convertToUTC(props.subsidyProgram.closes_at);
  }

  return false;
});

const isOpen = computed(() => {
  if (props.subsidyProgram?.opens_at) {
    return convertToUTC(new Date()) > convertToUTC(props.subsidyProgram.opens_at);
  }

  return true;
});

function convertToUTC(date) {
  const inputDate = new Date(date);

  const utcYear = inputDate.getUTCFullYear();
  const utcMonth = inputDate.getUTCMonth();
  const utcDay = inputDate.getUTCDate();
  const utcHours = inputDate.getUTCHours();
  const utcMinutes = inputDate.getUTCMinutes();
  const utcSeconds = inputDate.getUTCSeconds();

  return new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds));
}
</script>
