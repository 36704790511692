<template>
  <div class="bg-white">
    <template v-if="!loaded">
      <v-progress-linear indeterminate />
    </template>
    <template v-else>
      <v-toolbar
        v-if="toolbar"
        class="fullwidth bc-outlined-gray bb-1"
        elevation="0"
        style="position: fixed; top: 64px; z-index: 1"
      >
        <v-row class="d-flex align-center">
          <v-col class="d-flex justify-start">
            <v-btn
              :aria-label="$t('Back to child profile')"
              :to="{ name: 'Dashboard' }"
              class="focus-very-visible me-2 font-weight-bold"
              prepend-icon="west"
              variant="text"
            >
              {{ $t('Back') }}
            </v-btn>
          </v-col>

          <v-col class="d-flex justify-center">
            <v-tabs
              align-tabs="center"
              hide-slider
            >
              <template v-if="tabs.includes('Application')">
                <v-tab
                  :class="$route.query.tab == 'Application' ? 'bb-2  bc-primary' : ''"
                  :to="{ query: { tab: 'Application' } }"
                  class="c-black fs-16 ls-normal tt-none"
                  data-cy="tab-enrollment"
                >
                  {{ $t('Application') }}
                </v-tab>
              </template>

              <template v-if="tabs.includes('Claims')">
                <v-tab
                  :class="$route.query.tab == 'Claims' ? 'bb-2  bc-primary' : ''"
                  :to="{ query: { tab: 'Claims' } }"
                  class="c-black fs-16 ls-normal tt-none"
                  data-cy="tab-enrollment"
                >
                  {{ $t('Claims') }}
                </v-tab>
              </template>
            </v-tabs>
          </v-col>

          <v-col class="d-flex justify-end">
            <v-btn
              :aria-label="$t('Download application')"
              :href="link"
              class="focus-very-visible"
              color="secondary"
              target="_blank"
              variant="outlined"
            >
              <span class="fs-16">{{ $t('Download') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-container
        class="py-8"
        fluid
      >
        <div style="padding-top: 60px">
          <v-window v-model="tab">
            <v-window-item
              class="mxw-800 mx-auto"
              value="Application"
            >
              <div data-cy="alert">
                <v-alert
                  v-if="subsidy.projected_eligibility == false"
                  border="start"
                  class="focus-very-visible w-100pc mt-6 fs-20"
                  color="primary"
                  tabindex="0"
                  type="info"
                >
                  <div>
                    {{
                      $t(
                        'You are not eligible for this program at this time. Please contact support if you believe this is incorrect.',
                      )
                    }}
                  </div>
                </v-alert>

                <v-alert
                  v-if="subsidy.projected_eligibility && closed"
                  border="start"
                  class="w-100pc mb-8 fs-20"
                  color="primary"
                  tabindex="0"
                  type="info"
                >
                  <span
                    v-if="subsidy.submitted_at"
                    class="me-1"
                    data-testid="application-submitted-at-closed"
                  >
                    <LongDateTime
                      :date="subsidy.submitted_at"
                      prefix="Application submitted on"
                    />.
                  </span>
                  <span>{{
                    $t('At this time your application is locked and you cannot make edits.')
                  }}</span>
                </v-alert>

                <v-alert
                  v-if="subsidy.projected_eligibility != false && !closed"
                  border="start"
                  class="focus-very-visible w-100pc mb-8 fs-20"
                  color="secondary"
                  tabindex="0"
                  type="info"
                >
                  <span
                    v-if="subsidy.submitted_at"
                    class="me-1"
                    data-testid="application-submitted-at-open"
                  >
                    <LongDateTime
                      v-if="subsidy.submitted_at"
                      :date="subsidy.submitted_at"
                      prefix="Application submitted on"
                    />.
                  </span>

                  <span
                    v-if="familySubsidyProgram.closes_at && !closed"
                    class="me-1"
                  >
                    <LongDate
                      v-if="familySubsidyProgram.closes_at"
                      :date="familySubsidyProgram.closes_at"
                      prefix="You can make edits until"
                    />.
                  </span>
                </v-alert>
              </div>

              <SubsidyEligibilityCard
                :elevation="2"
                :funding-sources="subsidy.eligibility_determinations"
                :projected-eligibility="subsidy.projected_eligibility"
                class="focus-very-visible mb-6"
                tabindex="0"
                expanded
                explanation
                outlined
              />

              <QuestionSet
                v-model="subsidy"
                @change="changed = true"
                :attachment-group-id="subsidy.group_id"
                :attachment-owner-id="subsidy.id"
                :attachment-owner-type="'FamilySubsidy'"
                :attachment-readonly="subsidy.locked"
                :attachments="familySubsidyAttachments"
                :color="null"
                :elevation="0"
                :processing="processing"
                :questions="validEligibilityQuestions"
                :readonly="readonly"
                :schema="schema.definition"
                condensed
                dense
                divided
                expanded
                hide-actions
                tile
              />

              <ThirdPartyQuestions
                v-if="familySubsidyProgram.enable_third_party_applications"
                key="third-party-questions"
                :model-value="subsidy"
                :processing="processing"
                question-class="focus-very-visible"
                readonly
              />

              <v-divider class="my-8" />

              <QuestionSet
                v-model="subsidy"
                @change="changed = true"
                :attachment-group-id="subsidy.group_id"
                :attachment-owner-id="subsidy.id"
                :attachment-owner-type="'FamilySubsidy'"
                :attachment-readonly="subsidy.locked"
                :attachments="familySubsidyAttachments"
                :color="null"
                :elevation="0"
                :processing="processing"
                :questions="validOtherQuestions"
                :readonly="readonly"
                :schema="schema.definition"
                condensed
                dense
                divided
                expanded
                hide-actions
                tile
              />

              <div
                v-for="question in validVerificationQuestions"
                :key="question.id"
                class="mb-6"
              >
                <FormQuestion
                  :color="null"
                  :elevation="0"
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  condensed
                  dense
                  divided
                  hide-actions
                  tile
                >
                  <AttachmentUploader
                    @uploaded="addToAttachments($event)"
                    :ref="['uploader', question.id].join('')"
                    :owner="{
                      group_id: subsidy.group_id,
                      type: 'FamilySubsidyProgram',
                      id: familySubsidyProgram.id,
                      tag: question.id,
                      tags: [subsidy.id, question.id],
                      description:
                        question.attachment_description ||
                        question.verification_title ||
                        question.verification_subtitle,
                    }"
                    class="mb-4"
                  />

                  <AttachmentList
                    @change="loadAttachments()"
                    @delete="loadAttachments"
                    :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                    :hide-remove="subsidy.locked"
                    class="mb-6"
                  />
                </FormQuestion>
              </div>

              <div
                v-for="(householdMember, householdMemberIndex) in householdMembers"
                :key="householdMember.id"
              >
                <FormQuestion
                  :color="null"
                  :title="`${householdMember.first_name} ${householdMember.last_name}`"
                  condensed
                  dense
                  divided
                  hide-actions
                  tile
                >
                  <HouseholdMemberCard
                    :outlined="false"
                    :value="householdMember"
                    hide-title
                    included-check
                    locked
                  />
                </FormQuestion>

                <QuestionSet
                  v-if="householdMemberQuestionRegister[householdMember.id]"
                  @change="changed = true"
                  :attachment-group-id="subsidy.group_id"
                  :attachment-owner-id="subsidy.id"
                  :attachment-owner-type="'FamilySubsidy'"
                  :attachment-readonly="subsidy.locked"
                  :attachment-tags-supplements="[householdMember.id]"
                  :attachments="familySubsidyAttachments"
                  :color="null"
                  :key-name="householdMemberIndex.toString()"
                  :model-value="householdMember"
                  :processing="processing"
                  :questions="
                    getHouseholdMemberQuestions(householdMember).filter(
                      (question) => question.valid,
                    )
                  "
                  :schema="householdMemberSchema.definition"
                  class="mb-6"
                  condensed
                  dense
                  divided
                  expanded
                  hide-actions
                  readonly
                  tile
                />
              </div>

              <v-btn
                v-show="!subsidy.locked"
                @click="save"
                :disabled="!changed"
                :ripple="false"
                color="primary"
                data-testid="submit-button"
                size="x-large"
                block
              >
                <span v-if="changed">
                  {{ $t('Submit changes') }}
                </span>
                <span v-else>
                  <v-icon class="mx-1">check_box</v-icon>
                  {{ $t('Successfully submitted') }}
                </span>
              </v-btn>
            </v-window-item>

            <v-window-item value="Claims">
              <v-container
                class="px-3 py-0"
                fluid
              >
                <ClaimsTable
                  :family-subsidy="subsidy"
                  :family-subsidy-program="familySubsidyProgram"
                />
              </v-container>
            </v-window-item>
          </v-window>
        </div>
      </v-container>
    </template>
  </div>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import ClaimsTable from '@/parent/components/family_subsidy/ClaimsTable.vue';
import HouseholdMemberCard from '@/shared/components/household-members/HouseholdMemberCard.vue';
import LongDate from '@/shared/components/LongDate.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import useMyFamilySubsidyUtils from '@/shared/composables/useMyFamilySubsidyUtils';
import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useI18n();

const attachments = ref([]);
const changed = ref(false);
const familySubsidyAttachments = ref([]);
const familySubsidyProgram = ref(null);
const householdMemberSchema = ref(getHouseholdMemberSchema());
const householdMembers = ref([]);
const loaded = ref(false);
const processing = ref(false);
const schema = ref();
const subsidy = ref();
const tab = ref();

const {
  householdMemberQuestionRegister,
  loadQuestions,
  validEligibilityQuestions,
  validOtherQuestions,
  validVerificationQuestions,
} = useMyFamilySubsidyUtils({
  familySubsidyProgram,
  householdMembers,
  subsidy,
});

const props = defineProps({
  familySubsidyValue: {
    type: Object,
    default: null,
  },
  familySubsidyProgramValue: {
    type: Object,
    default: null,
  },
  toolbar: {
    type: Boolean,
    default: true,
  },
});

onMounted(performOnMountedHook);

async function performOnMountedHook() {
  await load();

  if (!route.query.tab) router.push({ query: { tab: tabs.value[0] } });
}

const closed = computed(() => {
  if (subsidy.value.locked) return true;

  if (familySubsidyProgram.value?.closes_at) {
    return new Date() >= new Date(familySubsidyProgram.value.closes_at);
  }

  return false;
});

const link = computed(() => {
  return Api.parent.family_subsidy.downloadUrl(
    route.params.id,
    store.state.profile?.default_locale || 'en',
    'Application',
  );
});

const readonly = computed(() => {
  return subsidy.value.projected_eligibility === false || closed.value || subsidy.value.locked;
});

const tabs = computed(() => {
  const tabArray = ['Application'];

  // TODO: Once family subsidy status is being passed forward include "&& subsidy.status === 'Approved'" (BCD-1067)
  if (
    subsidy.value?.submitted_at &&
    familySubsidyProgram.value?.enable_claims &&
    familySubsidyProgram.value?.claim_schema_id
  ) {
    tabArray.push('Claims');
  }

  return tabArray;
});

watch(
  () => route.query.tab,
  (newVal) => {
    if (newVal) {
      tab.value = newVal;
    }
  },
  { immediate: true },
);

function getHouseholdMemberQuestions(householdMember) {
  if (householdMemberQuestionRegister.value.length === 0) return [];

  return householdMemberQuestionRegister.value[householdMember.id];
}

function getHouseholdMemberSchema() {
  return Object.values(store.state.schemas).filter(
    (schema) => schema.data_type === 'HouseholdMember',
  )[0];
}

async function load() {
  if (props.familySubsidyValue) {
    subsidy.value = props.familySubsidyValue;
  } else {
    const resp = await Api.parent.family_subsidy.get(route.params.id);
    subsidy.value = resp.data;
  }

  if (props.familySubsidyProgramValue) {
    familySubsidyProgram.value = props.familySubsidyProgramValue;
  } else {
    await loadFamilySubsidyProgram();
  }

  await loadFamilySubsidySchema();
  if (familySubsidyProgram.value.enable_household_members) await loadHouseholdMembers();

  await loadQuestions();

  await loadAttachments();
  loaded.value = true;
}

async function loadAttachments() {
  processing.value = true;
  const resp = await Api.member.attachment.index();
  processing.value = false;

  if (resp?.status !== 200) return;

  attachments.value = resp.data;
  familySubsidyAttachments.value = resp.data.filter((att) => att.owner_type === 'FamilySubsidy');
}

async function loadFamilySubsidyProgram() {
  const resp = await Api.public_api.organization.family_subsidy_program.get(
    subsidy.value.family_subsidy_program_id,
  );

  familySubsidyProgram.value = resp.data;
  document.title = `Review application - ${familySubsidyProgram.value.name}`;
}

async function loadFamilySubsidySchema() {
  const resp = await Api.public_api.organization.schema.get(
    familySubsidyProgram.value.family_subsidy_schema_id,
  );

  schema.value = resp.data;
}

function addToAttachments(att) {
  eventBus.chime(
    t('Success! The document was received. You do not need to resubmit your application.'),
  );

  attachments.value.push(att);
}

async function loadHouseholdMembers() {
  const response = await Api.parent.household_member.index();

  householdMembers.value = response.data;
}

async function save() {
  processing.value = true;

  const resp = await Api.parent.family_subsidy
    .update(subsidy.value)
    .catch((error) => eventBus.error(error));

  if (!resp?.data) return;

  eventBus.chime('Saved');
  subsidy.value = resp.data;
  changed.value = false;
  processing.value = false;
}
</script>
