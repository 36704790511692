<template>
  <v-dialog
    v-model="userSessionTimeoutIsVisible"
    max-width="600px"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-title class="pb-4">
        {{ $t('Are you still there?') }}
      </v-card-title>

      <v-card-text class="pb-0">
        <p>{{ $t('If you do not respond, your session will be logged out soon.') }}</p>
      </v-card-text>

      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          @click="checkIdentity"
          color="primary"
          size="x-large"
        >
          Stay logged in
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useStore } from 'vuex';

const ONE_MINUTE = 1000 * 60;

const store = useStore();

const props = defineProps({
  millisecondsToSignout: {
    type: Number,
    default: 1000 * 60, // note: duplicating due to linting warnings
  },
});

const displayTimerValue = ref(null);
const displayTimerInterval = ref(null);
const userSessionTimer = ref(null);
const userSessionTimeoutIsVisible = ref(false);

const timerLength = computed(() => {
  return store.state.userSessionTimeout * ONE_MINUTE - ONE_MINUTE; // account for the time it takes for the dialog to appear
});

watch(
  () => store.state.userSessionActionTimestamp,
  () => {
    clearTimers();
    userSessionTimer.value = setTimeout(displaySessionTimeoutDialog, timerLength.value);
  },
);

async function checkIdentity() {
  clearTimers();

  store.dispatch('identify', {
    success() {},
    failure() {
      window.location.reload();
    },
  });
}

async function displaySessionTimeoutDialog() {
  // Before the session timeout dialog appears, check for an identity.
  //
  // If there is no user identity, the user is already logged out and the timeout
  // dialog need not appear. To omit the timeout dialog, the failure() callback will
  // reload the current page, which should be the login page, and omits the timer.
  //
  // If there is a user identity, operation continues as normal. The
  // success() callback is a no-op and the subsequent code starts the
  // interval for the timeout dialog to appear.
  await checkIdentity();

  displayTimerValue.value = props.millisecondsToSignout / 1000;

  displayTimerInterval.value = setInterval(() => {
    displayTimerValue.value -= 1;
  }, 1000);

  userSessionTimeoutIsVisible.value = true;
  userSessionTimer.value = setTimeout(checkIdentity, props.millisecondsToSignout + 3000);
}

function clearTimers() {
  clearInterval(displayTimerInterval.value);
  clearTimeout(userSessionTimer.value);
  userSessionTimeoutIsVisible.value = false;
}
</script>
