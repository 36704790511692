<template>
  <v-menu
    v-model="open"
    :close-on-content-click="closeOnContentClick"
    :left="left"
    :min-width="minWidth"
    offset="5"
    scrim="transparent"
    width="500"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        ref="activator"
        :aria-label="$t(title)"
        :class="buttonClass"
        :color="color"
        :data-testid="testId"
        :data-tid="tid"
        :disabled="disabled"
        :elevation="elevation ? elevation : open ? 2 : 0"
        :ripple="false"
        :variant="outlined ? 'outlined' : undefined"
        rounded
        tracked
      >
        <slot name="icon-prefix" />

        <span class="c-black fs-14 fw-500">
          <slot name="title">
            {{ $t(title) }}
          </slot>
        </span>

        <slot name="icon-suffix">
          <v-icon
            class="c-black fw-600 ms-2"
            icon="keyboard_arrow_down"
          />
        </slot>
      </v-btn>
    </template>

    <v-card
      ref="card"
      :tile="tile"
      class="pa-0"
      border
      flat
    >
      <v-card-text :class="menuCardClass">
        <slot
          :open="open"
          name="card"
        />
      </v-card-text>

      <v-card-actions
        v-if="actions"
        class="pt-0"
      >
        <v-btn
          v-show="!hideClearFilter"
          @click="clear()"
          class="focus-visible"
          variant="text"
        >
          {{ $t('Clear filter') }}
        </v-btn>

        <v-spacer />

        <v-btn
          @click="save()"
          :disabled="saveDisabled"
          class="focus-very-visible"
          color="primary"
          data-cy="apply-filter"
          variant="flat"
        >
          {{ $t(saveText || 'Apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    actions: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    activeClasses: {
      type: Array,
      default: null,
    },
    borderless: {
      default: false,
      type: Boolean,
    },
    classes: {
      type: String,
      default: null,
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    color: {
      default: '#EEF0F1',
      type: String,
    },
    defaultButtonClass: {
      type: String,
      default: 'me-2',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: String,
      default: '0',
    },
    hideClearFilter: {
      type: Boolean,
      default: false,
    },
    inactiveClasses: {
      type: Array,
      default: null,
    },
    left: Boolean,
    minWidth: {
      type: String,
      default: null,
    },
    outlined: {
      default: true,
      type: Boolean,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: null,
    },
    tid: {
      type: String,
      default: null,
    },
    testId: {
      type: String,
      default: null,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  emits: ['clear', 'close', 'open', 'save'],

  data() {
    return { open: false };
  },

  computed: {
    buttonClass() {
      let classes = [this.defaultButtonClass];

      if (!this.borderless) {
        if (this.active) {
          if (this.activeClasses) {
            classes = classes.concat(this.activeClasses);
          } else {
            classes = classes.concat(['bc-primary-light', 'bg-primary-extra-light']);
          }
        } else if (this.inactiveClasses) {
          classes = classes.concat(this.inactiveClasses);
        } else {
          classes = classes.concat(['bc-very-light-gray']);
        }
      }

      if (this.classes) classes = classes.concat([this.classes]);

      return classes.join(' ');
    },

    menuCardClass() {
      if (this.paddingless) return 'py-0 px-0';

      return 'py-3 px-3';
    },
  },

  watch: {
    open(newVal) {
      if (newVal) {
        this.$emit('open');
        // TODO: children access in vue 3 is more difficult,
        // consider using autofocus attributes or DOM methods instead
        // setTimeout(() => {
        //   this.$refs.card.$children.find((el) => {
        //     if (el.$refs.input) {
        //       el.$refs.input.focus();
        //       return true;
        //     } if (el.items && el.items[0] && el.items[0].$refs && el.items[0].$refs.input) {
        //       el.items[0].$refs.input.focus();
        //       return true;
        //     }
        //     return false;
        //   });
        // }, 100);
      } else {
        this.$nextTick(() => {
          this.$refs.activator.$el.focus();
        });
        this.$emit('close');
      }
    },
  },

  methods: {
    clear() {
      this.$emit('clear');
      this.open = false;
    },

    close() {
      this.open = false;
    },

    save() {
      this.open = false;
      this.$emit('save');
    },
  },
};
</script>
