<template>
  <v-container
    v-if="provider"
    class="px-3 py-0"
    fluid
  >
    <div class="mxw-1200 mx-auto">
      <v-container
        class="px-4 px-md-0"
        fluid
      >
        <v-row dense>
          <v-col
            class="py-2"
            cols="12"
          >
            <h1 class="fs-24 fw-800 c-black mt-2 me-3">
              {{ $t(provider.name) }}
            </h1>
            <v-chip
              v-if="provider.closed_status != 'Open'"
              class="va-text-bottom"
              color="red"
              prepend-icon="warning"
            >
              <span class="fw-600">{{ $t(provider.closed_status) }}</span>
            </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-inline-block me-6">
              <v-icon
                class="d-inline-block me-2 va-text-bottom"
                color="secondary"
                icon="location_on"
                size="24"
              />
              <span class="fs-16 fw-500-lg c-black">
                {{ providerAddress }}
              </span>
            </div>

            <div
              v-if="provider.license_type"
              class="d-inline-block me-6"
            >
              <v-icon
                class="d-inline-block me-2 va-text-bottom"
                color="secondary"
                icon="store_mall_directory"
                size="24"
              />
              <span class="fs-16 fw-500-lg c-black">
                {{ $t(provider.license_type) }}
              </span>
            </div>

            <div
              v-if="provider.quality_rating_score > 1"
              class="d-inline-block"
            >
              <v-icon
                class="d-inline-block me-2 va-text-bottom"
                color="secondary"
                icon="stars"
                size="24"
              />

              <span class="fs-16 fw-500-lg me-2"> {{ $t($store.state.site.ratings.name) }}: </span>

              <v-rating
                v-if="provider.quality_rating_score"
                v-model="provider.quality_rating_score"
                :length="$store.state.site.ratings.max"
                class="d-inline-block me-2 va-middle"
                color="#eecb54"
                density="compact"
                size="18"
                readonly
              >
                <template
                  v-if="$store.state.site.ratings.icons.length > 0"
                  #item="props"
                >
                  <CustomRating
                    :index="props.index"
                    :passed="props.isFilled"
                    :score="provider.quality_rating_score"
                  />
                </template>
              </v-rating>

              <span class="c-light-black fs-16 fw-500-lg me-1">
                ({{ provider.quality_rating_score }} {{ $t('out of') }}
                {{ $store.state.site.ratings.max }})
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row class="flex-row">
          <v-col
            v-if="providerSchemaMeta.profile.enable_contact"
            cols="12"
            md="4"
          >
            <VirtualTour
              v-if="videos.length > 0"
              :videos="videos"
              class="mb-4"
            />

            <ContactInformation
              v-if="provider"
              :provider="provider"
              class="mb-3"
            />

            <template v-if="store.state.profile?.id">
              <template v-if="canMessage">
                <v-btn
                  v-if="!existingMessage"
                  @click="$refs.messageProviderDialog.open()"
                  :disabled="$role != 'parent'"
                  class="c-white me-1 my-1 fs-16"
                  color="primary"
                  data-cy="public_view_message"
                  elevation="0"
                  prepend-icon="message"
                  block
                  tracked
                >
                  {{ $t('Message') }}
                </v-btn>

                <v-btn
                  v-else
                  @click="maybeGoToMessaging()"
                  id="draft_message_button"
                  class="bg-primary-light c-white my-1 fs-16"
                  elevation="0"
                  prepend-icon="check_circle"
                  block
                  tracked
                >
                  {{ $t('Message sent') }}
                </v-btn>
              </template>

              <template v-if="provider.tours_enabled && provider.tour_time">
                <v-btn
                  v-if="!existingTour"
                  @click="$refs.bookTourDialog.open()"
                  id="book_tour_button"
                  :disabled="$role != 'parent'"
                  class="c-white my-1 fs-16"
                  color="primary"
                  elevation="0"
                  block
                  tracked
                >
                  {{ $t('Request a tour') }}
                </v-btn>

                <v-btn
                  v-else
                  id="book_tour_button"
                  class="bg-primary-light c-white my-1 fs-16"
                  elevation="0"
                  prepend-icon="watch_later"
                  block
                  tracked
                >
                  {{ $t('Tour requested') }}
                </v-btn>
              </template>
            </template>

            <MessageProviderDialog
              v-if="$role === 'parent'"
              @messaged="existingMessage = $event"
              ref="messageProviderDialog"
              :provider="provider"
            />

            <BookTourDialog
              v-if="$role === 'parent'"
              @booked="existingTour = $event"
              ref="bookTourDialog"
              :provider="provider"
            />
          </v-col>

          <v-col
            :md="providerSchemaMeta.profile.enable_contact ? 8 : 12"
            cols="12"
          >
            <ProviderImages
              :provider_id="provider.id"
              class="mb-4"
            />

            <EmergencyResponse
              v-if="providerSchemaMeta.profile.enable_emergency_response"
              :provider="provider"
              class="mb-4"
            />

            <PublicNotice
              v-if="provider.public_notice"
              :public-notice="provider.public_notice"
            />

            <AboutThisClassroom
              v-if="providerSchemaMeta.profile.enable_description"
              :provider="provider"
            />

            <ProviderHours
              v-if="providerSchemaMeta.profile.enable_hours && provider.closed_status === 'Open'"
              :provider="provider"
            />

            <v-card
              v-if="enrollmentSteps.length > 0"
              class="mb-4"
              border
              tile
            >
              <v-card-title>
                <h2 class="fs-20 fw-800">
                  {{ $t(`How to ${terms.enroll.toLowerCase()}`) }}
                </h2>
              </v-card-title>

              <v-card-text>
                <EnrollmentSteps :steps="enrollmentSteps" />
              </v-card-text>
            </v-card>

            <v-card
              v-if="showProviderPrograms"
              class="mb-4"
              border
              tile
            >
              <v-card-text>
                <ProviderPrograms
                  :provider="provider"
                  :rows="2"
                  hide-top-border
                />
              </v-card-text>
            </v-card>

            <PaymentDetails
              v-if="providerSchemaMeta.profile.enable_payment_details"
              :provider="provider"
            />

            <ProviderHighlights
              v-if="providerSchemaMeta.profile.enable_highlights"
              :provider="provider"
            />

            <ProviderLicensing
              v-if="providerSchemaMeta.profile.enable_licensing"
              :provider="provider"
              :title="providerSchemaMeta.profile.licensing_title"
            />

            <v-row v-if="provider.count_of_staff_members > 0">
              <v-col>
                <v-card
                  class="mb-4"
                  border
                  tile
                >
                  <v-card-title class="fs-20 fw-800">
                    {{ $t('Meet our staff!') }}
                  </v-card-title>

                  <v-card-text class="py-3 px-5">
                    <StaffMembers
                      v-if="provider"
                      :provider="provider"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script setup>
import AboutThisClassroom from '@/public/components/provider/AboutThisClassroom.vue';
import Api from '@/shared/services/bright_finder';
import BookTourDialog from '@/shared/components/BookTourDialog.vue';
import ContactInformation from '@/public/components/provider/ContactInformation.vue';
import CustomRating from '@/shared/components/CustomRating.vue';
import EmergencyResponse from '@/public/components/provider/EmergencyResponse.vue';
import EnrollmentSteps from '@/shared/components/search/EnrollmentSteps.vue';
import MessageProviderDialog from '@/shared/components/MessageProviderDialog.vue';
import PaymentDetails from '@/public/components/provider/PaymentDetails.vue';
import ProviderHighlights from '@/public/components/provider/ProviderHighlights.vue';
import ProviderHours from '@/public/components/provider/ProviderHours.vue';
import ProviderImages from '@/public/components/provider/ProviderImages.vue';
import ProviderLicensing from '@/public/components/provider/ProviderLicensing.vue';
import ProviderPrograms from '@/shared/components/provider/ProviderPrograms.vue';
import PublicNotice from '@/shared/components/PublicNotice.vue';
import StaffMembers from '@/public/components/provider/StaffMembers.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useTerms from '@/shared/composables/useTerms';
import VirtualTour from '@/public/components/provider/VirtualTour.vue';
import { formatAddress } from '@/shared/services/address';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { terms } = useTerms();

const emit = defineEmits(['providerUpdate']);

const props = defineProps({
  providerId: {
    type: String,
    default: null,
  },
});

const bookTourDialog = ref(null);
const contact = ref(null);
const enrollmentSteps = ref([]);
const existingMessage = ref(null);
const existingTour = ref(null);
const messageProviderDialog = ref(null);
const provider = ref(null);
const videos = ref([]);

const canMessage = computed(() => {
  if (store.state.profile?.contact && contact.value) {
    return (
      provider.value.inquiries_enabled &&
      store.state.profile.contact.provider_inquiries &&
      store.state.site.features.enable_messaging &&
      contact.value.provider_inquiries
    );
  }

  return false;
});

const providerSchemaMeta = computed(() => {
  return store.state.schemas[provider.value.schema_id].meta;
});

const showProviderPrograms = computed(() => {
  return (
    providerSchemaMeta.value.profile.enable_programs &&
    provider.value.count_of_programs > 0 &&
    provider.value.closed_status === 'Open'
  );
});

const providerAddress = computed(() => {
  if (!provider.value) return null;

  let formattedAddress = formatAddress(
    provider.value.address1,
    provider.value.city,
    provider.value.state,
  );
  if (provider.value.zip) formattedAddress += ` ${provider.value.zip}`;

  return formattedAddress;
});

watch(
  () => route.params?.providerId || props.providerId,
  (newValue) => {
    provider.value = null;
    contact.value = null;
    existingMessage.value = null;
    existingTour.value = null;
    enrollmentSteps.value = [];

    if (newValue) load();
  },
  { deep: true, immediate: true },
);

onMounted(() => {
  store.dispatch('identify');
});

function getProvider(id) {
  Api.public_api.provider.get(id, (response) => {
    provider.value = response.data;

    if (props.providerId) emit('providerUpdate', provider.value);
    loadContact();
    void loadEnrollmentSteps();
    void loadVirtualTour();
  });
}

function load() {
  if (props.providerId || route.params.providerId) {
    getProvider(props.providerId || route.params.providerId);
  } else {
    Api.public_api.organization.slug.get(
      window.organization_id,
      btoa(window.location.href),
      (response) => {
        getProvider(response.data.provider_id);
      },
      (error) => {
        eventBus.chime(error.response.data.errors[0]);
        window.location = '/not_found';
      },
    );
  }
}

function loadContact() {
  if (provider.value.email) {
    Api.contact.get(provider.value.email, (response) => {
      contact.value = response.data;
      if (route.query.action === 'message') {
        messageProviderDialog.value?.open(provider.value);
      } else if (route.query.action === 'tour') {
        bookTourDialog.value.open(provider.value);
      }
    });
  } else {
    contact.value = null;
  }
}

async function loadEnrollmentSteps() {
  const response = await Api.public_api.provider.enrollment_steps.index(provider.value.id);
  enrollmentSteps.value = response.data;
}

async function loadVirtualTour() {
  const response = await Api.public_api.provider.asset.index(provider.value.id, {
    category: 'video',
  });
  videos.value = response.data;
}

function maybeGoToMessaging() {
  if (!store.state.pages.Messaging.enabled) return;

  router.push({
    name: 'MessageIndex',
    query: {
      provider_id: provider.value.id,
    },
  });
}
</script>

<style>
a span:hover {
  text-decoration: underline !important;
}
</style>
