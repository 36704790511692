<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        v-if="label"
        class="labeled-input"
        cols="12"
      >
        <label :for="inputId">
          {{ $t(label) }}
          <RequiredIndicator v-if="mandatory" />
        </label>
      </v-col>

      <v-col
        v-else-if="title || subtitle"
        cols="12"
      >
        <div
          :class="[titleClasses, bold ? 'fs-16 fw-600' : 'fs-16 fw-500']"
          class="c-black"
        >
          {{ $t(title) }}
          <RequiredIndicator v-if="mandatory" />
        </div>

        <div
          v-if="subtitle"
          class="fs-14 c-light-black mt-1 text-pre-wrap"
        >
          {{ $t(subtitle) }}
        </div>
      </v-col>

      <v-col>
        <v-select
          @blur="handleBlur"
          @update:model-value="handleUpdateModelValue"
          :id="inputId"
          :append-inner-icon="appendIcon"
          :aria-label="$t(ariaLabel || '')"
          :chips="chips"
          :closable-chips="deletableChips"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-props="itemProps"
          :item-title="itemTitle"
          :item-value="itemValue"
          :items="items"
          :menu-props="{ 'data-testid': menuTestId }"
          :model-value="localValue"
          data-cy="labeled-select"
          variant="filled"
          clearable
          hide-details
          tile
          v-bind="attrsOmitDataPrefix"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import Schematized from '@/shared/mixins/schematized';
import _ from 'lodash';
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';

export default {
  compatConfig: { MODE: 3 },

  components: {
    RequiredIndicator,
  },

  mixins: [Labeled, Schematized, FilteredAttrs],

  props: {
    chips: Boolean,
    deletableChips: Boolean,
    id: String,
    items: {
      type: Array,
      default: () => [],
    },
    itemProps: Boolean,
    menuTestId: String,
    itemTitle: String,
    itemValue: String,
    mandatory: Boolean,
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleClasses: {
      type: [String, Array, Object],
      default: undefined,
    },
  },

  data() {
    return {
      inputId: this.id || _.uniqueId('labeled-select-'),
    };
  },
};
</script>
