<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-title>
      {{ $t('Danger') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div class="fs-16 fw-500 mb-4">
        {{ $t('Be careful! Some of these actions cannot be undone.') }}
      </div>

      <div>
        <v-btn
          @click="emit('destroy')"
          :loading="processing"
          color="red"
          prepend-icon="delete"
        >
          {{ $t('Delete') }}
        </v-btn>

        <v-btn
          v-if="onDuplicate"
          @click="emit('duplicate')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="content_copy"
        >
          {{ $t('Duplicate') }}
        </v-btn>

        <v-btn
          v-if="publishable && !published"
          @click="emit('publish')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="publish"
        >
          {{ $t('Publish') }}
        </v-btn>

        <v-btn
          v-show="archivable && !archived"
          @click="confirmAndEmit('archive')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="archive"
        >
          {{ $t('Archive') }}
        </v-btn>

        <v-btn
          v-show="archivable && archived"
          @click="confirmAndEmit('unarchive')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="archive"
        >
          {{ $t('Unarchive') }}
        </v-btn>

        <v-btn
          v-if="publishable && published"
          @click="emit('unpublish')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="move_to_inbox"
          variant="outlined"
        >
          {{ $t('Unpublish') }}
        </v-btn>

        <v-btn
          v-if="runnable"
          @click="emit('run')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="run"
        >
          {{ $t('Run') }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>

  <ConfirmDialog ref="confirmDialog" />
</template>

<script setup>
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';

const props = defineProps({
  onDuplicate: [Array, Function],
  otherActions: {
    default: () => [],
    type: Array,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  archivable: {
    type: Boolean,
    default: false,
  },
  archived: {
    type: Boolean,
    default: false,
  },
  publishable: {
    type: Boolean,
    default: false,
  },
  published: {
    type: Boolean,
    default: false,
  },
  resourceName: {
    type: String,
    default: 'resource',
  },
  runnable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'archive',
  'destroy',
  'duplicate',
  'publish',
  'run',
  'unarchive',
  'unpublish',
]);

const confirmDialog = ref(null);

async function confirmAndEmit(action) {
  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to ${action} ${props.resourceName}?`,
  );
  if (!confirm) return;

  emit(action);
}
</script>
