<template>
  <div>
    <slot
      :child="child"
      :previous-child="previousChild"
      :previous-child-name="previousChildName"
      :previous-subsidy="previousSubsidy"
      :subsidy="subsidy"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  child: {
    type: Object,
    default: () => ({}),
  },
  previousChild: {
    type: Object,
    default: null,
  },
  subsidies: {
    type: Array,
    default: () => [],
  },
});

const previousChildName = computed(() => {
  return props.previousChild?.name;
});

const previousSubsidy = computed(() => {
  return props.previousChild
    ? props.subsidies.find((subsidy) => subsidy.child_id === props.previousChild.id)
    : null;
});

const subsidy = computed(() => {
  return props.subsidies.find((subsidy) => subsidy.child_id === props.child.id);
});
</script>
