<template>
  <div class="d-inline-flex">
    <div class="d-none d-lg-inline-flex">
      <template v-if="meta.openings.enabled && (meta.openings.public || $role == 'specialist')">
        <FilterMenu
          @clear="modelValue.vacancy = null"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.vacancy != null"
          tid="search_availability_filter"
          title="Availability"
        >
          <template #card>
            <v-checkbox
              v-model="modelValue.vacancy"
              class="mt-0 mb-0"
              color="primary"
              false-icon="check_box_outline_blank"
              true-icon="check_box"
              hide-details
            >
              <template #label>
                <span
                  class="c-black"
                  data-cy="availability-checkmark"
                >
                  {{ t('Only show providers that have reported availability') }}
                </span>
              </template>
            </v-checkbox>
          </template>
        </FilterMenu>
      </template>

      <FilterMenu
        @clear="modelValue.distance = null"
        @close="emitChange()"
        :actions="true"
        :active="modelValue.distance != null"
        :title="schemaProperties?.distance?.alias || 'Distance'"
        aria-label="menuitem"
        min-width="375px"
        tid="search_distance_filter"
      >
        <template #card>
          <v-row>
            <v-col
              class="fw-600"
              cols="12"
            >
              {{ t('Only show me options within a specific distance') }}
            </v-col>
            <v-col>
              <v-select
                v-model="modelValue.distance"
                :items="mileageRange()"
                :label="t('Distance in miles')"
                class="c-black mt-0"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
        </template>
      </FilterMenu>

      <template v-if="schemaProperties.quality_rating_score.filterable">
        <FilterMenu
          @clear="modelValue.minimum_quality_rating = null"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.minimum_quality_rating != null"
          :title="schemaProperties.quality_rating_score.alias"
          min-width="250px"
          tid="search_quality_filter"
        >
          <template #card>
            <div v-if="store.state.site.ratings.description">
              <div class="c-light-black fs-15 pt-2 pb-1">
                <span class="c-light-black fs-15">
                  {{ t(store.state.site.ratings.description) }}
                </span>
                <template v-if="store.state.site.ratings.link">
                  <a
                    :href="store.state.site.ratings.link"
                    class="c-primary fs-15 ms-1 underlined"
                    target="_blank"
                  >
                    {{ t('Learn more') }}
                  </a>
                </template>
              </div>
              <v-divider class="my-3" />
            </div>
            <v-radio-group
              v-model="modelValue.minimum_quality_rating"
              class="mt-0"
              hide-details
            >
              <div
                v-for="(level, index) in store.state.site.ratings.text.slice().reverse()"
                :key="index"
              >
                <RadioWithContext
                  :index="index"
                  :item="level"
                  :suffix="
                    store.state.site.ratings.text.length - index <
                    store.state.site.ratings.text.length
                      ? 'or higher'
                      : null
                  "
                  :value="store.state.site.ratings.max - index"
                />
                <v-divider
                  v-if="level.subtitle"
                  class="my-3"
                />
              </div>
            </v-radio-group>
          </template>
        </FilterMenu>
      </template>

      <template v-if="schemaProperties.county.filterable">
        <FilterMenu
          @clear="modelValue.county = []"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.county.length > 0"
          :title="schemaProperties.county.alias || 'County'"
          aria-label="menuitem"
          min-width="375px"
          tid="search_county_filter"
        >
          <template #card>
            <v-select
              v-model="modelValue.county"
              :items="schemaProperties.county.enum"
              :label="t('County')"
              class="c-black mt-0"
              color="primary"
              hide-details
              multiple
            />
          </template>
        </FilterMenu>
      </template>

      <template v-if="schemaProperties.program_types.filterable">
        <FilterMenu
          @clear="modelValue.program_types = []"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.program_types.length > 0"
          :title="schemaProperties.program_types.alias || 'Program types'"
          aria-label="menuitem"
          min-width="375px"
          tid="search_program_types_filter"
          paddingless
        >
          <template #card>
            <v-list
              v-model:selected="modelValue.program_types"
              :item-title="(item) => capitalize(t(item))"
              :item-value="(item) => item"
              :items="schemaProperties.program_types.items.enum"
              select-strategy="independent"
            >
              <template #prepend="{ isSelected }">
                <v-checkbox-btn
                  :model-value="isSelected"
                  false-icon="check_box_outline_blank"
                  tabindex="-1"
                  true-icon="check_box"
                />
              </template>
            </v-list>
          </template>
        </FilterMenu>
      </template>

      <template v-if="schemaProperties.program_season.filterable">
        <FilterMenu
          @clear="modelValue.program_season = null"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.program_season != null"
          :title="schemaProperties.program_season.alias || 'Season'"
          aria-label="menuitem"
          min-width="375px"
          tid="search_program_season_filter"
        >
          <template #card>
            <v-radio-group
              v-model="modelValue.program_season"
              class="mt-0"
              hide-details
            >
              <v-radio
                v-for="type in schemaProperties.program_season.enum"
                :key="type"
                :label="capitalize(t(type))"
                :value="type"
                class="c-black mt-0"
                color="primary"
                hide-details
              />
            </v-radio-group>
          </template>
        </FilterMenu>
      </template>

      <template v-if="schemaProperties.license_type.filterable">
        <FilterMenu
          @clear="modelValue.license_type = []"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.license_type.length > 0"
          :title="schemaProperties.license_type.alias"
          aria-label="menuitem"
          min-width="375px"
          tid="search_license_types_filter"
          paddingless
        >
          <template #card>
            <v-list
              v-model:selected="modelValue.license_type"
              :item-title="(item) => capitalize(t(item))"
              :item-value="(item) => item"
              :items="schemaProperties.license_type.enum"
              select-strategy="independent"
            >
              <template #prepend="{ isSelected }">
                <v-checkbox-btn
                  :model-value="isSelected"
                  false-icon="check_box_outline_blank"
                  tabindex="-1"
                  true-icon="check_box"
                />
              </template>
            </v-list>
          </template>
        </FilterMenu>
      </template>

      <template v-if="schemaProperties.categories.filterable">
        <FilterMenu
          @clear="modelValue.categories = []"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.categories.length > 0"
          :title="schemaProperties.categories.alias"
          aria-label="menuitem"
          min-width="375px"
          tid="search_categories_filter"
          paddingless
        >
          <template #card>
            <template v-if="schemaProperties.categories.items?.enum?.length < 20">
              <v-list
                v-model:selected="modelValue.categories"
                :item-title="(item) => capitalize(t(item))"
                :item-value="(item) => item"
                :items="schemaProperties.categories.items.enum"
                select-strategy="independent"
              >
                <template #prepend="{ isSelected }">
                  <v-checkbox-btn
                    :model-value="isSelected"
                    false-icon="check_box_outline_blank"
                    tabindex="-1"
                    true-icon="check_box"
                  />
                </template>
              </v-list>
            </template>
            <div
              v-else
              class="pa-3"
            >
              <v-autocomplete
                v-model="modelValue.categories"
                :items="schemaProperties.categories.items.enum || []"
                :label="t('Select all that apply')"
                variant="filled"
                chips
                deleteable-chips
                hide-details
                multiple
              />
            </div>
          </template>
        </FilterMenu>
      </template>

      <template v-if="schemaProperties.hours.filterable">
        <FilterMenu
          @clear="clearHours()"
          @close="emitChange()"
          :actions="true"
          :active="modelValue.week_days.length > 0"
          :save-disabled="modelValue.week_days.length == 0"
          aria-label="menuitem"
          min-width="400"
          tid="search_hours_filter"
          title="Hours"
        >
          <template #card>
            <v-row>
              <LabeledOpenClose
                v-model="hoursRange"
                close-title="Pickup time"
                open-title="Drop-off time"
              />
            </v-row>
            <div class="c-gray fs-15 mt-2 mb-3 mxw-400">
              {{
                t(
                  "We'll show you results that offer drop-off at the time requested or earlier, and pick-up at the time requested or later",
                )
              }}
            </div>
            <v-row dense>
              <v-col
                class="fw-600"
                cols="12"
              >
                {{ t('Days of the week') }}
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Monday')"
                  class="mt-0"
                  density="compact"
                  value="0"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Tuesday')"
                  class="mt-2"
                  density="compact"
                  value="1"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Wednesday')"
                  class="mt-2"
                  density="compact"
                  value="2"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Thursday')"
                  class="mt-2"
                  density="compact"
                  value="3"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Friday')"
                  class="mt-2"
                  density="compact"
                  value="4"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Saturday')"
                  class="mt-2"
                  density="compact"
                  value="5"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Sunday')"
                  class="mt-2"
                  density="compact"
                  value="6"
                  hide-details
                />
              </v-col>
            </v-row>
          </template>
        </FilterMenu>
      </template>

      <template v-if="store.state.pages.Search.features.enable_more_filters">
        <v-btn
          @click="openMoreFiltersDialog()"
          :class="moreFiltersButtonClass"
          class="focus-visible"
          data-tid="search_more_filter"
          variant="outlined"
          rounded
        >
          <span
            class="bc-body c-black d-none d-md-flex fw-500 fs-14"
            data-cy="more-filters"
          >
            {{ t('More filters') }}
          </span>
          <v-icon class="d-flex d-md-none fw-500"> more_horiz </v-icon>
        </v-btn>
      </template>
    </div>

    <v-btn
      @click="allFiltersDialogIsVisible = true"
      class="bg-white me-2 bc-very-light-gray d-inline-block d-lg-none"
      data-testid="search_all_filter"
      data-tid="search_all_filter"
      variant="outlined"
      rounded
    >
      <v-icon class="me-1 fs-20 c-primary fw-500"> filter_list </v-icon>
      <span class="bc-body c-black fw-600">
        {{ t('Filters') }}
      </span>
    </v-btn>

    <v-dialog
      v-model="moreFiltersDialogIsVisible"
      width="600px"
      scrollable
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title class="mb-n-3 pb-0">
          {{ t('More filters') }}
        </v-card-title>
        <v-card-text class="pt-0 pb-6">
          <SearchMoreFilters :value="modelValue" />
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-2">
          <v-btn
            @click="clearMoreFilters()"
            color="primary"
            data-cy="clear-filters"
            variant="text"
          >
            {{ t('Clear filters') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="emitChange()"
            color="primary"
            data-cy="apply-filter"
          >
            {{ t('Apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="allFiltersDialogIsVisible"
      fullscreen
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-row class="d-flex align-center">
            <v-col class="d-flex justify-start">
              <span>{{ t('Filters') }}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="allFiltersDialogIsVisible = false"
                :aria-label="t('Close filter')"
                variant="text"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider class="mb-6" />

        <v-card-text class="pt-0 pb-6">
          <template v-if="meta.openings.enabled && (meta.openings.public || $role == 'specialist')">
            <v-row dense>
              <v-col class="c-black fs-16 fw-600">
                {{ t('Availability') }}
              </v-col>
            </v-row>
            <v-row
              class="mb-4"
              dense
            >
              <v-col>
                <v-checkbox
                  v-model="modelValue.vacancy"
                  class="mt-0 mb-0"
                  color="primary"
                  false-icon="check_box_outline_blank"
                  true-icon="check_box"
                  hide-details
                >
                  <template #label>
                    <span
                      class="c-black"
                      data-cy="availability-checkmark"
                    >
                      {{ t('Only show providers that have reported availability') }}
                    </span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-6" />
          </template>

          <template v-if="schemaProperties.hours.filterable">
            <v-row>
              <LabeledOpenClose
                v-model="hoursRange"
                close-title="Pickup time"
                open-title="Drop-off time"
              />
            </v-row>
            <div class="c-gray fs-15 mt-2 mb-3 mxw-400">
              {{
                t(
                  "We'll show you results that offer drop-off at the time requested or earlier, and pick-up at the time requested or later",
                )
              }}
            </div>
            <v-row dense>
              <v-col
                class="fw-600"
                cols="12"
              >
                {{ t('Days of the week') }}
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Monday')"
                  class="mt-0"
                  density="compact"
                  value="0"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Tuesday')"
                  class="mt-2"
                  density="compact"
                  value="1"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Wednesday')"
                  class="mt-2"
                  density="compact"
                  value="2"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Thursday')"
                  class="mt-2"
                  density="compact"
                  value="3"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Friday')"
                  class="mt-2"
                  density="compact"
                  value="4"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Saturday')"
                  class="mt-2"
                  density="compact"
                  value="5"
                  hide-details
                />
                <v-checkbox
                  v-model="modelValue.week_days"
                  :label="t('Sunday')"
                  class="mt-2"
                  density="compact"
                  value="6"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider class="my-6" />
          </template>

          <template v-if="schemaProperties.program_types.filterable">
            <v-row dense>
              <v-col class="c-black fs-16 fw-600">
                {{ t(schemaProperties.program_types.alias || 'Program types') }}
              </v-col>
            </v-row>
            <v-row
              class="mb-4"
              dense
            >
              <v-col>
                <v-checkbox
                  v-for="type in schemaProperties.program_types.items.enum"
                  v-model="modelValue.program_types"
                  :key="type"
                  :label="capitalize(t(type))"
                  :value="type"
                  class="c-black mt-0"
                  color="primary"
                  hide-details
                  multiple
                />
              </v-col>
            </v-row>
            <v-divider class="my-6" />
          </template>

          <template v-if="schemaProperties.categories.filterable">
            <v-row dense>
              <v-col class="c-black fs-16 fw-600">
                {{ t(schemaProperties.categories.alias || 'Categories') }}
              </v-col>
            </v-row>

            <v-row
              class="mb-4"
              dense
            >
              <v-col>
                <template v-if="schemaProperties.categories.items.enum.length < 20">
                  <v-checkbox
                    v-for="type in schemaProperties.categories.items.enum"
                    v-model="modelValue.categories"
                    :key="type"
                    :label="capitalize(t(type))"
                    :value="type"
                    class="c-black mt-0"
                    color="primary"
                    hide-details
                    multiple
                  />
                </template>
                <template v-else>
                  <v-autocomplete
                    v-model="modelValue.categories"
                    :items="schemaProperties.categories.items.enum"
                    :label="t('Select all that apply')"
                    variant="filled"
                    chips
                    deleteable-chips
                    hide-details
                    multiple
                  />
                </template>
              </v-col>
            </v-row>

            <v-divider class="my-6" />
          </template>

          <template v-if="schemaProperties.program_season.filterable">
            <v-row dense>
              <v-col class="c-black fs-16 fw-600">
                {{ t(schemaProperties.program_season.alias || 'Season') }}
              </v-col>
            </v-row>
            <v-row
              class="mb-4"
              dense
            >
              <v-col>
                <v-radio-group
                  v-model="modelValue.program_season"
                  class="mt-0"
                  hide-details
                >
                  <v-radio
                    v-for="type in schemaProperties.program_season.enum"
                    :key="type"
                    :label="capitalize(t(type))"
                    :value="type"
                    class="c-black mt-0"
                    color="primary"
                    hide-details
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-divider class="my-6" />
          </template>

          <template v-if="schemaProperties.license_type.filterable">
            <v-row dense>
              <v-col class="c-black fs-16 fw-600">
                {{ t(schemaProperties.license_type.alias || 'License') }}
              </v-col>
            </v-row>
            <v-row
              class="mb-4"
              dense
            >
              <v-col>
                <v-checkbox
                  v-for="type in schemaProperties.license_type.enum"
                  v-model="modelValue.license_type"
                  :key="type"
                  :label="capitalize(t(type))"
                  :value="type"
                  class="c-black mt-0"
                  color="primary"
                  hide-details
                  multiple
                />
              </v-col>
            </v-row>
            <v-divider class="my-6" />
          </template>

          <div v-if="schemaProperties.quality_rating_score.filterable">
            <v-row dense>
              <v-col class="c-black fs-16 fw-600">
                {{ schemaProperties.quality_rating_score.alias }}
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <div v-if="store.state.site.ratings.Description">
                  <div class="c-light-black fs-15 pt-2 pb-4">
                    <span class="c-light-black fs-15">
                      {{ t(store.state.site.ratings.Description) }}
                    </span>
                    <template v-if="store.state.site.ratings.Link">
                      <a
                        :href="store.state.site.ratings.Link"
                        class="c-primary fs-15 ms-1 underlined"
                        target="_blank"
                      >
                        {{ t('Learn more') }}
                      </a>
                    </template>
                  </div>
                </div>
                <v-radio-group
                  v-model="modelValue.minimum_quality_rating"
                  class="mt-0 mb-2"
                  hide-details
                >
                  <div
                    v-for="(level, index) in store.state.site.ratings.text.slice().reverse()"
                    :key="index"
                  >
                    <RadioWithContext
                      :index="index"
                      :item="level"
                      :suffix="
                        store.state.site.ratings.text.length - index <
                        store.state.site.ratings.text.length
                          ? 'or higher'
                          : null
                      "
                      :value="store.state.site.ratings.max - index"
                    />
                    <template v-if="level.subtitle">
                      <v-divider
                        v-show="index + 1 < store.state.site.ratings.text.length"
                        class="my-3"
                      />
                    </template>
                  </div>
                </v-radio-group>
                <v-btn
                  v-show="modelValue.minimum_quality_rating"
                  @click="modelValue.minimum_quality_rating = null"
                  color="primary"
                  size="small"
                >
                  {{ t('Clear filter') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-6" />
          </div>

          <v-row>
            <v-col>
              <SearchMoreFilters :value="modelValue" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="my-3" />

        <v-card-actions>
          <v-btn
            @click="clearAllFilters()"
            variant="text"
          >
            {{ t('Clear filters') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="emitChange()"
            color="primary"
          >
            {{ t('Apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import RadioWithContext from '@/shared/components/form/RadioWithContext.vue';
import SearchMoreFilters from '@/shared/components/search/more_filters.vue';
import _ from 'lodash';
import { capitalize } from '@/plugins/filters';

const store = useStore();
const { t } = useI18n();
const modelValue = defineModel({ type: Object });

const emit = defineEmits(['change', 'redraw']);

const allFiltersDialogIsVisible = ref(false);
const moreFiltersDialogIsVisible = ref(false);
const hoursRange = ref(
  modelValue.value.dropoff && modelValue.value.pickup
    ? [modelValue.value.dropoff, modelValue.value.pickup]
    : [900, 1600],
);
const meta = store.state.schemas[modelValue.value.schema_id].meta;

const moreFiltersActive = computed(() => {
  return (
    Object.values(modelValue.value.highlights).some((item) => item.length > 0) ||
    Object.values(modelValue.value.custom).some((item) => item && item.length > 0) ||
    modelValue.value.payment_subsidies_accepted.length > 0 ||
    !!modelValue.value.subsidy
  );
});

const moreFiltersButtonClass = computed(() => {
  let c = ['me-2'];
  if (moreFiltersActive.value) {
    c = c.concat(['bc-primary-light', 'bg-primary-extra-light']);
  } else {
    c = c.concat(['bg-white', 'bc-very-light-gray']);
  }
  return c.join(' ');
});

const schemaProperties = computed(() => {
  return store.state.schemas[modelValue.value.schema_id].definition.properties;
});

const clearHours = () => {
  const newVal = modelValue.value;
  hoursRange.value = [900, 1600];
  newVal.dropoff = null;
  newVal.pickup = null;
  newVal.week_days = [];
  emit('change', newVal);
};

const clearAllFilters = () => {
  const newVal = modelValue.value;
  hoursRange.value = [900, 1600];
  newVal.vacancy = null;
  newVal.distance = null;
  newVal.dropoff = null;
  newVal.pickup = null;
  newVal.week_days = [];
  newVal.program_types = [];
  newVal.categories = [];
  newVal.license_type = [];
  newVal.minimum_quality_rating = null;
  newVal.program_season = null;
  newVal.highlights = {};
  newVal.custom = {};
  newVal.exclude_closed = null;
  newVal.payment_subsidies_accepted = [];
  newVal.payment_other_programs = [];
  newVal.subsidy = null;
  moreFiltersDialogIsVisible.value = false;
  emit('change', newVal);
};

const clearMoreFilters = () => {
  const newVal = modelValue.value;
  newVal.highlights = {};
  newVal.custom = {};
  newVal.exclude_closed = null;
  newVal.payment_subsidies_accepted = [];
  newVal.payment_other_programs = [];
  newVal.subsidy = null;
  newVal.week_days = [];
  moreFiltersDialogIsVisible.value = false;
  emit('change', newVal);
};

const openMoreFiltersDialog = () => {
  moreFiltersDialogIsVisible.value = true;
  emit('redraw');
};

const emitChange = () => {
  const newVal = modelValue.value;

  if (newVal.week_days.length > 0) {
    const [dropoff, pickup] = hoursRange.value;
    newVal.dropoff = dropoff;
    newVal.pickup = pickup;
  } else {
    newVal.dropoff = null;
    newVal.pickup = null;
  }

  allFiltersDialogIsVisible.value = false;
  moreFiltersDialogIsVisible.value = false;
  emit('change', newVal);
};

const mileageRange = () => {
  return _.range(1, 31);
};
</script>
