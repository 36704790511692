<template>
  <MaskedInput
    v-model="zip"
    @update:model-value="emitChangeIfComplete"
    v-slot="{ inputRef, masked }"
    mask="#####"
  >
    <v-text-field
      v-model="masked.value"
      @keyup.enter="emitDoneIfComplete"
      id="search_origin_zip"
      :ref="inputRef"
      :placeholder="t('Enter a location')"
      aria-label="Enter a zip"
      class="dark-placeholder mt-0 ta-left"
      color="primary"
      data-cy="enter-zip-text"
      variant="outlined"
      hide-details
    />
  </MaskedInput>
</template>

<script setup>
import MaskedInput from '@/shared/components/form/MaskedInput.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const zip = ref(null);

const emit = defineEmits(['change', 'done']);

function emitChangeIfComplete(value) {
  if (value.length === 5) {
    emit('change', value);
  }
}

function emitDoneIfComplete() {
  if (zip.value.length === 5) {
    emit('done', zip.value);
  }
}
</script>
