<template>
  <div
    class="mb-4 px-4"
    data-cy="message"
  >
    <template v-if="message.attachment_id">
      <v-btn
        @click="openAttachment"
        :class="currentMemberClass"
        size="large"
        variant="flat"
      >
        <v-icon start> attachment </v-icon>
        <span
          v-text="processContent"
          class="fs-16 fw-500 message-container text-decoration-underline"
          data-cy="message-text"
        ></span>
      </v-btn>
    </template>
    <template v-else>
      <div
        :class="currentMemberClass"
        class="d-inline-block py-2 px-4 rounded-sm"
      >
        <MarkdownContent
          :content="processContent"
          class="fs-16 fw-500 message-container"
        />
      </div>
    </template>

    <div
      class="fs-12 c-light-black"
      data-cy="message-author-and-date"
    >
      <LongDateTime
        :date="message.created_at"
        :prefix="message.member_id != currentMemberId ? messageAuthor : ''"
        prefix-separator=","
      />
    </div>
  </div>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import { useStore } from 'vuex';

const store = useStore();
const props = defineProps({
  message: {
    type: Object,
    default: null,
  },
});

const currentMemberId = ref(store.state.profile.id);

const currentMemberClass = computed(() => {
  return props.message.member_id == currentMemberId.value
    ? 'bg-dark-blue c-white'
    : 'c-black bg-super-light-blue';
});

const messageAuthor = computed(() => {
  if (props.message.meta.member?.is_specialist) {
    return [props.message.meta.member.name, store.state.brand.organization_name].join(' @ ');
  }
  return props.message.meta.member
    ? props.message.meta.member.name
    : props.message.meta.member_name;
});

const processContent = computed(() => {
  return props.message.text.replace(/\\_/g, '_').replace(/\n/g, '<br>');
});

async function openAttachment() {
  const resp = await Api.member.attachment.get(props.message.attachment_id);
  if (resp?.status !== 200) return;

  window.open(resp.data.url, '_blank');
}
</script>

<style>
.message-container p:not(:last-child) {
  margin-bottom: 12px;
}

.message-container p:last-child {
  margin-bottom: 0px;
}
</style>
