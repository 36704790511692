import axios from 'axios';

export default {
  baseUrl() {
    return `${window.api_url}/organization/custom_view_results`;
  },

  async index({ customViewId, page, search }) {
    const params = {
      'filter[custom_view]': customViewId,
      'page[offset]': (page - 1) * 100,
    };

    if (search) {
      params['filter[search]'] = search;
    }

    return axios.get(`${this.baseUrl()}`, { params });
  },
};
