<template>
  <div v-if="localSearch">
    <v-row
      v-if="displayExpandedSearch"
      dense
    >
      <SearchBarElement
        ref="locationMenu"
        :menu-text="localSearch.unformatted_origin || localSearch.formatted_origin"
        md="6"
        menu-icon="location_on"
        menu-label="Change search location"
      >
        <RegionLocationEditor
          v-if="searchFlags.regionSearchEnabled"
          @update="handleRegionLocationChange"
          :search="localSearch"
        />

        <LocationEditor
          v-else
          @update="handleAddressLocationChange"
          ref="searchLocation"
          :disable-mode="true"
          :search="search"
        />
      </SearchBarElement>

      <SearchBarElement
        v-if="searchFlags.transportationOptionsEnabled"
        ref="modeMenu"
        :menu-icon="travelModeIcon"
        :menu-text="travelModeLabel"
        md="3"
        menu-label="Change travel mode"
      >
        <v-card>
          <v-card-text>
            <v-radio-group
              v-model="localSearch.travel_mode"
              class="mt-0 pa-1"
              hide-details
            >
              <v-radio
                v-for="type in $a.assets.transit_options"
                :key="type.value"
                :label="$t(type.text)"
                :value="type.value"
              />
            </v-radio-group>
          </v-card-text>

          <v-card-actions class="ta-right">
            <v-btn
              @click="updateSearch()"
              class="focus-very-visible"
              color="primary"
            >
              {{ $t('Done') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </SearchBarElement>

      <v-col
        v-if="searchFlags.dobSearchEnabled"
        cols="12"
        md="3"
      >
        <ChildrenMenuV2
          @add-child="(child) => handleAddChild(child)"
          @remove-child="(index) => handleRemoveChild(index)"
          @update-child="(child, index) => handleChildrenOverride(child, index)"
          :children="localSearch.children"
          :inline-style="true"
        />
      </v-col>

      <SearchBarElement
        v-else-if="!searchFlags.defaultAgeGroupEnabled"
        ref="childrenMenu"
        :menu-text="childrenValue"
        md="3"
        menu-icon="person_outline"
        menu-label="Change age groups"
      >
        <v-card>
          <v-card-text>
            <VolumeList
              ref="childVolume"
              :value="ageGroups"
            />
            <v-divider class="my-4" />
            <v-checkbox
              v-model="localSearch.include_all_children"
              :label="$t('Only show me results that serve all ages selected')"
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="updateAgeGroups()"
              class="focus-very-visible"
              color="primary"
            >
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </SearchBarElement>
    </v-row>

    <div v-else>
      <v-row
        v-if="$store.state.expandAppBarSmAndDown"
        dense
      >
        <v-dialog
          v-model="searchDialog"
          fullscreen
        >
          <v-card style="position: relative; padding-bottom: 50px">
            <v-card-text class="px-3 pt-8">
              <LocationEditor
                v-if="searchDialogMode == 'location'"
                @change="handleAddressLocationChange"
                :search="search"
                hide-close
              />

              <div v-if="searchDialogMode == 'mode'">
                <div
                  v-t="'Show commutes by:'"
                  class="c-black fs-18 fw-600 mb-2"
                />
                <v-list
                  v-model:selected="localSearch.travel_mode"
                  mandatory
                >
                  <v-list-item
                    v-for="type in $a.assets.transit_options"
                    :key="type.value"
                    :value="type.value"
                  >
                    <v-list-item-title>{{ $t(type.text) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>

              <div v-if="searchDialogMode == 'children' && !searchFlags.dobSearchEnabled">
                <div
                  v-t="'Add children:'"
                  class="c-black fs-18 fw-600 mb-2"
                />
                <LabeledVolume
                  v-for="(item, index) in ageGroups"
                  @decrease="modifyCount(index, -1)"
                  @increase="modifyCount(index, 1)"
                  :key="item.name"
                  :count="item.count"
                  :dense="ageGroups.length > 6"
                  :subtitle="item.subtitle || item.subtext"
                  :title="item.title || item.text"
                />
              </div>

              <div v-if="searchDialogMode == 'children' && searchFlags.dobSearchEnabled">
                <SearchDobChild
                  @add-child="(child) => handleAddChild(child)"
                  @remove-child="(index) => handleRemoveChild(index)"
                  @update-child="(child, index) => handleChildrenOverride(child, index)"
                  :children="localSearch.children"
                />
              </div>
            </v-card-text>
            <v-card-actions style="position: absolute; bottom: 0; width: 100%">
              <v-btn
                @click="searchDialog = false"
                color="primary"
                block
              >
                {{ $t('Done') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col
          class="px-4"
          cols="12"
          md="6"
        >
          <div class="d-flex">
            <v-text-field
              @click="openSearchDialog('location')"
              @click:append="openSearchDialog('location')"
              :model-value="localSearch.formatted_origin"
              density="compact"
              variant="filled"
              hide-details
              readonly
            >
              <template #prepend-inner>
                <v-icon
                  @click="openSearchDialog('location')"
                  color="primary"
                >
                  location_on
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <v-col
          v-if="searchFlags.transportationOptionsEnabled"
          class="px-4"
          cols="12"
          md="3"
        >
          <v-text-field
            @click="openSearchDialog('mode')"
            @click:append="openSearchDialog('mode')"
            :model-value="$t(travelModeLabel)"
            density="compact"
            variant="filled"
            hide-details
            readonly
          >
            <template #prepend-inner>
              <v-icon
                @click="openSearchDialog('mode')"
                color="primary"
              >
                {{ travelModeIcon }}
              </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col
          class="px-4"
          cols="12"
          md="3"
        >
          <v-text-field
            @click="openSearchDialog('children')"
            @click:append="openSearchDialog('children')"
            :model-value="childrenValue"
            density="compact"
            variant="filled"
            hide-details
            readonly
          >
            <template #prepend-inner>
              <v-icon
                @click="openSearchDialog('children')"
                color="primary"
              >
                person_outline
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          class="pa-4"
          cols="12"
        >
          <v-text-field
            @click:append="expand"
            @focus="expand"
            :model-value="localSearch.formatted_origin"
            density="compact"
            variant="filled"
            hide-details
            readonly
            tile
          >
            <template #prepend-inner>
              <v-icon color="primary"> location_on </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import SearchBarElement from '@/parent/components/searches/SearchBarElement.vue';
import ChildrenMenuV2 from '@/parent/components/children/ChildrenMenuV2.vue';
import SearchDobChild from '@/parent/components/searches/SearchDobChild.vue';
import SearchFlags from '@/parent/services/search-flags';
import LocationEditor from '@/shared/components/search/LocationEditor.vue';
import VolumeList from '@/shared/components/form/VolumeList.vue';
import RegionLocationEditor from '@/parent/components/searches/RegionLocationEditor.vue';

const emit = defineEmits(['search-update']);

const props = defineProps({
  search: {
    type: Object,
    required: true,
  },
});

const localSearch = ref(props.search);

const store = useStore();
const { t } = useI18n();
const display = useDisplay();

const searchFlags = new SearchFlags(store);
const ageGroups = ref(searchFlags.ageGroups);
const childrenMenu = ref(null);
const locationMenu = ref(null);
const searchDialog = ref(null);
const searchDialogMode = ref(null);
const modeMenu = ref(null);

const childVolume = ref(null);
const searchLocation = ref(null);

const displayExpandedSearch = computed(() => {
  return display.mdAndUp.value;
});

const sum = computed(() => {
  if (!searchFlags.dobSearchEnabled) {
    let total = 0;
    ageGroups.value.forEach((item) => {
      total += item.count;
    });
    return total;
  }

  return localSearch.value.children.filter(
    (child) => child.dob !== null && child.care_needed_date !== null,
  ).length;
});

const childrenValue = computed(() => {
  return [sum.value, sum.value === 1 ? t('child') : t('children')].join(' ');
});

const travelModeIcon = computed(() => {
  switch (localSearch.value.travel_mode) {
    case 'DRIVING':
      return 'directions_car';
    case 'BICYCLING':
      return 'directions_bike';
    case 'TRANSIT':
      return 'directions_transit';
    case 'WALKING':
      return 'directions_walk';
    default:
      return null;
  }
});

const travelModeLabel = computed(() => {
  switch (localSearch.value.travel_mode) {
    case 'DRIVING':
      return t('Driving');
    case 'BICYCLING':
      return t('Bicycling');
    case 'TRANSIT':
      return t('Transit');
    case 'WALKING':
      return t('Walking');
    default:
      return null;
  }
});

// watch(childrenMenu, (newVal) => {
//   if (!newVal) {
//     childrenMenuActivator.value.$el.focus();
//   }
// });

// watch(locationMenuOpen, (newVal) => {
//   if (!newVal) {
//     locationMenuActivator.value.$el.focus();
//   }
// });

// watch(travelModeMenuOpen, (newVal) => {
//   if (!newVal) {
//     travelModeActivator.value.$el.focus();
//   }
// });

watch(searchDialog, (newVal) => {
  if (!newVal) {
    if (searchDialogMode.value === 'location' || searchDialogMode.value === 'mode') {
      updateLocation();
    }

    if (!searchFlags.dobSearchEnabled) {
      updateAgeGroups();
    }
  }
});

const handleAddChild = (child) => {
  localSearch.value.children.push({ ...child });
};

const handleAddressLocationChange = (newVal) => {
  localSearch.value.origin = newVal.origin;
  localSearch.value.formatted_origin = newVal.formatted_origin;
  localSearch.value.location_type = newVal.location_type;
  localSearch.value.zip = newVal.zip;
  localSearch.value.destination = newVal.destination;
  localSearch.value.formatted_destination = newVal.formatted_destination;
  updateSearch();
};

const handleRemoveChild = (index) => {
  const childrenWithoutRemoved = localSearch.value.children.filter(
    (child) => child !== localSearch.value.children[index],
  );
  localSearch.value.children = childrenWithoutRemoved;
  emit('search-update', localSearch.value);
};

const handleRegionLocationChange = (newVal) => {
  localSearch.value.unformatted_origin = newVal;
  updateSearch();
};

const handleChildrenOverride = (child, index) => {
  localSearch.value.children[index] = child;
  emit('search-update', localSearch.value);
};

const expand = () => {
  store.commit('setExpandAppBarSmAndDown', true);
};

// const focusChildVolume = () => {
//   nextTick(() => {
//     if (childVolume.value) {
//       childVolume.value.focus();
//     }
//   });
// };

// const focusFirstLocationType = () => {
//   nextTick(() => {
//     if (locationMenuOpen.value) {
//       searchLocation.value.$refs.locationHome.$el.focus();
//     } else {
//       travelModeActivator.value.$el.focus();
//     }
//   });
// };

// const focusFirstTravelMode = () => {
//   nextTick(() => {
//     if (travelModeMenuOpen.value) {
//       travelMode.value[0].$refs.input.focus();
//     } else {
//       childrenMenuActivator.value.$el.focus();
//     }
//   });
// };

const openSearchDialog = (mode) => {
  searchDialogMode.value = mode;
  searchDialog.value = true;
};

const updateAgeGroups = () => {
  childrenMenu.value?.close();
  localSearch.value.ageGroups = ageGroups.value;
  emit('search-update', localSearch.value);
};

const updateLocation = () => {
  modeMenu.value?.close();
  updateSearch();
};

const updateSearch = () => {
  locationMenu.value?.close();
  modeMenu.value?.close();
  childrenMenu.value?.close();
  emit('search-update', localSearch.value);
};

const modifyCount = (index, addition) => {
  ageGroups.value[index].count += addition;
};

const setChildren = () => {
  localSearch.value.children.forEach((child) => {
    const index = ageGroups.value.findIndex((age) => age.name === child.group);

    if (index >= 0) {
      ageGroups.value[index].count = child.count;
      ageGroups.value[index].group = ageGroups.value[index].name;
    }
  });
};

onMounted(() => setChildren());
</script>
