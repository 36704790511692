<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-divider
      v-if="dividedTop"
      class="mt-2 mb-4"
    />

    <div class="d-flex align-center justify-space-between">
      <div class="me-3">
        <div
          :class="[titleClasses, bold ? 'fs-18 fw-600' : 'fs-18 fw-500']"
          class="c-black"
        >
          {{ $t(title) }}
        </div>

        <div
          v-if="subtitle"
          class="fs-16 c-light-black mt-1 text-pre-wrap"
        >
          {{ $t(subtitle) }}
        </div>
      </div>

      <div class="d-flex align-center">
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <span v-bind="props">
              <v-icon
                v-if="locked"
                class="me-1"
                color="grey"
                icon="lock"
              />
            </span>
          </template>
          <span v-if="hardLock">{{ $t(hardLockMessage) }}</span>
        </v-tooltip>

        <v-switch
          @update:model-value="handleUpdateModelValue"
          :aria-label="$t(title)"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :loading="processing"
          :model-value="localValue"
          :readonly="readonly"
          class="mt-0 pt-0"
          color="primary"
          role="switch"
          variant="filled"
          data-cy
          hide-details
          inset
          tile
        />
      </div>
    </div>

    <slot />

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized],

  props: {
    dividedBottom: {
      type: Boolean,
      default: false,
    },
    dividedTop: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleClasses: {
      type: [String, Array, Object],
      default: undefined,
    },
  },
};
</script>

<style scoped>
.v-input--horizontal {
  grid-template-areas: unset;
}
</style>
