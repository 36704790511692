import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';

/**
 * Handles navigating forward and backward in the parent's child subsidy new and continue flows
 * Requires question collections,
 * generates a sectionsList of all steps in the flow,
 * and handles forward/back navigation
 * @param {{
 * childrenEligibilityQuestions: object (ref) - questions filtered for each child, indexed by child ID
 * childrenOtherQuestions: object (ref) - questions filtered for each child, indexed by child ID
 * childrenVerificationQuestions: object (ref) - questions filtered for each child, indexed by child ID
 * childrenWithSubsidies: array (ref) - array of children with subsidies included in this flow
 * eligibleChildren: array (ref) - array of children with eligible subsidies in this flow
 * eligibleChildrenWithPreferencesAllowed: array (ref) - children eligible for provider or program preferences
 * groupEligibilityQuestions: array (ref) - questions for the group eligibility section
 * groupOtherQuestions: array (ref) - questions for the group
 * groupVerificationQuestions: array (ref) - questions for the group verification section
 * includeChildrenSection: boolean - used by ChildSubsidyNew to prompt to create/edit/include children
 * processing: boolean (ref)
 * subsidyProgram: object (ref)
 * transitionName: string (ref)
 * }}
 *
 * The `section` ref is crucial to how both ChildSubsidyNew and ChildSubsidyContinue determine what to display
 * @returns {{
 * currentStep: number (ref)
 * section: string (ref)
 * sectionsList: array (computed) - valuable for unit testing and may have value in component `loaded` checks
 * step: function
 * stepBack: function
 * stepForward: function
 * stepTotal: number (computed)
 * subsidySections: object
 * }}
 */

export default function useChildSubsidyStepUtils({
  childrenEligibilityQuestions,
  childrenOtherQuestions,
  childrenVerificationQuestions,
  childrenWithSubsidies,
  eligibleChildren,
  eligibleChildrenWithPreferencesAllowed,
  groupEligibilityQuestions,
  groupOtherQuestions,
  groupVerificationQuestions,
  includeChildrenSection = false,
  processing,
  subsidyProgram,
  transitionName,
}) {
  const route = useRoute();
  const currentStep = ref(1);
  const section = ref();
  const stepTotal = computed(() => sectionsList.value?.length);
  const { updateQuery } = useRouterHelper();

  async function step(options = { sectionName: null, direction: null }) {
    let newSectionIndex;
    if (options.direction === 'back') {
      transitionName.value = 'slide-right';
      newSectionIndex = currentSectionIndex.value - 1;
    } else if (options.direction === 'forward') {
      transitionName.value = 'slide-left';
      newSectionIndex = currentSectionIndex.value + 1;
    } else {
      newSectionIndex = sectionNameToIndex(options.sectionName);
    }
    if (newSectionIndex < 0) {
      newSectionIndex = 0; // Start at the first step when section is invalid
    }

    const newSectionName = sectionsList.value[newSectionIndex];

    currentStep.value = newSectionIndex + 1;
    section.value = newSectionName;

    await updateQuery({
      section: section.value,
      step: currentStep.value,
      programId: route?.query?.programId,
    });

    processing.value = false;
  }

  async function stepBack() {
    await step({ direction: 'back' });
  }

  async function stepForward() {
    await step({ direction: 'forward' });
  }

  // Build up an array of all section strings used in navigation to simplify 'question'/prompt navigation
  const sectionsList = computed(() => {
    if (!subsidyProgram.value) return [];

    const sectionStepNames = [];

    sectionDefinitions.value.forEach((sectionDef, _sectionIndex) => {
      const questions = sectionDef.questions?.value || [];
      if (sectionDef.skipSection) {
        return;
      } else if (sectionDef.type === sectionTypes.questionIndexed) {
        questions.forEach((_question, index) => {
          sectionStepNames.push(`${sectionDef.prefix}-${index}`);
        });
      } else if (sectionDef.type === sectionTypes.childAndQuestionIndexed) {
        sectionDef.childrenRef.value.forEach((child, childIndex) => {
          (questions[child.id] || []).forEach((_question, questionIndex) => {
            sectionStepNames.push(`${sectionDef.prefix}${childIndex}-${questionIndex}`);
          });
        });
      } else if (sectionDef.type === sectionTypes.childIndexed) {
        sectionDef.childrenRef.value.forEach((_child, childIndex) => {
          sectionStepNames.push(`${sectionDef.prefix}-${childIndex}`);
        });
      } else {
        sectionStepNames.push(sectionDef.prefix);
      }
    });

    return sectionStepNames;
  });

  const currentSectionIndex = computed(() => {
    return sectionNameToIndex(section.value);
  });

  function sectionNameToIndex(name) {
    return sectionsList.value.findIndex((sectionName) => sectionName === name);
  }
  const subsidySections = Object.freeze({
    groupHomeAddress: 'group',
    children: 'children',
    groupEligibility: 'GroupEligibility',
    childEligibility: 'ChildEligibility',
    eligibility: 'eligibility',
    thirdPartyQuestions: 'third-party-questions',
    favorites: 'favorites',
    groupOther: 'Group',
    childOther: 'Child',
    groupVerification: 'group-verification',
    childVerification: 'child-verification',
    childDocuments: 'child-documents',
    proofOfResidency: 'proof-of-residency',
    confirm: 'confirm',
  });

  const sectionTypes = Object.freeze({
    questionIndexed: 'questionIndexed',
    childAndQuestionIndexed: 'childAndQuestionIndexed',
    childIndexed: 'childIndexed',
  });

  const sectionDefinitions = computed(() => {
    return [
      { prefix: subsidySections.groupHomeAddress },
      {
        prefix: subsidySections.children,
        skipSection: !includeChildrenSection,
      },
      {
        prefix: subsidySections.groupEligibility,
        type: sectionTypes.questionIndexed,
        questions: groupEligibilityQuestions,
      },
      {
        prefix: subsidySections.childEligibility,
        type: sectionTypes.childAndQuestionIndexed,
        questions: childrenEligibilityQuestions,
        childrenRef: childrenWithSubsidies,
      },
      {
        prefix: subsidySections.eligibility,
        skipSection: !subsidyProgram.value?.eligibility,
      },
      {
        prefix: subsidySections.thirdPartyQuestions,
        skipSection: !subsidyProgram.value?.enable_third_party_applications,
      },
      {
        prefix: subsidySections.groupOther,
        type: sectionTypes.questionIndexed,
        questions: groupOtherQuestions,
      },
      {
        prefix: subsidySections.childOther,
        type: sectionTypes.childAndQuestionIndexed,
        childrenRef: eligibleChildren,
        questions: childrenOtherQuestions,
      },
      {
        prefix: subsidySections.favorites,
        childrenRef: eligibleChildrenWithPreferencesAllowed,
        type: sectionTypes.childIndexed,
      },
      {
        prefix: subsidySections.groupVerification,
        type: sectionTypes.questionIndexed,
        questions: groupVerificationQuestions,
      },
      {
        prefix: subsidySections.childVerification,
        type: sectionTypes.childAndQuestionIndexed,
        questions: childrenVerificationQuestions,
        childrenRef: eligibleChildren,
      },
      {
        prefix: subsidySections.childDocuments,
        type: sectionTypes.childIndexed,
        childrenRef: eligibleChildren,
        skipSection: !subsidyProgram.value?.verify_child,
      },
      {
        prefix: subsidySections.proofOfResidency,
        skipSection: !subsidyProgram.value?.verify_home_address,
      },
      { prefix: subsidySections.confirm },
    ];
  });

  return {
    currentStep,
    section,
    sectionsList,
    step,
    stepBack,
    stepForward,
    stepTotal,
    subsidySections,
  };
}
