import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(schemaId) {
    let url = `${window.api_url}/organization/schemas/${schemaId}/schema_properties`;
    return url;
  },

  create(schemaId, schemaProperty) {
    return axios
      .post(this.baseUrl(schemaId), { schema_property: schemaProperty })
      .catch(handleError);
  },

  destroy(schemaId, propertyId) {
    return axios.delete(`${this.baseUrl(schemaId)}/${propertyId}`).catch(handleError);
  },

  update(schemaId, propertyId, schemaProperty) {
    return axios
      .patch(`${this.baseUrl(schemaId)}/${propertyId}`, { schema_property: schemaProperty })
      .catch(handleError);
  },

  remove(schemaId, propertyId, params) {
    return axios
      .patch(`${this.baseUrl(schemaId)}/${propertyId}/remove`, { ...params })
      .catch(handleError);
  },

  upload(schemaId, upload) {
    return axios.post(`${this.baseUrl(schemaId)}/upload`, { upload }).catch(handleError);
  },
};
