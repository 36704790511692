import axios from 'axios';

import { asyncApi, handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/forms`;
    if (id) url += `/${id}`;
    return url;
  },

  create(resource) {
    return axios.post(this.baseUrl(), { form: resource }).catch(handleError);
  },

  destroy(id, success) {
    if (!success) return axios.delete(this.baseUrl(id)).catch(handleError);
    return axios.delete(this.baseUrl(id)).then(success);
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
    return axios.get(this.baseUrl(id)).then(success);
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters });
    return axios.get(this.baseUrl(), { params: filters }).then(success);
  },

  update(id, resource, success, failure) {
    if (!success) return axios.patch(this.baseUrl(id), { form: resource }).catch(handleError);
    return axios.patch(this.baseUrl(id), { form: resource }).then(success).catch(failure);
  },

  v2: asyncApi({ endpoint: '/v2/forms' }),
};
