<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="properties.role == 'formula'">
      <v-text-field
        v-model="localValue"
        @change="$emit('change', $event)"
        :append-inner-icon="processing ? 'pending' : 'lock'"
        :aria-label="`${t('Enter')} ${label}`"
        :autofocus="autofocus"
        :density="dense || veryDense ? 'compact' : undefined"
        :required="mandatory || undefined"
        :rules="rules"
        data-cy="field-input"
        prefix="="
        variant="filled"
        disabled
        hide-details
      />
    </div>

    <template v-else>
      <div v-if="properties.type == 'array'">
        <template v-if="properties.items.type == 'object'">
          <v-card
            v-for="(item, itemIndex) in localValue"
            :key="itemIndex"
            class="mb-3"
            border
            flat
            tile
          >
            <v-card-text>
              <v-row dense>
                <LabeledControl
                  v-for="field in itemFields"
                  :key="[itemIndex, field].join('.')"
                  :cols="field[1].format == 'date' ? '12' : groupedFieldCols"
                  :format="field[1].format"
                  :message="field[1].alias || field[1].title"
                  :type="field[1].type"
                >
                  <Field
                    v-model="item[field[0]]"
                    @change="$emit('change', $event)"
                    @input="inputEventHandler()"
                    :append-inner-icon="appendInnerIcon"
                    :booleans-as-checkboxes="booleansAsCheckboxes"
                    :hard-lock="locked || undefined"
                    :multiple-boolean-fields="multipleBooleanFields"
                    :properties="field[1]"
                    :readonly="readonly || undefined"
                    :required="mandatory || undefined"
                    :rules="rules"
                    :sibling-values="modelValue[0]"
                    condensed
                  />
                </LabeledControl>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <template v-if="!locked">
                <v-btn
                  @click="removeItem(itemIndex)"
                  class="focus-very-visible"
                  color="red"
                  variant="text"
                >
                  <span>{{ $t('Remove') }}</span>
                </v-btn>
              </template>
            </v-card-actions>
          </v-card>

          <template v-if="!locked">
            <v-btn
              @click="addItem(properties.items.properties)"
              class="focus-very-visible"
              color="primary"
            >
              {{ $t('Add') }}
            </v-btn>
          </template>
        </template>

        <template v-else>
          <v-row dense>
            <v-col>
              <template v-if="properties.items.enum?.length > 10 || condensed">
                <v-select
                  v-model="localValue"
                  @update:model-value="$emit('change', $event)"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(`Select ${label}`)"
                  :density="dense || veryDense ? 'compact' : undefined"
                  :disabled="locked || undefined"
                  :items="enumItems"
                  :readonly="readonly || undefined"
                  :required="mandatory || undefined"
                  :rules="rules"
                  data-cy="field-input"
                  variant="filled"
                  chips
                  closable-chips
                  hide-details
                  multiple
                />
              </template>

              <template v-else>
                <v-checkbox
                  v-for="(option, index) in properties.items.enum"
                  v-model="localValue"
                  @update:model-value="$emit('change', $event)"
                  :key="index"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(option)"
                  :autofocus="autofocus"
                  :disabled="locked || undefined"
                  :label="$t(option)"
                  :readonly="readonly || undefined"
                  :required="mandatory || undefined"
                  :rules="rules"
                  :value="option"
                  class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 mx-0 my-1"
                  data-cy="field-input"
                  hide-details
                  multiple
                />
              </template>
            </v-col>
          </v-row>
        </template>
      </div>

      <div v-if="properties.type == 'boolean'">
        <v-row dense>
          <template v-if="multipleBooleanFields && booleansAsCheckboxes">
            <v-col cols="12">
              <v-checkbox
                v-model="localValue"
                @update:model-value="$emit('change', $event)"
                :append-inner-icon="appendInnerIcon"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked || undefined"
                :label="checkboxLabel"
                :readonly="readonly || undefined"
                :required="mandatory || undefined"
                :rules="rules"
                class="bg-super-light-blue pa-2 bc-extra-light-gray b-1"
                data-cy="field-input"
                hide-details
              />
            </v-col>
          </template>

          <template v-else>
            <v-col cols="12">
              <v-radio-group
                v-model="localValue"
                @update:model-value="$emit('change', $event)"
                ref="radioButton"
                :label="multipleBooleanFields ? $t(label) : undefined"
                :required="mandatory || undefined"
                :rules="rules"
                class="mt-0"
                data-cy="field-input"
                hide-details
              >
                <v-radio
                  :disabled="locked || undefined"
                  :label="$t(trueLabel)"
                  :readonly="readonly || undefined"
                  :value="true"
                  class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
                />
                <v-radio
                  v-if="falseLabel"
                  :disabled="locked || undefined"
                  :label="$t(falseLabel)"
                  :readonly="readonly || undefined"
                  :value="false"
                  class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
                />
              </v-radio-group>
            </v-col>

            <v-col class="d-flex justify-end">
              <v-btn
                @click="clearResponse"
                :disabled="locked || undefined"
                class="b-1 my-1 fs-14"
                data-cy="clear_response_btn"
                size="small"
                variant="outlined"
              >
                <span>{{ $t('Clear response') }}</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </div>

      <div v-if="properties.type == 'integer'">
        <v-row dense>
          <v-col>
            <template
              v-if="properties.maximum && properties.maximum - (properties.minimum || 0) < 100"
            >
              <v-select
                v-model.number="localValue"
                @update:model-value="$emit('change', $event)"
                :append-inner-icon="appendInnerIcon"
                :aria-label="$t(`Select ${label}`)"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked || undefined"
                :items="maxMinArray"
                :readonly="readonly || undefined"
                :required="mandatory || undefined"
                :rules="rules"
                data-cy="field-input"
                variant="filled"
                hide-details
              />
            </template>

            <template v-else>
              <v-text-field
                v-model.number="localValue"
                @change="$emit('change', $event)"
                @update:model-value="inputEventHandler(properties)"
                :append-inner-icon="appendInnerIcon"
                :aria-label="`${t('Enter')} ${label}`"
                :autofocus="autofocus"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked || undefined"
                :readonly="readonly || undefined"
                :required="mandatory || undefined"
                :rules="rules"
                data-cy="field-input"
                variant="filled"
                hide-details
                tile
              />
            </template>
          </v-col>
        </v-row>
      </div>

      <div v-if="properties.type == 'number'">
        <v-row dense>
          <v-col>
            <template v-if="properties.role == 'currency-us'">
              <VCurrencyField
                v-model.number="localValue"
                @change="handleMaybeNumberChange($event)"
                @update:model-value="inputEventHandler(properties)"
                :append-inner-icon="appendInnerIcon"
                :aria-label="`${t('Enter')} ${label}`"
                :autofocus="autofocus"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked || undefined"
                :readonly="readonly || undefined"
                :required="mandatory || undefined"
                :rules="rules"
                data-cy="field-input"
                variant="filled"
                hide-details
                tile
              />
            </template>

            <template v-else>
              <template
                v-if="properties.maximum && properties.maximum - (properties.minimum || 0) < 100"
              >
                <v-select
                  v-model.number="localValue"
                  @update:model-value="$emit('change', $event)"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(`Select ${label}`)"
                  :density="dense || veryDense ? 'compact' : undefined"
                  :disabled="locked || undefined"
                  :items="maxMinArray"
                  :readonly="readonly || undefined"
                  :required="mandatory || undefined"
                  :rules="rules"
                  data-cy="field-input"
                  variant="filled"
                  condensed
                  hide-details
                />
              </template>

              <template v-else>
                <v-text-field
                  v-model.number="localValue"
                  @change="$emit('change', $event)"
                  @update:model-value="inputEventHandler(properties)"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="`${t('Enter')} ${label}`"
                  :autofocus="autofocus"
                  :density="dense || veryDense ? 'compact' : undefined"
                  :disabled="locked || undefined"
                  :readonly="readonly || undefined"
                  :required="mandatory || undefined"
                  :rules="rules"
                  data-cy="field-input"
                  type="number"
                  variant="filled"
                  hide-details
                  tile
                />
              </template>
            </template>
          </v-col>
        </v-row>
      </div>

      <div v-if="properties.type == 'string'">
        <template v-if="properties.enum && properties.enum.length > 0">
          <template v-if="condensed || properties.enum.length > 5">
            <v-select
              v-model="localValue"
              @update:model-value="
                $emit('change', $event);
                inputEventHandler(properties);
              "
              :append-inner-icon="appendInnerIcon"
              :aria-label="$t(`Select ${label}`)"
              :density="dense || veryDense ? 'compact' : undefined"
              :disabled="locked || undefined"
              :items="enumItems"
              :readonly="readonly || undefined"
              :required="mandatory || undefined"
              :rules="rules"
              data-cy="field-input"
              variant="filled"
              hide-details
              tile
            />
          </template>

          <template v-else>
            <v-row dense>
              <v-col cols="12">
                <v-radio-group
                  v-model="localValue"
                  @update:model-value="$emit('change', $event)"
                  ref="radioButton"
                  :required="mandatory || undefined"
                  :rules="rules"
                  class="mt-0"
                  data-cy="field-input"
                  hide-details
                >
                  <v-radio
                    v-for="(option, index) in properties.enum"
                    :key="index"
                    :disabled="locked || undefined"
                    :label="$t(option)"
                    :readonly="readonly || undefined"
                    :value="option"
                    class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
                  />
                </v-radio-group>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-btn
                  @click="clearResponse"
                  :disabled="locked || undefined"
                  class="b-1 my-1 fs-14"
                  size="small"
                  variant="outlined"
                >
                  <span>{{ $t('Clear response') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-if="properties.format == 'date'">
          <v-row dense>
            <LabeledDate
              v-model="localValue"
              @change="$emit('change', $event)"
              :autofocus="autofocus"
              :dense="dense || veryDense"
              :disabled="locked || undefined"
              :hard-lock="locked || undefined"
              :maximum="maxDate"
              :minimum="minDate"
              :readonly="readonly || undefined"
              :required="mandatory || undefined"
              :rules="rules"
              data-cy="field-input"
            />
          </v-row>
        </template>

        <template v-if="properties.role == 'phone-us'">
          <MaskedInput
            v-model="localValue"
            v-slot="{ inputRef, masked }"
            mask="(###) ###-####"
          >
            <v-text-field
              v-model="masked.value"
              @change="$emit('change', $event)"
              :ref="inputRef"
              :append-inner-icon="appendInnerIcon"
              :aria-label="$t('Enter phone number')"
              :autofocus="autofocus"
              :density="dense || veryDense ? 'compact' : undefined"
              :disabled="locked || undefined"
              :readonly="readonly || undefined"
              :required="mandatory || undefined"
              :rules="rules"
              data-cy="field-input"
              placeholder="(555) 555-5555"
              variant="filled"
              hide-details
              tile
            />
          </MaskedInput>
        </template>

        <template v-if="properties.role == 'text-long'">
          <v-textarea
            v-if="!locked"
            v-model="localValue"
            @change="$emit('change', $event)"
            @update:model-value="inputEventHandler(properties)"
            :append-inner-icon="appendInnerIcon"
            :aria-label="$t(label)"
            :autofocus="autofocus"
            :density="dense || veryDense ? 'compact' : undefined"
            :disabled="locked || undefined"
            :readonly="readonly || undefined"
            :required="mandatory || undefined"
            :rules="rules"
            data-cy="field-input"
            variant="filled"
          />
          <div
            v-else
            class="disabled-textarea-container"
          >
            <v-icon icon="lock" />
            <div
              v-html="localValue.replace(/\n/g, '<br>')"
              class="textarea-content"
            />
          </div>
        </template>

        <template
          v-if="
            !(properties.enum && properties.enum.length > 0) &&
            properties.format != 'date' &&
            !properties.role
          "
        >
          <MaskedInput
            v-model="localValue"
            @update:model-value="inputEventHandler(properties)"
            v-slot="{ inputRef, masked }"
            :mask="properties.mask"
          >
            <v-text-field
              v-model="masked.value"
              @change="$emit('change', $event)"
              :ref="inputRef"
              :append-inner-icon="appendInnerIcon"
              :aria-label="`${t('Enter')} ${label}`"
              :autofocus="autofocus"
              :density="dense || veryDense ? 'compact' : undefined"
              :disabled="locked || undefined"
              :placeholder="properties.placeholder"
              :readonly="readonly || undefined"
              :required="mandatory || undefined"
              :rules="rules"
              data-cy="field-input"
              variant="filled"
              hide-details
              tile
            />
          </MaskedInput>
        </template>
      </div>
      <p
        v-if="displayAnswer"
        class="my-1 fw-600"
      >
        {{ $t('Answer') }}: {{ answer }}
      </p>
    </template>
  </div>
</template>

<script setup>
import _ from 'lodash';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledDate from '@/shared/components/form/LabeledDate.vue';
import MaskedInput from '@/shared/components/form/MaskedInput.vue';
import VCurrencyField from '@/shared/components/form/VCurrencyField.vue';
import { dateMaximum, dateMinimum } from '@/shared/services/schema-date-validation';
import { capitalize } from '@/plugins/filters';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const display = useDisplay();
const store = useStore();
const { t } = useI18n();

const props = defineProps({
  answer: {
    type: String,
    default: null,
  },
  answerType: {
    type: String,
    default: null,
  },
  autofocus: {
    type: Boolean,
    default: undefined,
  },
  booleansAsCheckboxes: {
    type: Boolean,
    default: undefined,
  },
  condensed: {
    type: Boolean,
    default: undefined,
  },
  dense: {
    type: Boolean,
    default: undefined,
  },
  displayAnswer: {
    type: Boolean,
    default: undefined,
  },
  falseLabel: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: undefined,
  },
  landscape: {
    type: Boolean,
    default: undefined,
  },
  multipleBooleanFields: {
    type: Boolean,
    default: undefined,
  },
  properties: {
    type: Object,
    default() {
      return {};
    },
  },
  processing: {
    type: Boolean,
    default: undefined,
  },
  siblingValues: {
    type: Object,
    default() {
      return {};
    },
  },
  trueLabel: {
    type: String,
    default: null,
  },
  veryDense: {
    type: Boolean,
    default: undefined,
  },
  mandatory: {
    type: Boolean,
    default: undefined,
  },
});

const modelValue = defineModel({ type: undefined });

const emit = defineEmits(['change', 'change:input', 'update:modelValue']);

const emittedMountedChangeEvent = ref(false);
const localValue = ref(getInitialValue());
const radioButton = ref(null);

const appendInnerIcon = computed(() => {
  return locked.value ? 'lock' : undefined;
});

const checkboxLabel = computed(() => {
  return capitalize(label.value);
});

const maxDate = computed(() => {
  return dateMaximum(props.properties, props.siblingValues);
});

const minDate = computed(() => {
  return dateMinimum(props.properties, props.siblingValues);
});

const enumItems = computed(() => {
  if (props.properties.type === 'array') {
    return props.properties.items.enum?.map((item) => ({
      text: t(item),
      value: item,
    }));
  }

  if (props.properties.type === 'string' && props.properties.enum) {
    return props.properties.enum?.map((item) => ({
      text: t(item),
      value: item,
    }));
  }

  return [];
});

const itemFields = computed(() => {
  return _.sortBy(
    Object.entries(props.properties.items?.properties || {}),
    (object) => object[1].index,
  );
});

const groupedFieldCols = computed(() => {
  if (display.smAndDown.value) return '12';

  return Math.max(3, 12 / itemFields.value.length).toString();
});

const label = computed(() => {
  return t(props.properties.alias || props.properties.title);
});

const locked = computed(() => {
  if (props.readonly) return true;

  if (Array.isArray(props.properties?.editRoles)) {
    return !props.properties.editRoles.includes(store.state.role);
  }

  return false;
});

const maxMinArray = computed(() => {
  if (props.properties.maximum && props.properties.minimum !== undefined) {
    return [...Array(props.properties.maximum - (props.properties.minimum || 0)).keys()].map(
      (item) => item + (props.properties.minimum || 0),
    );
  }
  return [];
});

const rules = computed(() => {
  const rulesData = [];

  if (props.mandatory) {
    const rule = async (value) =>
      (value !== null && value !== undefined && value.length !== 0) ||
      `${label.value} ${t('is required')}`;
    rulesData.push(rule);
  }

  return rulesData;
});

watch(
  () => modelValue.value,
  (newValue, oldValue) => {
    if (newValue === oldValue || (newValue?.length === 0 && !oldValue)) return;

    localValue.value = newValue;
  },
);

watch(
  () => localValue.value,
  (newVal) => {
    emit('update:modelValue', newVal);
  },
  { deep: true },
);

function addItem(props) {
  return localValue.value.push(
    Object.keys(props).reduce((accumulator, value) => ({ ...accumulator, [value]: '' }), {}),
  );
}

function clearResponse() {
  localValue.value = null;
  emit('change', null);
}

function getInitialValue() {
  if (!modelValue.value && props.properties.type === 'array') return [];

  return modelValue.value;
}

function handleMaybeNumberChange(event) {
  // Event can sometimes be an object, so we need to check if it's a number
  if (typeof event !== 'number' && event != null) return;

  // Hack in place until this is resolved: https://github.com/dm4t2/vue-currency-input/issues/421
  if (!emittedMountedChangeEvent.value) {
    emittedMountedChangeEvent.value = true;
    return;
  }

  emit('change', event);
}

function inputEventHandler(properties) {
  if (properties?.mask) return;

  emit('change:input');
}

function removeItem(index) {
  localValue.value.splice(index, 1);
}

onMounted(async () => {
  if (props.properties.type === 'boolean' && props.autofocus) radioButton.value.focus();
});
</script>

<style scoped lang="scss">
.disabled-textarea-container {
  background-color: rgba(#eee, 40%);
  color: darken(#bdbdbd, 13%);
  font-size: 1rem;
  height: 136px;
  position: relative;
  width: 100%;

  i.v-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .textarea-content {
    height: 136px;
    overflow-y: auto;
    padding: 10px 36px 10px 16px;
    white-space: pre-wrap;
    width: 100%;
  }
}
.v-selection-control {
  > :deep(.v-label) {
    width: 100%;
    padding-left: 56px;
    z-index: -1;
  }
}
</style>
