<template>
  <v-col
    :md="props.md"
    cols="12"
  >
    <v-menu
      v-model="menuOpen"
      :close-on-content-click="false"
      offset="5"
    >
      <template #activator="{ props: activatorProps }">
        <v-btn
          @keydown.tab.exact.stop.prevent="focusFirstLocationType()"
          v-bind="activatorProps"
          ref="menuActivator"
          :aria-label="menuLabel"
          append-icon="expand_more"
          class="mxw-100pc justify-space-between"
          color="surface-light"
          variant="flat"
          block
        >
          <v-icon
            aria-hidden="true"
            class="me-2 fs-24"
            color="primary"
          >
            {{ menuIcon }}
          </v-icon>
          <span class="fs-16 mxw-70pc constrained fs-16">
            {{ t(menuText) }}
          </span>
          <v-spacer />
        </v-btn>
      </template>

      <slot />
    </v-menu>
  </v-col>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  md: {
    type: Number,
    required: true,
  },
  search: {
    type: Object,
    required: true,
  },
  menuLabel: {
    type: String,
    required: true,
  },
  menuIcon: {
    type: String,
    required: true,
  },
  menuText: {
    type: String,
    required: true,
  },
});

const menuOpen = ref(false);

function close() {
  menuOpen.value = false;
}

defineExpose({
  close,
});
</script>
