<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-12"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-text class="mnh-250 mt-4">
          <QuestionSet
            v-model="form"
            @back="backFromQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromQuestion($event)"
            :attachment-owner-id="form.id"
            :attachment-owner-type="'Form'"
            :attachments="attachments"
            :processing="processing"
            :questions="validQuestions"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';

const route = useRoute();
const section = ref(null);
const store = useStore();
const { updateQuery } = useRouterHelper();

const attachments = ref([]);
const form = ref(null);
const index = ref(0);
const processing = ref(false);
const schema = ref(null);
const transitionName = ref('slide-left');

const schemaId = computed(() => schema.value?.id);

const { questionsLoaded, validateAnswers, validQuestions } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: schemaId,
  syncedObjectRef: form,
});

const loaded = computed(() => form.value && schema.value && questionsLoaded.value);

async function backFromQuestion(index) {
  processing.value = true;

  if (index.value - 1 < 0) {
    processing.value = false;
    await close();
  } else {
    await goToQuestion(index.value - 1);
  }
}

async function close() {
  window.location.replace(schema.value.meta.submitted_redirect_url);
}

async function createForm() {
  const resp = await api.parent.form.create({
    schema_id: schema.value.id,
  });
  if (resp?.status === 201) {
    form.value = resp.data;
  }
}

async function forwardFromQuestion(index) {
  processing.value = true;

  await validateAnswers();
  if (index + 1 >= validQuestions.value.length) {
    await submit();
  } else {
    await goToQuestion(index + 1);
  }
}

async function goToQuestion(newIndex) {
  if (newIndex > index.value) {
    transitionName.value = 'slide-left';
  } else {
    transitionName.value = 'slide-right';
  }

  processing.value = false;
  section.value = `question-${newIndex}`;
  index.value = newIndex;
  await updateQuery({ section: section.value, step: newIndex });
}

async function loadAttachments() {
  const params = {
    owner_type: 'Form',
    owner_id: form.value.id,
  };
  const response = await api.member.attachment.index(params);
  if (response?.status !== 200) return;
  attachments.value = response.data;
}

async function open() {
  processing.value = true;
  schema.value = store.state.schemas[route.params.schemaId];
  await createForm();
  await validateAnswers();
  await goToQuestion(0);
}

async function submit() {
  const resp = await api.parent.form.update(form.value.id, { ...form.value, submitted: true });
  processing.value = false;

  if (resp?.status !== 200) return;

  await close();
}

onMounted(async () => {
  if (route.params.schemaId) {
    await open();
  }
});
</script>
