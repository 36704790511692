import Api from '@/shared/services/api/member';

function getChildQuestions(subsidyId, options = {}) {
  if (options.draft_data) {
    return Api.childApplicationData.questionSetForDraft(subsidyId, options);
  } else {
    return Api.childApplicationData.questionSet(subsidyId, options);
  }
}

function getGroupQuestions(subsidyId, options = {}) {
  if (options.draft_data) {
    return Api.groupApplicationData.questionSetForDraft(subsidyId, options);
  } else {
    return Api.groupApplicationData.questionSet(subsidyId, options);
  }
}

function getChildEligibilityQuestions(subsidyId, options = {}) {
  return getChildQuestions(subsidyId, { ...options, eligibility: true });
}

function getChildOtherQuestions(subsidyId, options = {}) {
  return getChildQuestions(subsidyId, { ...options, eligibility: false });
}

function getChildVerificationQuestions(subsidyId, options = {}) {
  return getChildQuestions(subsidyId, { ...options, verification: true });
}

function getGroupEligibilityQuestions(subsidyId, options = {}) {
  return getGroupQuestions(subsidyId, { ...options, eligibility: true });
}

function getGroupOtherQuestions(subsidyId, options = {}) {
  return getGroupQuestions(subsidyId, { ...options, eligibility: false });
}

function getGroupVerificationQuestions(subsidyId, options = {}) {
  return getGroupQuestions(subsidyId, { ...options, verification: true });
}

async function loadChildQuestions(
  subsidyId,
  { includeUnpublished = false, draftData = null } = {},
) {
  if (!subsidyId) return;

  const options = { include_unpublished: includeUnpublished, draft_data: draftData };

  const [
    childEligibilityQuestionsResponse,
    childOtherQuestionsResponse,
    childVerificationQuestionsResponse,
  ] = await Promise.all([
    getChildEligibilityQuestions(subsidyId, options),
    getChildOtherQuestions(subsidyId, options),
    getChildVerificationQuestions(subsidyId, options),
  ]);

  return {
    childEligibilityQuestions: childEligibilityQuestionsResponse.data,
    childOtherQuestions: childOtherQuestionsResponse.data,
    childVerificationQuestions: childVerificationQuestionsResponse.data,
  };
}

async function loadGroupQuestions(
  subsidyId,
  { includeUnpublished = false, draftData = null } = {},
) {
  if (!subsidyId) return;

  const options = { include_unpublished: includeUnpublished, draft_data: draftData };

  const [
    groupEligibilityQuestionsResponse,
    groupOtherQuestionsResponse,
    groupVerificationQuestionsResponse,
  ] = await Promise.all([
    getGroupEligibilityQuestions(subsidyId, options),
    getGroupOtherQuestions(subsidyId, options),
    getGroupVerificationQuestions(subsidyId, options),
  ]);

  return {
    groupEligibilityQuestions: groupEligibilityQuestionsResponse.data,
    groupOtherQuestions: groupOtherQuestionsResponse.data,
    groupVerificationQuestions: groupVerificationQuestionsResponse.data,
  };
}

export default {
  getChildVerificationQuestions,
  getGroupVerificationQuestions,
  loadChildQuestions,
  loadGroupQuestions,
};
