import axios from 'axios';
import adminApi from '@/admin/services/bright_finder';
import baseApi from '@/shared/services/bright_finder';
import managerApi from '@/manager/services/bright_finder';
import organizationApi from '@/specialist/services/bright_finder';
import deepmerge from 'deepmerge';

axios.defaults.withCredentials = true;
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.patch.Accept = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // TODO: Should 401 bounce you back to the homepage?
    // TODO: this makes it very hard to debug fetch errors when it just kicks you back to the homepage
    // if ([401, 403].includes(error.response?.status)) {
    //   let nextLocation = `/${window.location.pathname.split('/')[1]}`;
    //   if (nextLocation === '/welcome') nextLocation = '/sign_out';
    //   window.location = nextLocation;
    // } else {
    throw error;
    // }
  },
);

// Request interceptor
axios.interceptors.request.use(
  (config) =>
    // Do something before request is sent
    // eslint-disable-next-line implicit-arrow-linebreak
    config,
  (error) =>
    // Do something with request error
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(error),
);

export default deepmerge.all([baseApi, managerApi, organizationApi, adminApi]);
