import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/parent/application_summaries`;
  },

  async index(params) {
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },
};
