<!-- eslint-disable max-len -->
<template>
  <v-container class="mxw-800 mx-auto py-12 px-1">
    <SubsidyProgramValidation
      v-if="loaded"
      :subsidy-program="subsidyProgram"
    >
      <template #open>
        <span class="c-primary font-weight-bold px-2">
          {{ renderText(subsidyProgram?.name) }}
        </span>
        <div
          ref="progressRef"
          :aria-label="$t('Progress indicator - question') + ' ' + currentStep"
          class="px-2 focus-invisible"
          tabindex="0"
          aria-live
        >
          <v-progress-linear
            v-if="!!progress"
            v-model="progress"
            :indeterminate="!loaded"
            class="focus-invisible mb-3"
            color="primary"
          />
        </div>

        <div class="px-md-2">
          <FormQuestion
            v-show="section === subsidySections.groupHomeAddress"
            @next="validateAddress()"
            :key="subsidySections.groupHomeAddress"
            :next-disabled="!validHomeAddress"
            :processing="processing"
            :reversible="false"
            :subtitle="
              subsidyProgram.home_address_subtitle ||
              'Let\'s start with your family\'s primary home address.'
            "
            :title="
              subsidyProgram.home_address_title || 'Welcome! We\'ll make it fast and easy to apply.'
            "
          >
            <template #preamble>
              <p>
                <span class="c-red me-1">*</span>
                <span
                  v-t="'Indicates a required field'"
                  class="c-light-black"
                />
              </p>
            </template>

            <v-row dense>
              <LabeledTextfield
                v-model="group.home_address"
                data-cy="address"
                message="Address"
                mandatory
              />
              <template v-if="subsidyProgram.city_options.length > 0">
                <LabeledSimpleSelect
                  v-model="group.home_city"
                  :items="subsidyProgram.city_options"
                  data-cy="city-input"
                  md="4"
                  message="City"
                  mandatory
                />
              </template>
              <template v-else>
                <LabeledTextfield
                  v-model="group.home_city"
                  data-cy="city"
                  md="4"
                  message="City"
                  mandatory
                />
              </template>
              <LabeledSimpleSelect
                v-model="group.home_state"
                :items="stateOptions"
                data-cy="state-input"
                md="4"
                message="State"
                mandatory
              />
              <template v-if="subsidyProgram.zip_options.length > 0">
                <LabeledSimpleSelect
                  v-model="group.home_zip"
                  :items="subsidyProgram.zip_options"
                  data-cy="zip-input"
                  md="4"
                  message="Zip"
                  mandatory
                />
              </template>
              <template v-else>
                <LabeledTextfield
                  v-model="group.home_zip"
                  data-cy="zip"
                  mask="#####"
                  md="4"
                  message="Zip"
                  mandatory
                />
              </template>
            </v-row>
            <v-row
              v-if="subsidyProgram.enable_homeless_address_option"
              dense
            >
              <v-checkbox
                v-model="group.experiencing_homelessness"
                :label="
                  $t(
                    subsidyProgram.homeless_attestation ||
                      'My family is experiencing homelessness.',
                  )
                "
              />
            </v-row>
          </FormQuestion>

          <QuestionSet
            v-model="group"
            @back="saveGroupStepBack"
            @change:attachments="loadAttachments()"
            @next="forwardFromGroupEligibilityQuestion"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :key-name="subsidySections.groupEligibility"
            :processing="processing"
            :questions="groupEligibilityQuestions"
            :schema="groupSchema.definition"
            :section="section"
            :transition-name="transitionName"
          />

          <QuestionSet
            v-model="localChild"
            @back="saveChildStepBack"
            @change="maybeClearPreferences(localChild, $event)"
            @change:attachments="loadAttachments()"
            @input="localChild = $event"
            @next="forwardFromChildEligibilityQuestion"
            key="child-eligibility"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachment-tags-supplements="[subsidy.id]"
            :attachments="attachments"
            :header="$t('Child') + ' - ' + localChild.first_name"
            :key-name="subsidySections.childEligibility + childIndex"
            :processing="processing"
            :questions="childrenEligibilityQuestions[localChild.id]"
            :schema="childSchema.definition"
            :section="section"
            :transition-name="transitionName"
          />

          <template v-if="subsidyProgram.eligibility && subsidy && section == 'eligibility'">
            <FormQuestion
              @back="stepBack"
              @finish="exit"
              @next="stepForward"
              key="eligibility"
              :finishable="!subsidy.projected_eligibility"
              :forwardable="subsidy.projected_eligibility"
              :processing="processing"
              :subtitle="
                subsidyProgram.eligibility_subtitle ||
                'Based on the answers you supplied, we\'ve determined your eligibility. If you think this determination is incorrect, please message our support team.'
              "
              :title="subsidyProgram.eligibility_title || 'Here is your eligibility report:'"
              class="focus-very-visible"
              finish-text="Exit"
              next-text="Continue application"
              next-arrow
            >
              <div
                v-if="subsidy.projected_eligibility"
                class="mb-8"
              >
                <SubsidyEligibilityCard
                  :key="subsidy.id"
                  :elevation="2"
                  :funding-sources="subsidy.meta.funding_sources"
                  :name="localChild.name"
                  :projected-eligibility="subsidy.projected_eligibility"
                  outlined
                />
              </div>

              <div v-if="!subsidy.projected_eligibility">
                <div
                  v-t="'Child is not eligible:'"
                  class="fs-20 fw-500 mb-4"
                />
                <SubsidyEligibilityCard
                  :key="subsidy.id"
                  :elevation="2"
                  :funding-sources="subsidy.meta.funding_sources"
                  :name="localChild.name"
                  :projected-eligibility="subsidy.projected_eligibility"
                  outlined
                />
              </div>

              <v-alert
                v-if="!subsidy.projected_eligibility"
                border="start"
                class="w-100pc mt-6 fs-20"
                color="primary"
                type="info"
              >
                <div>
                  <div
                    v-t="
                      `Your child is not eligible for this ${terms.program.toLowerCase()} at this time. Please contact support if you believe this is incorrect.`
                    "
                  />
                </div>
              </v-alert>
            </FormQuestion>
          </template>

          <ThirdPartyQuestions
            v-if="section == subsidySections.thirdPartyQuestions"
            @back="stepBack"
            @finish="exit"
            @next="forwardFromThirdPartyQuestions"
            key="third-party-questions"
            :model-value="subsidy"
            :next-disabled="thirdPartyContinueDisabled()"
            :processing="processing"
            :question-class="'focus-very-visible'"
            finish-text="Exit"
            next-text="Continue"
            forwardable
          />

          <QuestionSet
            v-model="group"
            @back="saveGroupStepBack"
            @change:attachments="loadAttachments()"
            @next="saveGroupStepForward"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :key-name="subsidySections.groupOther"
            :processing="processing"
            :questions="groupOtherQuestions"
            :schema="groupSchema.definition"
            :section="section"
            :transition-name="transitionName"
          />

          <QuestionSet
            v-if="childrenOtherQuestions[localChild.id]"
            v-model="localChild"
            @back="saveChildStepBack($event)"
            @change:attachments="loadAttachments()"
            @input="localChild = $event"
            @next="saveChildStepForward"
            key="child"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachment-tags-supplements="[subsidy.id]"
            :attachments="attachments"
            :header="$t('Child') + ' - ' + localChild.first_name"
            :key-name="subsidySections.childOther + childIndex"
            :processing="processing"
            :questions="childrenOtherQuestions[localChild.id]"
            :schema="childSchema.definition"
            :section="section"
            :transition-name="transitionName"
          />

          <template
            v-if="
              favoritesSectionEnabled && section == subsidySections.favorites + '-' + childIndex
            "
          >
            <template v-if="subsidyProgram.enable_advanced_selector">
              <template v-if="subsidyProgram.allow_program_preference">
                <ProgramPreferenceEditor
                  v-show="section == subsidySections.favorites + '-' + childIndex"
                  @back="stepBack"
                  @next="forwardFromFavoriteQuestion"
                  @reorder-programs="(from, to) => handleReorderSelectedPrograms(from, to)"
                  @selected-current-provider-id="handleSelectedCurrentProvider"
                  @selected-program-ids="(obj) => handleSelectedPrograms(obj)"
                  @selected-sibling-ids="handleSelectedSiblings"
                  @selected-staff-ids="handleSelectedStaff"
                  :key="'favorites-' + childIndex"
                  :child-data="localChild"
                  :current-provider="subsidy.current_provider_id"
                  :default-query="homeAddress"
                  :processing="processing"
                  :sibling-providers="subsidy.sibling_provider_ids"
                  :staff-providers="subsidy.staff_provider_ids"
                  :subsidy="subsidy"
                  :subsidy-program="subsidyProgram"
                />
              </template>
              <template v-else>
                <ProviderPreferenceEditor
                  v-show="section == subsidySections.favorites + '-' + childIndex"
                  @back="stepBack"
                  @next="forwardFromFavoriteQuestion"
                  :key="'favorites-' + childIndex"
                  :child-data="localChild"
                  :default-query="homeAddress"
                  :processing="processing"
                  :subsidy="subsidy"
                  :subsidy-program="subsidyProgram"
                />
              </template>
            </template>
            <template v-else>
              <template v-if="subsidyProgram.allow_preference">
                <FormQuestion
                  @back="stepBack"
                  @next="forwardFromFavoriteQuestion"
                  :key="'favorites-' + childIndex"
                  :header="$t('Child') + ' - ' + localChild.first_name"
                  :next-disabled="subsidy.provider_ids.length == 0"
                  :processing="processing"
                  :subtitle="
                    subsidyProgram.preference_subtitle ||
                    (subsidyProgram.allow_preference_order
                      ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                      : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                  "
                  :title="
                    subsidyProgram.preference_title ||
                    (subsidyProgram.allow_preference_order
                      ? 'Select and rank the providers you’d like to include in the application for this child'
                      : 'Select the providers you’d like to include in the application for this child')
                  "
                >
                  <FavoritesSelector
                    :child-data="localChild"
                    :default-query="homeAddress"
                    :ordered="subsidyProgram.allow_preference_order"
                    :subsidy="subsidy"
                    :subsidy-program="subsidyProgram"
                    class="mt-4 mb-4"
                  />
                </FormQuestion>
              </template>

              <template v-if="subsidyProgram.allow_enrolled">
                <FormQuestion
                  @back="stepBack"
                  @next="forwardFromFavoriteQuestion"
                  :key="'favorites-' + childIndex"
                  :header="$t('Child') + ' - ' + localChild.first_name"
                  :processing="processing"
                  :subtitle="subsidyProgram.preference_subtitle"
                  :title="
                    subsidyProgram.preference_title ||
                    'If your child is already enrolled in care, add the provider below.'
                  "
                >
                  <ProviderSelector
                    :child="localChild"
                    :subsidy="subsidy"
                  />
                </FormQuestion>
              </template>
            </template>
          </template>

          <div
            v-for="(question, index) in groupVerificationQuestions"
            v-show="section == [subsidySections.groupVerification, index].join('-')"
            :key="['group-verification', index].join('-')"
          >
            <FormQuestion
              @back="stepBack"
              @next="stepForward"
              :next-disabled="
                question.verification_mandatory && hasNoAttachmentsWithTag(question.id)
              "
              :processing="processing"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: question.id,
                  tags: [subsidy.id, question.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachmentsWithTag(question.id)"
                :processing="processing"
                class="mb-6"
              />
            </FormQuestion>
          </div>
          <div key="child-verification">
            <div
              v-for="(question, index) in childrenVerificationQuestions[localChild.id]"
              v-show="section == `${subsidySections.childVerification}${childIndex}-${index}`"
              :key="`${subsidySections.childVerification}${childIndex}-${index}`"
            >
              <FormQuestion
                @back="stepBack"
                @next="stepForward"
                :header="$t('Child') + ' - ' + localChild.first_name"
                :next-disabled="
                  question.verification_mandatory &&
                  hasNoAttachmentsWithTag(localChild.id + question.id)
                "
                :processing="processing"
                :schema="childSchema.definition"
                :subtitle="question.verification_subtitle"
                :title="question.verification_title"
              >
                <AttachmentUploader
                  @uploaded="attachments.push($event)"
                  :ref="['uploader', question.id, localChild.id].join('')"
                  :owner="{
                    type: 'SubsidyProgram',
                    id: subsidyProgram.id,
                    tag: localChild.id + question.id,
                    tags: [localChild.id, subsidy.id, question.id],
                  }"
                  class="mb-4"
                />
                <AttachmentList
                  @delete="loadAttachments"
                  :attachments="attachmentsWithTag(localChild.id + question.id)"
                  :processing="processing"
                  class="mb-6"
                />
              </FormQuestion>
            </div>
          </div>

          <template v-if="subsidyProgram.verify_child">
            <FormQuestion
              v-show="section == subsidySections.childDocuments + '-' + childIndex"
              @back="stepBack"
              @next="stepForward"
              key="child-documents"
              :header="$t('Child') + ' - ' + localChild.first_name"
              :next-disabled="
                subsidyProgram.verify_child_mandatory &&
                hasNoAttachmentsWithTag('child-documents-' + localChild.id)
              "
              :processing="processing"
              :subtitle="subsidyProgram.verify_child_subtitle"
              :title="subsidyProgram.verify_child_title"
            >
              <p class="fs-15 c-light-black" />
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: 'child-documents-' + localChild.id,
                  tags: ['child-documents', localChild.id, subsidy.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachmentsWithTag('child-documents-' + localChild.id)"
                :processing="processing"
              />
            </FormQuestion>
          </template>

          <FormQuestion
            v-show="section == subsidySections.proofOfResidency"
            @back="stepBack"
            @next="stepForward"
            key="proof-of-residency"
            :next-disabled="
              subsidyProgram.verify_home_address_mandatory &&
              hasNoAttachmentsWithTag('proof-of-residency')
            "
            :processing="processing"
            :subtitle="
              subsidyProgram.verify_home_address_subtitle ||
              'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
            "
            :title="
              subsidyProgram.verify_home_address_title ||
              'Upload a document to verify your current address'
            "
          >
            <p class="fs-15 c-light-black" />
            <AttachmentUploader
              @uploaded="attachments.push($event)"
              ref="uploader_residency"
              :owner="{
                type: 'SubsidyProgram',
                id: subsidyProgram.id,
                tag: 'proof-of-residency',
                tags: ['proof-of-residency', subsidy.id],
              }"
              class="mb-4"
            />
            <AttachmentList
              @delete="loadAttachments"
              :attachments="attachmentsWithTag('proof-of-residency')"
              :processing="processing"
            />
          </FormQuestion>

          <FormQuestion
            v-if="section == subsidySections.confirm"
            @back="stepBack"
            @finish="finish"
            key="confirm"
            :finish-disabled="!confirmed"
            :forwardable="false"
            :next-disabled="!confirmed"
            :processing="processing"
            :title="subsidyProgram.confirm_title || 'Confirm your application information'"
            header="Last step"
            finishable
          >
            <FormQuestion
              :processing="processing"
              :subtitle="subsidyProgram.home_address_subtitle"
              :title="
                subsidyProgram.home_address_title || 'What is your family\'s primary home address.'
              "
              class="mb-4"
              dense
              expanded
              hide-actions
              paddingless
            >
              <v-row>
                <LabeledTextfield
                  v-model="group.home_address"
                  :hard-lock="reviewLocked"
                  :schema-id="group.schema_id"
                  field="home_address"
                  message="Address"
                  dense
                />
                <LabeledTextfield
                  v-model="group.home_city"
                  :hard-lock="reviewLocked"
                  :schema-id="group.schema_id"
                  field="home_city"
                  md="4"
                  message="City"
                  dense
                />
                <LabeledSimpleSelect
                  v-model="group.home_state"
                  :hard-lock="reviewLocked"
                  :items="$a.assets.states"
                  :schema-id="group.schema_id"
                  field="home_state"
                  md="4"
                  message="State"
                  placeholder="Select one"
                  dense
                />
                <LabeledTextfield
                  v-model="group.home_zip"
                  :hard-lock="reviewLocked"
                  :schema-id="group.schema_id"
                  field="home_zip"
                  md="4"
                  message="Zip"
                  dense
                />
              </v-row>
              <v-row
                v-if="subsidyProgram.enable_homeless_address_option"
                dense
              >
                <v-checkbox
                  v-model="group.experiencing_homelessness"
                  :disabled="reviewLocked"
                  :label="
                    $t(
                      subsidyProgram.homeless_attestation ||
                        'My family is experiencing homelessness.',
                    )
                  "
                />
              </v-row>
            </FormQuestion>

            <v-divider class="mb-4" />

            <FormQuestion
              :key="localChild.id"
              :header="[$t('Child'), localChild.first_name].join(' - ')"
              :processing="processing"
              class="mb-4"
              title="Name and date of birth"
              break-after
              dense
              hide-actions
              paddingless
            >
              <Child
                :hard-lock="reviewLocked"
                :initial-child="localChild"
                dense
                remove-disabled
              />
            </FormQuestion>

            <QuestionSet
              v-model="group"
              @change:attachments="loadAttachments()"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachments="attachments"
              :processing="processing"
              :questions="groupEligibilityQuestions"
              :readonly="reviewLocked"
              :schema="groupSchema.definition"
              dense
              divided
              expanded
              hide-actions
              paddingless
            />

            <QuestionSet
              v-if="childrenEligibilityQuestions[localChild.id]"
              v-model="localChild"
              @change:attachments="loadAttachments()"
              @input="localChild = $event"
              :key="localChild.id + '-eligibility'"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidy.id]"
              :attachments="attachments"
              :header="$t('Child') + ' - ' + localChild.first_name"
              :processing="processing"
              :questions="childrenEligibilityQuestions[localChild.id]"
              :readonly="reviewLocked"
              :schema="childSchema.definition"
              dense
              divided
              expanded
              hide-actions
              paddingless
            />

            <QuestionSet
              v-model="group"
              @change:attachments="loadAttachments()"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachments="attachments"
              :processing="processing"
              :questions="groupOtherQuestions"
              :readonly="reviewLocked"
              :schema="groupSchema.definition"
              dense
              divided
              expanded
              hide-actions
              paddingless
            />

            <QuestionSet
              v-if="childrenOtherQuestions[localChild.id]"
              v-model="localChild"
              @change:attachments="loadAttachments()"
              @input="localChild = $event"
              :key="localChild.id + '-other'"
              :attachment-group-id="group.id"
              :attachment-owner-id="subsidyProgram.id"
              :attachment-owner-type="'SubsidyProgram'"
              :attachment-tags-supplements="[subsidy.id]"
              :attachments="attachments"
              :processing="processing"
              :questions="childrenOtherQuestions[localChild.id]"
              :readonly="reviewLocked"
              :schema="childSchema.definition"
              dense
              divided
              expanded
              hide-actions
              paddingless
            />

            <template v-if="favoritesSectionEnabled">
              <div key="favorites">
                <v-divider class="my-4 no-print" />
                <template v-if="subsidyProgram.enable_advanced_selector">
                  <template v-if="subsidyProgram.allow_program_preference">
                    <ProgramPreferenceReview
                      :child-name="localChild.first_name"
                      :current-provider-id="subsidy.current_provider_id"
                      :program-ids="subsidy.program_ids"
                      :sibling-provider-ids="subsidy.sibling_provider_ids"
                      :staff-provider-ids="subsidy.staff_provider_ids"
                      :subsidy-program="subsidyProgram"
                    />
                  </template>
                  <template v-else>
                    <ProviderPreferenceReview
                      :child-name="localChild.first_name"
                      :current-provider-id="subsidy.current_provider_id"
                      :provider-ids="subsidy.provider_ids"
                      :subsidy-program="subsidyProgram"
                    />
                  </template>
                </template>
                <template v-else>
                  <template v-if="subsidyProgram.allow_preference">
                    <FormQuestion
                      :header="$t('Child') + ' - ' + localChild.first_name"
                      :subtitle="
                        subsidyProgram.preference_subtitle ||
                        (subsidyProgram.allow_preference_order
                          ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                          : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                      "
                      :title="
                        subsidyProgram.preference_title ||
                        (subsidyProgram.allow_preference_order
                          ? 'Select and rank the providers you’d like to include in the application for this child'
                          : 'Select the providers you’d like to include in the application for this child')
                      "
                      dense
                      hide-actions
                      paddingless
                    >
                      <FavoritesSelector
                        :child-data="localChild"
                        :ordered="subsidyProgram.allow_preference_order"
                        :subsidy="subsidy"
                        :subsidy-program="subsidyProgram"
                        class="mt-4 mb-4"
                        initial-mode="selected"
                      />
                    </FormQuestion>
                  </template>

                  <template v-if="subsidyProgram.allow_enrolled">
                    <FormQuestion
                      :header="$t('Child') + ' - ' + localChild.first_name"
                      :subtitle="subsidyProgram.preference_subtitle"
                      :title="
                        subsidyProgram.preference_title ||
                        'If your child is already enrolled in care, add the provider below.'
                      "
                      dense
                      hide-actions
                      paddingless
                    >
                      <ProviderSelector
                        :child="localChild"
                        :subsidy="subsidy"
                      />
                    </FormQuestion>
                  </template>
                </template>
              </div>
            </template>

            <div
              v-for="question in groupVerificationQuestions"
              :key="question.id"
            >
              <v-divider class="my-4" />

              <FormQuestion
                :subtitle="question.verification_subtitle"
                :title="question.verification_title"
                dense
                hide-actions
                paddingless
              >
                <AttachmentList
                  :attachments="attachmentsWithTag(question.id)"
                  :empty-label="$t('No documents provided.')"
                  :processing="processing"
                  dense
                  hide-remove
                />
              </FormQuestion>
            </div>

            <template v-if="childrenVerificationQuestions[localChild.id]">
              <div
                v-for="question in childrenVerificationQuestions[localChild.id].filter(
                  (questionObject) => questionObject.valid,
                )"
                :key="question.id + localChild.id"
              >
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  dense
                  hide-actions
                  paddingless
                >
                  <AttachmentList
                    :attachments="attachmentsWithTag(localChild.id + question.id)"
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </div>
            </template>

            <template v-if="subsidyProgram.verify_child">
              <div>
                <v-divider class="my-4" />
                <FormQuestion
                  :header="$t('Child') + ' - ' + localChild.first_name"
                  :subtitle="subsidyProgram.verify_child_subtitle"
                  :title="subsidyProgram.verify_child_title"
                  dense
                  hide-actions
                  paddingless
                >
                  <AttachmentList
                    :attachments="attachmentsWithTag('child-documents-' + localChild.id)"
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </div>
            </template>

            <template v-if="subsidyProgram.verify_home_address">
              <v-divider class="my-4" />

              <FormQuestion
                :subtitle="
                  subsidyProgram.verify_home_address_subtitle ||
                  'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
                "
                :title="
                  subsidyProgram.verify_home_address_title ||
                  'Upload a document to verify your current address'
                "
                dense
                hide-actions
                paddingless
              >
                <AttachmentList
                  :attachments="attachmentsWithTag('proof-of-residency')"
                  :empty-label="$t('No documents provided.')"
                  :processing="processing"
                  dense
                  hide-remove
                />
              </FormQuestion>
            </template>

            <v-divider class="my-8" />

            <ThirdPartyQuestions
              v-if="subsidyProgram.enable_third_party_applications"
              key="third-party-questions"
              :model-value="subsidy"
              :processing="processing"
              :readonly="true"
              question-class="focus-very-visible"
            />

            <v-divider class="my-8" />

            <UserAttestation
              v-if="subsidy.third_party_application && subsidyProgram.enable_third_party_esign"
              @change="confirmed = $event"
              ref="attestationRef"
              :attestation-label="
                subsidyProgram.third_party_attestation_label[$store.state.profile.default_locale]
              "
              :attestation-text="subsidyProgram.third_party_attestation_text"
              :enable-esign="true"
            />

            <UserAttestation
              v-else
              @change="confirmed = $event"
              ref="attestationRef"
              :attestation-label="subsidyProgram.attestation_label"
              :attestation-text="subsidyProgram.attestation_text"
              :enable-esign="subsidyProgram.enable_esign"
            />
          </FormQuestion>

          <AddressVerificationDialog
            @cancel="saveGroupStepForward"
            @confirm="updateHomeAddress"
            ref="validateAddressDialog"
            cancel-button-text="Keep original"
            confirm-button-text="Use recommended"
            title="Verify address"
          />
        </div>
      </template>
    </SubsidyProgramValidation>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { SUBSIDY_EVENTS } from '@/parent/services/constants';
import { useStore } from 'vuex';
import AddressVerificationDialog from '@/shared/components/AddressVerificationDialog.vue';
import Api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import Child from '@/parent/components/children/Child.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FavoritesSelector from '@/parent/components/favorites/FavoritesSelector.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ProgramPreferenceEditor from '@/parent/components/ProgramPreferenceEditor.vue';
import ProgramPreferenceReview from '@/parent/components/subsidy/ProgramPreferenceReview.vue';
import ProviderPreferenceEditor from '@/parent/components/ProviderPreferenceEditor.vue';
import ProviderPreferenceReview from '@/parent/components/subsidy/ProviderPreferenceReview.vue';
import ProviderSelector from '@/parent/components/ProviderSelector.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import SubsidyApplicationData from '@/shared/services/subsidy/application-data.js';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import SubsidyProgramValidation from '@/shared/components/SubsidyProgramValidation.vue';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import useAssets from '@/shared/composables/useAssets';
import useEventBus from '@/shared/composables/useEventBus';
import UserAttestation from '@/shared/components/UserAttestation.vue';
import useRenderLiquid from '@/shared/composables/useRenderLiquid';
import useTerms from '@/shared/composables/useTerms';
import useChildSubsidyStepUtils from '@/shared/composables/child-subsidy/useChildSubsidyStepUtils';
import useSubsidyData from '@/shared/composables/useSubsidyData';
import useGroupAddressUtils from '@/shared/composables/child-subsidy/useGroupAddressUtils';

const LEAVE_WARNING =
  'You have unsaved changes and have not submitted your application. Are you sure you want to leave your application?';
const NOW_INELIGIBLE_ERROR =
  'Application data has changed that has resulted in your application becoming ineligible. Please review your application or contact support if you believe this is incorrect.';

const { renderText } = useRenderLiquid();
const { terms } = useTerms();
const $a = useAssets();
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const attachments = ref([]);
const attestationRef = ref(null);
const childId = computed(() => localChild.value?.id);
const childIndex = 0; // To approximate behavior in ChildSubsidyNew to keep sections/steps more consistent
const children = ref([]);
const confirmDialog = ref(null);
const confirmed = ref(false);
const eligibleChildren = computed(() => children.value);
const finished = ref(false);
const group = ref(null);
const localChild = ref(null);
const processing = ref(false);
const progress = computed(() => (currentStep.value / stepTotal.value) * 100);
const progressRef = ref(null);
const providers = ref([]);
const subsidies = ref([]);
const subsidy = ref(null);
const subsidyId = computed(() => subsidy.value?.id);
const subsidyProgram = ref(null);
const reviewLocked = ref(subsidyProgram.value?.enable_parent_review_locked);
const subsidyProgramId = ref(null);
const transitionName = ref('slide-left');
const validateAddressDialog = ref(null);
const loadFinished = ref(false);

const {
  childSchema,
  groupEligibilityQuestions,
  groupOtherQuestions,
  groupSchema,
  groupVerificationQuestions,
  loadChildrenQuestions,
  loadGroupQuestions,
  loadSubsidyProgramSchemas,
  childrenEligibilityQuestions,
  childrenOtherQuestions,
  childrenVerificationQuestions,
} = useSubsidyData({ includeUnpublished: { value: false } }, subsidyId, subsidyProgram);

const favoritesSectionEnabled = computed(() => {
  if (
    subsidyProgram.value.enable_iep_direct_placement &&
    localChild.value.individualized_education_plan
  ) {
    return false;
  }

  return (
    subsidyProgram.value.allow_preference ||
    subsidyProgram.value.allow_program_preference ||
    subsidyProgram.value.allow_enrolled
  );
});

const eligibleChildrenWithPreferencesAllowed = computed(() => {
  if (!favoritesSectionEnabled.value) return [];
  if (!subsidyProgram.value?.enable_iep_direct_placement) return eligibleChildren.value;

  return eligibleChildren.value?.filter((child) => !child.individualized_education_plan);
});

const { currentStep, section, step, stepBack, stepForward, stepTotal, subsidySections } =
  useChildSubsidyStepUtils({
    childrenEligibilityQuestions,
    childrenOtherQuestions,
    childrenVerificationQuestions,
    childrenWithSubsidies: children,
    eligibleChildren,
    eligibleChildrenWithPreferencesAllowed,
    groupEligibilityQuestions,
    groupOtherQuestions,
    groupVerificationQuestions,
    processing,
    subsidyProgram,
    transitionName,
  });
const {
  homeAddress,
  maybeSetDefaultCityAndState,
  stateOptions,
  updateHomeAddress,
  validateAddress,
  validHomeAddress,
} = useGroupAddressUtils({
  forwardFromHomeAddress: saveGroupLoadSubsidyStepForward,
  group,
  subsidyProgram,
  validateAddressDialog,
});

const loaded = computed(() => {
  return !!(
    localChild.value &&
    group.value &&
    subsidy.value &&
    subsidyProgram.value &&
    groupSchema.value &&
    childSchema.value &&
    loadFinished.value
  );
});

function attachmentsWithTag(tag) {
  return attachments.value.filter((attachment) => attachment.tag == tag);
}

function hasNoAttachmentsWithTag(tag) {
  return attachmentsWithTag(tag).length == 0;
}

function handleSelectedPrograms({ type, id }) {
  switch (type) {
    case SUBSIDY_EVENTS.ADD: {
      return subsidy.value.program_ids.push(id);
    }
    case SUBSIDY_EVENTS.REMOVE:
      return subsidy.value.program_ids.splice(
        subsidy.value.program_ids.findIndex((programId) => programId === id),
        1,
      );
    default:
      return null;
  }
}

function handleReorderSelectedPrograms(fromIndex, toIndex) {
  const reorderedProgramId = subsidy.value.program_ids[fromIndex];

  subsidy.value.program_ids.splice(fromIndex, 1);
  subsidy.value.program_ids.splice(toIndex, 0, reorderedProgramId);
}

function handleSelectedSiblings(ids) {
  subsidy.value.sibling_provider_ids = ids;
}

function handleSelectedStaff(ids) {
  subsidy.value.staff_provider_ids = ids;
}

function handleSelectedCurrentProvider(id) {
  subsidy.value.current_provider_id = id;
}

function thirdPartyContinueDisabled() {
  if (
    subsidy.value.third_party_application === false ||
    (subsidy.value.third_party_application &&
      subsidy.value.third_party_email &&
      subsidy.value.third_party_first_name &&
      subsidy.value.third_party_last_name &&
      subsidy.value.third_party_phone_number)
  ) {
    return false;
  }

  return true;
}

async function exit() {
  processing.value = true;
  if (subsidyProgram.value.enable_submit_signout) {
    await Api.member.session.promiseDestroy();
    store.commit('setAuthenticated', false);
    store.commit('setProfile', null);
    if (subsidyProgram.value.submit_title) {
      eventBus.longChime('You have been automatically logged out for security.');
    }
    router.push({ path: '/' });
  } else {
    router.push({ name: 'Dashboard' });
  }
}

async function finish() {
  processing.value = true;
  if (!subsidyProgram.value.enable_parent_review_locked) {
    await saveGroup();
    await saveChild();
  }

  const { data } = await Api.subsidy.get(subsidy.value.id);
  if (data.projected_eligibility === false) {
    eventBus.longChime(NOW_INELIGIBLE_ERROR);
    await step({ sectionName: subsidySections.groupHomeAddress, direction: 'back' });
    return;
  }

  if (subsidyProgram.value.enable_esign || subsidyProgram.value.enable_third_party_esign) {
    subsidy.value.revision_author_signed_name = attestationRef.value.esignName;
    subsidy.value.revision_author_signature_image = attestationRef.value.getEsignSignature();
  }

  const submitResp = await Api.subsidy.update(subsidy.value.id, {
    revision_author_signed_name: subsidy.value.revision_author_signed_name,
    revision_author_signature_image: subsidy.value.revision_author_signature_image,
    submitted: true,
    program_ids: subsidy.value.program_ids,
    provider_ids: subsidy.value.provider_ids,
    sibling_provider_ids: subsidy.value.sibling_provider_ids,
    staff_provider_ids: subsidy.value.staff_provider_ids,
  });
  processing.value = false;
  if (submitResp?.status != 200) return;

  finished.value = true;

  if (subsidyProgram.value.enable_submit_signout) {
    await Api.member.session.promiseDestroy();
    store.commit('setAuthenticated', false);
    store.commit('setProfile', null);
    if (subsidyProgram.value.submit_title) {
      eventBus.longChime(subsidyProgram.value.submit_title);
    }
    router.push({ path: '/' });
  } else {
    if (subsidyProgram.value.submit_title) {
      eventBus.longChime(subsidyProgram.value.submit_title);
    }
    router.push({
      name: 'Dashboard',
      query: { submittedSurveySchemaId: subsidyProgram.value.submitted_survey_schema_id },
    });
  }
}

async function forwardFromGroupEligibilityQuestion() {
  processing.value = true;
  await saveGroup();
  await loadGroupQuestions();
  await loadSubsidy();
  await stepForward();
}

async function forwardFromChildEligibilityQuestion() {
  processing.value = true;
  await saveChild();
  await loadChildrenQuestions({ subId: subsidyId.value, childId: childId.value });
  await loadSubsidy();
  await stepForward();
}

async function forwardFromFavoriteQuestion() {
  processing.value = true;
  await Api.subsidy.update(subsidy.value.id, {
    current_provider_id: subsidy.value.current_provider_id,
    program_ids: subsidy.value.program_ids,
    provider_ids: subsidy.value.provider_ids,
    sibling_provider_ids: subsidy.value.sibling_provider_ids,
    staff_provider_ids: subsidy.value.staff_provider_ids,
  });
  await stepForward();
}

async function forwardFromThirdPartyQuestions() {
  processing.value = true;
  const resp = await Api.subsidy.update(subsidy.value.id, {
    third_party_application: subsidy.value.third_party_application,
    third_party_email: subsidy.value.third_party_email,
    third_party_first_name: subsidy.value.third_party_first_name,
    third_party_last_name: subsidy.value.third_party_last_name,
    third_party_phone_number: subsidy.value.third_party_phone_number,
  });
  if (resp?.status !== 200) {
    processing.value = false;
    return;
  }
  await stepForward();
}

async function saveGroupStepBack() {
  processing.value = true;
  await saveGroup();
  await loadGroupQuestions();
  await stepBack();
}

async function saveGroupLoadSubsidyStepForward() {
  processing.value = true;
  await saveGroup();
  await loadSubsidy();
  await loadGroupQuestions();
  await stepForward();
}

async function saveGroupStepForward() {
  processing.value = true;
  await saveGroup();
  await loadGroupQuestions();
  await stepForward();
}
async function saveChildStepBack() {
  processing.value = true;
  await saveChild();
  await loadChildrenQuestions({ subId: subsidyId.value, childId: childId.value });
  await stepBack();
}
async function saveChildStepForward() {
  processing.value = true;
  await saveChild();
  await loadChildrenQuestions({ subId: subsidyId.value, childId: childId.value });
  await stepForward();
}

async function load() {
  loadFinished.value = false;
  const resp = await Api.subsidy.get(route.params.subsidyId);

  if (resp.data) {
    subsidy.value = resp.data;
    subsidies.value[0] = subsidy.value;
    subsidyProgramId.value = subsidy.value.subsidy_program_id;

    await loadChild();
    await loadGroup();
    await loadSubsidyProgram();
    await loadSubsidyProgramSchemas();
    await loadGroupQuestions();
    await loadChildrenQuestions({ subId: subsidyId.value, childId: childId.value });
    maybeSetDefaultCityAndState();
    await loadAttachments();
    providers.value = subsidy.value.provider_ids;

    // TODO: Pull this into the useSubsidyStepUtils composable
    // We must be loaded before using a route query to set the section.
    // We don't want the section ref to have a default value or it loads the default before the route section
    if (route.query.section && route.query.section !== section.value) {
      await step({ sectionName: route.query.section });
    } else if (!section.value) {
      // Wait until we've loaded and checked the route to set the default initial question:
      await step({ sectionName: subsidySections.groupHomeAddress });
    }
    loadFinished.value = true;
  }
}

watch(
  () => route.query.section,
  async (newSection, _oldSection) => {
    // This handles the browser's forward/back buttons
    if (loaded.value && newSection !== section.value) {
      await step({ sectionName: newSection });
    }
  },
);

async function loadChild() {
  const resp = await SubsidyApplicationData.getChildData(subsidy.value.id);
  localChild.value = resp.data;
  children.value = [localChild.value];
}

async function loadGroup() {
  const resp = await SubsidyApplicationData.getGroupData(subsidy.value.id);
  group.value = resp.data;
}

async function loadSubsidy() {
  await Api.subsidy.get(route.params.subsidyId, (resp) => {
    subsidy.value = resp.data;
    subsidies.value[0] = subsidy.value;
  });
}

async function loadSubsidyProgram() {
  const resp = await Api.public_api.organization.subsidy_program.get(subsidyProgramId.value);
  if (resp.data) {
    subsidyProgram.value = resp.data;
    document.title = `Continue application - ${subsidyProgram.value.name}`;
  }
}

async function loadAttachments() {
  processing.value = true;
  const params = {
    owner_type: 'SubsidyProgram',
    owner_id: subsidy.value.subsidy_program_id,
  };

  const resp = await Api.member.attachment.index(params);
  processing.value = false;
  if (resp?.status === 200) attachments.value = resp.data;
}

async function maybeClearPreferences(child, event) {
  if (!subsidyProgram.value.enable_iep_direct_placement) return;

  if (
    child.individualized_education_plan === false &&
    event?.attribute?.name === 'individualized_education_plan' &&
    event?.value === true
  ) {
    const targetSubsidy = subsidies.value.find((subsidyItem) => subsidyItem.child_id === child.id);

    targetSubsidy.current_provider_id = null;
    targetSubsidy.program_ids = [];
    targetSubsidy.provider_ids = [];
    targetSubsidy.sibling_provider_ids = [];
    targetSubsidy.staff_provider_ids = [];

    await Api.subsidy.update(targetSubsidy.id, {
      current_provider_id: targetSubsidy.current_provider_id,
      program_ids: targetSubsidy.program_ids,
      provider_ids: targetSubsidy.provider_ids,
      sibling_provider_ids: targetSubsidy.sibling_provider_ids,
      staff_provider_ids: targetSubsidy.staff_provider_ids,
    });
  }
}

async function saveGroup() {
  const resp = await SubsidyApplicationData.updateGroupData(subsidy.value.id, group.value);
  group.value = resp.data;
}

async function saveChild() {
  const resp = await SubsidyApplicationData.updateChildData(subsidy.value.id, localChild.value);
  localChild.value = resp.data;
}

onMounted(async () => {
  await load();

  window.onbeforeunload = async () => {
    await confirmDialog.value.confirmWithText('You have unsaved information.');
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (!finished.value) {
    const confirm = await confirmDialog.value.confirmWithText(LEAVE_WARNING);
    if (!confirm) return;
  }

  window.onbeforeunload = null;
  next();
});
</script>
