<template>
  <v-progress-linear
    v-if="!loaded"
    indeterminate
  />
  <div
    v-else
    data-testid="dashboard-v2"
  >
    <DashboardIntro
      @add-application="router.push({ name: 'ChildSelectOrCreate' })"
      :add-child="true"
      :first-name="store.state.profile.first_name"
      :hub-experience="hubExperience"
    />

    <DashboardInstructions :schema="groupSchema" />

    <DashboardTasks
      @reload-tasks="loadSummariesAndTasks"
      :items="applicationTasks"
    />

    <DashboardApplicationSummaries :items="applicationSummariesExcludingForms" />

    <DashboardFamilySubsidyPrograms
      v-if="!hubExperience"
      :items="familySubsidyPrograms"
    />

    <EligibilityScreenerResult
      v-if="hubExperience"
      class="mt-8"
      dashboard-view
    />
  </div>
</template>

<script setup>
import baseApi from '@/shared/services/bright_finder';
import DashboardApplicationSummaries from '@/parent/components/dashboards/DashboardApplicationSummaries.vue';
import DashboardIntro from '@/parent/components/dashboards/DashboardIntro.vue';
import DashboardInstructions from '@/parent/components/dashboards/DashboardInstructions.vue';
import DashboardTasks from '@/parent/components/dashboards/DashboardTasks.vue';
import DashboardFamilySubsidyPrograms from '@/parent/components/dashboards/DashboardFamilySubsidyPrograms.vue';
import EligibilityScreenerResult from '@/parent/views/eligibility-screener/EligibilityScreenerResult.vue';
import { subsidyProgramIsOpen } from '@/parent/services/subsidies';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const applicationSummaries = ref([]);
const applicationTasks = ref([]);
const familySubsidyPrograms = ref([]);
const loaded = ref(false);
const hubExperience = ref(false);

defineProps({
  group: {
    type: Object,
    default: null,
  },
  groupSchema: {
    type: Object,
    default: null,
  },
});

// Our initial implementation of new enrollment forms should only show in-progress forms and only in the To Do section
const applicationSummariesExcludingForms = computed(() => {
  return applicationSummaries.value.filter((item) => !item.form_name);
});

const getFamilySubsidyProgramApplications = (familySubsidyProgram) => {
  return applicationSummaries.value.filter(
    (application) => application.family_subsidy_program_id === familySubsidyProgram.id,
  );
};

const hasApplicationSummary = (familySubsidyProgram) => {
  return getFamilySubsidyProgramApplications(familySubsidyProgram).length > 0;
};

const hasApplicationSummaryInProgress = (familySubsidyProgram) => {
  return (
    getFamilySubsidyProgramApplications(familySubsidyProgram).filter(
      (application) => !application.submitted_at,
    ).length > 0
  );
};

const displayFamilySubsidyProgram = (familySubsidyProgram) => {
  const hasApplication = hasApplicationSummary(familySubsidyProgram);
  const hashApplicationInProgress = hasApplicationSummaryInProgress(familySubsidyProgram);
  const hasMultipleApplicationsEnabled =
    familySubsidyProgram.enable_multiple_applications &&
    (!hasApplication || !hashApplicationInProgress);

  return (
    subsidyProgramIsOpen(familySubsidyProgram) &&
    (hasMultipleApplicationsEnabled || !hasApplication)
  );
};

async function load() {
  loaded.value = false;
  await loadSummariesAndTasks();

  const { data: familySubsidyProgramData } =
    await baseApi.public_api.organization.family_subsidy_program.index();

  familySubsidyPrograms.value = familySubsidyProgramData
    .filter((familySubsidyProgram) => displayFamilySubsidyProgram(familySubsidyProgram))
    .map((familySubsidyProgram) => {
      return {
        ...familySubsidyProgram,
        has_applied: Boolean(hasApplicationSummary(familySubsidyProgram)),
      };
    });

  await setHubExperience();

  loaded.value = true;
}

async function loadSummariesAndTasks() {
  const response = await baseApi.parent.applicationSummary.index();
  applicationSummaries.value = response?.data;
  applicationTasks.value = response?.data.filter((item) => ![null, 'Review'].includes(item.action));
}

async function setHubExperience() {
  const response = await baseApi.screenerProgram.index();
  const screenerPrograms = response?.data;

  hubExperience.value = screenerPrograms && screenerPrograms.length > 0;
}

onMounted(load);
</script>
