<template>
  <transition-group :name="transitionName">
    <div
      v-for="(question, index) in questions"
      :key="keyName + '-' + index + question.id"
      :class="landscape ? 'bb-1 bc-outlined-grey' : ''"
    >
      <div v-if="expanded || section == keyName + '-' + index">
        <template v-if="question.is_section">
          <v-card
            :class="sectionClass(index)"
            :flat="flat"
            :variant="outlinedSections ? 'outlined' : 'elevated'"
            tile
          >
            <v-card-text>
              <div
                aria-level="2"
                class="fw-500 fs-16"
                role="heading"
              >
                {{ $t(question.title) }}
              </div>

              <div
                aria-level="3"
                class="fw-500 fs-14"
                role="heading"
              >
                {{ $t(question.subtitle) }}
              </div>
            </v-card-text>
          </v-card>
        </template>

        <template v-else>
          <QuestionWithField
            v-if="question.format === 'text'"
            v-model="modelValue"
            @back="$emit('back', index)"
            @change="$emit('change', $event)"
            @change:attachments="$emit('change:attachments')"
            @change:input="$emit('change:input', $event)"
            @click:notes="$emit('click:notes', question)"
            @next="$emit('next', index)"
            :attachment-group-id="attachmentGroupId"
            :attachment-owner-id="attachmentOwnerId"
            :attachment-owner-type="attachmentOwnerType"
            :attachment-readonly="attachmentReadonly"
            :attachment-status="attachmentStatus"
            :attachment-tags-supplements="attachmentTagsSupplements"
            :attachments="attachments"
            :autofocus="autofocus"
            :border="border"
            :color="color"
            :condensed="condensed"
            :dense="dense"
            :display-answer="displayAnswer"
            :display-notes="displayNotes"
            :elevation="elevation"
            :expanded="expanded"
            :header="header"
            :hide-actions="hideActions"
            :hide-field="hideField"
            :inline-verification="inlineVerification"
            :landscape="landscape"
            :notes-count="noteCount(question)"
            :outlined="outlined"
            :paddingless="paddingless"
            :processing="processing"
            :question="question"
            :readonly="readonly"
            :reversible="reversible"
            :schema="schema"
            :show-indicators="showIndicators"
            :show-passing="showPassing"
            :tile="tile"
            :very-dense="veryDense"
          />

          <AddressQuestion
            v-if="question.format === 'address'"
            v-model="modelValue"
            @back="$emit('back', index)"
            @change="$emit('change', $event)"
            @next="$emit('next', index)"
            :border="border"
            :color="color"
            :condensed="condensed"
            :dense="dense"
            :elevation="elevation"
            :expanded="expanded"
            :header="header"
            :hide-actions="hideActions"
            :hide-field="hideField"
            :landscape="landscape"
            :notes-count="noteCount(question)"
            :outlined="outlined"
            :paddingless="paddingless"
            :processing="processing"
            :question="question"
            :readonly="readonly"
            :reversible="reversible"
            :schema="schema"
            :show-indicators="showIndicators"
            :show-passing="showPassing"
            :tile="tile"
            :very-dense="veryDense"
          />

          <DocumentQuestion
            v-if="question.format === 'document'"
            v-model="modelValue"
            @back="$emit('back', index)"
            @change="$emit('change', $event)"
            @change:attachments="$emit('change:attachments')"
            @change:input="$emit('change:input', $event)"
            @click:notes="$emit('click:notes', question)"
            @next="$emit('next', index)"
            :attachment-group-id="attachmentGroupId"
            :attachment-owner-id="attachmentOwnerId"
            :attachment-owner-type="attachmentOwnerType"
            :attachment-readonly="attachmentReadonly"
            :attachment-status="attachmentStatus"
            :attachment-tags-supplements="attachmentTagsSupplements"
            :attachments="attachments"
            :autofocus="autofocus"
            :border="border"
            :color="color"
            :condensed="condensed"
            :dense="dense"
            :display-notes="displayNotes"
            :elevation="elevation"
            :expanded="expanded"
            :header="header"
            :hide-actions="hideActions"
            :landscape="landscape"
            :notes-count="noteCount(question)"
            :outlined="outlined"
            :paddingless="paddingless"
            :processing="processing"
            :question="question"
            :readonly="readonly"
            :reversible="reversible"
            :schema="schema"
            :show-indicators="showIndicators"
            :tile="tile"
            :very-dense="veryDense"
          />

          <ChangeResponseButton
            v-if="mode == 'review'"
            @jump="$emit('jump', $event)"
            :section="`${keyName}-${index}`"
          />

          <v-divider
            v-if="divided"
            :class="dividedClass"
          />
        </template>
      </div>
    </div>
  </transition-group>
</template>

<script setup>
import AddressQuestion from '@/shared/components/form/AddressQuestion.vue';
import ChangeResponseButton from '@/shared/components/form/ChangeResponseButton.vue';
import DocumentQuestion from '@/shared/components/form/DocumentQuestion.vue';
import QuestionWithField from '@/shared/components/form/QuestionWithField.vue';

defineOptions({
  inheritAttrs: false,
});

defineEmits([
  'back',
  'change:attachments',
  'change:input',
  'change',
  'click:notes',
  'jump',
  'next',
]);

/**
 * @property {boolean} expanded - Display all questions (e.g. reviewing a Subsidy application as a parent before submit)
 * @property {string} section - Display a specific section of questions (e.g. navigating through questions to complete a Subsidy application)
 */

const props = defineProps({
  attachmentGroupId: {
    type: String,
    default: null,
  },
  attachmentOwnerId: {
    type: String,
    default: null,
  },
  attachmentOwnerType: {
    type: String,
    default: null,
  },
  attachmentReadonly: {
    type: [Boolean, null],
    default: null,
  },
  attachmentTagsSupplements: {
    type: Array,
    default: () => [],
  },
  attachments: {
    type: Array,
    default: () => [],
  },
  attachmentStatus: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'transparent',
  },
  condensed: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  displayAnswer: {
    type: Boolean,
    default: false,
  },
  displayNotes: {
    type: Boolean,
    default: false,
  },
  divided: {
    type: Boolean,
    default: false,
  },
  elevation: {
    default: 0,
    type: Number,
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  flat: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: null,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  hideField: {
    type: Boolean,
    default: false,
  },
  inlineVerification: {
    type: Boolean,
    default: false,
  },
  keyName: {
    type: String,
    default: null,
  },
  landscape: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'edit',
  },
  notes: {
    type: Array,
    default: () => [],
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  outlinedSections: {
    type: Boolean,
    default: false,
  },
  paddingless: {
    type: Boolean,
    default: false,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  questions: {
    type: Array,
    default: () => [],
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  reversible: {
    type: Boolean,
    default: true,
  },
  schema: {
    type: Object,
    default: null,
  },
  section: {
    type: String,
    default: null,
  },
  showIndicators: {
    type: Boolean,
    default: false,
  },
  showPassing: {
    type: Boolean,
    default: false,
  },
  tile: {
    type: Boolean,
    default: false,
  },
  transitionName: {
    type: String,
    default: null,
  },
  veryDense: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({ type: Object });

const dividedClass = computed(() => {
  if (props.landscape) {
    return 'my-0';
  }
  if (props.dense) {
    return 'mb-4';
  }
  if (props.veryDense) {
    return 'mb-2';
  }
  return 'mb-6';
});

function noteCount(question) {
  return (props.notes || []).filter((note) => note.question_id === question.id).length;
}

function sectionClass(index) {
  const classes = [];
  if (props.landscape) {
    classes.push('mt-0');
    classes.push('bg-very-light-grey');
  } else {
    classes.push(index > 0 ? 'mt-8 mb-6' : 'mt-0 mb-6');
    classes.push('bg-super-light-grey');
  }
  return classes.join(' ');
}
</script>
