<template>
  <v-container
    class="bg-super-light-blue py-12 px-0"
    fluid
  >
    <v-row class="d-flex align-center mxw-1200 mx-auto">
      <v-col
        class="px-6"
        cols="12"
        md="6"
      >
        <div
          v-t="schema?.definition.properties.metadata.properties.long_name.default"
          class="fs-48 fw-600"
        ></div>
        <div
          v-t="schema?.definition.properties.metadata.properties.description.default"
          class="fs-16"
        ></div>
        <div class="my-5">
          <v-btn
            :to="{ name: 'Signup' }"
            class="text-decoration-none"
            color="primary"
            data-cy="apply_for_pfa"
            elevation="4"
            rounded
          >
            <span v-t="'Apply for Preschool for All'"></span>
            <v-icon text-decoration-none> chevron_right </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col
        class="px-6 ta-center"
        cols="12"
        md="6"
      >
        <img
          :src="schema?.image_url"
          alt=""
          class="mxh-500 w-100pc"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const schema = computed(() => store?.state.schemas[route.params.schemaId]);
</script>
