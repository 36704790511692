<template>
  <v-card class="pa-4">
    <v-card-text>
      <div class="fs-20 mb-4">
        {{ t('Search by zip code, city or county') }}
      </div>
      <v-text-field
        v-model="unformattedOrigin"
        @keyup.enter="handleEnter"
        :placeholder="$t('Enter zip code, city or county')"
        variant="solo-filled"
        hide-details
      />
    </v-card-text>
    <v-card-actions class="ta-right">
      <v-btn
        @click="handleDonePress"
        class="focus-very-visible"
        color="primary"
        data-cy="location_done"
      >
        {{ t('Done') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['update', 'close']);

const props = defineProps({
  search: {
    default: null,
    type: Object,
  },
});

const unformattedOrigin = ref(props.search.unformatted_origin);

function emitClose() {
  emit('close');
}

function emitUpdate() {
  emit('update', unformattedOrigin.value);
}

function handleEnter() {
  if (!unformattedOrigin.value) return;

  emitUpdate();
}

function handleDonePress() {
  if (unformattedOrigin.value) {
    emitUpdate();
  } else {
    emitClose();
  }
}
</script>
