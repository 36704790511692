<template>
  <div v-if="provider && programs && formsLoaded">
    <v-card
      :border="outlined"
      :flat="outlined"
      tile
    >
      <v-card-title class="py-2 px-4">
        <v-row
          class="d-flex align-center"
          dense
        >
          <template v-if="ordered">
            <v-col :class="rankingClass">
              <template v-if="reorderable">
                <EditableSelectionRanking
                  @move="$emit('move', $event)"
                  :index="enrollment.preference_order - 1"
                  :length="length"
                  :processing="processing"
                />
              </template>
              <template v-else>
                <SelectionRanking
                  :index="enrollment.preference_order - 1"
                  :length="length"
                />
              </template>
            </v-col>
          </template>
          <template v-if="enrollment">
            <v-col
              :class="statusClass"
              cols="12"
              md="6"
            >
              <template v-if="enrollment.public_status === 'Offered'">
                <v-btn
                  @click="declineDecisionDialog.open()"
                  class="me-3"
                  color="primary"
                  size="large"
                  variant="outlined"
                >
                  <span v-t="terms.decline" />
                </v-btn>

                <v-btn
                  @click="acceptDecisionDialog.open()"
                  color="primary"
                  size="large"
                  variant="flat"
                >
                  <span v-t="terms.accept" />
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  class="mr-2"
                  variant="outlined"
                  disabled
                >
                  {{ t(getStatusText(enrollment.public_status)) }}
                </v-btn>

                <v-btn
                  v-if="removable"
                  @click="handleRemove()"
                  :loading="processing"
                  class="ml-2"
                  color="red"
                  variant="flat"
                >
                  <span v-t="'Remove'" />
                </v-btn>
              </template>
              <ActionMenu
                v-if="
                  showActionMenu &&
                  ((subsidyProgram && subsidyProgram.transfer_form_schema_id) || canWithdraw)
                "
                @click:action:transfer="draftTransfer"
                @click:action:withdraw="withdrawConfirmationDialog.open()"
                :items="actionItems"
                button-icon="more_vert"
                left
              />
            </v-col>
          </template>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row dense>
          <v-col class="d-flex align-center">
            <div class="d-flex align-center">
              <span
                v-t="provider?.name"
                class="c-black fw-600 fs-20"
                data-cy="enrollment-card-name"
                dense
              />
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <div class="fs-16 fw-400 mb-1">
              {{ providerAddress() }}
            </div>
            <div>
              <router-link
                :to="{ name: 'PublicProviderShow', params: { providerId: provider.id } }"
                class="fs-14 fw-400"
                color="secondary"
                target="_blank"
                small
                text
              >
                <span v-t="'View profile'" />
              </router-link>
            </div>
          </v-col>
        </v-row>

        <template v-if="programs.length > 0 && enrollment?.program_id">
          <v-divider class="my-4" />
          <v-card flat>
            <v-card-text class="pa-0">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="d-flex align-center fs-16 fw-500">
                  <v-icon
                    color="black"
                    size="20"
                    start
                  >
                    chair_alt
                  </v-icon>
                  <span
                    v-t="programs.find((program) => program.id == enrollment.program_id)?.name"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="enrollment?.due_date || enrollment?.end_date">
          <v-divider class="my-4" />
          <v-card flat>
            <v-card-text class="pa-0">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="fs-16">
                  <LongDate
                    :date="enrollment.due_date"
                    prefix="Response deadline:"
                  />
                  <LongDate
                    :date="enrollment.end_date"
                    prefix="Enrollment end date:"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="forms.length > 0">
          <v-divider class="my-4" />

          <v-card
            v-for="form in forms"
            :key="form.id"
            class="mt-4"
            border
            flat
            tile
          >
            <v-card-text>
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="d-flex align-center fs-16 fw-500">
                  <v-icon
                    color="black"
                    size="20"
                    start
                  >
                    description
                  </v-icon>
                  <v-btn
                    @click="formReviewDialog.open(form)"
                    variant="text"
                  >
                    <span v-text="store.state.schemas[form.schema_id]?.name" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>

    <DecisionDialog
      @confirm="withdraw"
      ref="declineDecisionDialog"
      :confirm-button-text="declineButtonText"
      :content="declineDialogText"
      :processing="processing"
    />

    <DecisionDialog
      @confirm="accept"
      ref="acceptDecisionDialog"
      :confirm-button-text="acceptButtonText"
      :content="acceptDialogText"
      :processing="processing"
    />

    <template v-if="subsidyProgram">
      <template v-if="subsidyProgram.transfer_form_schema_id">
        <FormDialog
          @save="transfer"
          ref="transferDialog"
          :enrollment-id="enrollment.id"
          :group-id="enrollment.group_id"
          :provider-id="enrollment.provider_id"
          :schema-id="subsidyProgram.transfer_form_schema_id"
          :subsidy-id="enrollment.subsidy_id"
        />
      </template>

      <template v-if="subsidyProgram.accept_form_schema_id">
        <FormDialog
          @save="
            updateEnrollmentStatus({ status: ENROLLMENT_STATUSES.ACCEPTED, acceptForm: $event })
          "
          ref="acceptDialog"
          :enrollment-id="enrollment.id"
          :group-id="enrollment.group_id"
          :provider-id="enrollment.provider_id"
          :schema-id="subsidyProgram.accept_form_schema_id"
          :subsidy-id="enrollment.subsidy_id"
          draft
        />
      </template>

      <template v-if="subsidyProgram?.enable_additional_parent_enrollment_withdraw">
        <DecisionDialog
          @confirm="withdraw"
          ref="withdrawConfirmationDialog"
          :confirm-button-text="withdrawButtonText"
          :content="withdrawDialogText"
          :processing="processing"
        />
      </template>

      <template v-if="subsidyProgram.withdraw_form_schema_id">
        <FormDialog
          @save="updateEnrollmentStatus({ status: ENROLLMENT_STATUSES.WITHDRAWN })"
          ref="withdrawDialog"
          :enrollment-id="enrollment.id"
          :group-id="enrollment.group_id"
          :provider-id="enrollment.provider_id"
          :schema-id="subsidyProgram.withdraw_form_schema_id"
          :subsidy-id="enrollment.subsidy_id"
        />
      </template>
    </template>

    <FormReviewDialog ref="formReviewDialog" />
    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import EditableSelectionRanking from '@/shared/components/subsidy/EditableSelectionRanking.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import { formatAddress } from '@/shared/services/address';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import FormReviewDialog from '@/shared/components/form/FormReviewDialog.vue';
import LongDate from '@/shared/components/LongDate.vue';
import SelectionRanking from '@/shared/components/subsidy/SelectionRanking.vue';
import useTerms from '@/shared/composables/useTerms';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import { useStore } from 'vuex';

const emit = defineEmits(['change:status', 'move', 'transfer', 'remove']);
const props = defineProps({
  enrollment: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: null,
  },
  length: {
    type: Number,
    default: null,
  },
  ordered: Boolean,
  outlined: Boolean,
  processing: Boolean,
  removable: Boolean,
  reorderable: Boolean,
  showActionMenu: {
    type: Boolean,
    default: true,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const display = useDisplay();
const { getStatusText } = useEnrollmentStatuses();
const eventBus = useEventBus();
const store = useStore();
const { terms } = useTerms();
const { t } = useI18n();

const acceptButtonText = ref('');
const acceptDecisionDialog = ref(null);
const acceptDialog = ref(null);
const confirmDialog = ref(null);
const declineDecisionDialog = ref(null);
const forms = ref([]);
const formReviewDialog = ref(null);
const formsLoaded = ref(false);
const programs = ref([]);
const provider = ref(null);
const transferDialog = ref(null);
const withdrawableEnrollmentStatus = ref([
  ENROLLMENT_STATUSES.SELECTED,
  ENROLLMENT_STATUSES.PROPOSED,
  ENROLLMENT_STATUSES.PLACED,
  ENROLLMENT_STATUSES.OFFERED,
  ENROLLMENT_STATUSES.ACCEPTED,
  ENROLLMENT_STATUSES.WAITLISTED,
  ENROLLMENT_STATUSES.STANDBY,
]);
const withdrawDialog = ref(null);
const withdrawConfirmationDialog = ref(null);

const acceptDialogText = computed(() => {
  if (props.subsidyProgram.enrollment_decision_accept_text) {
    return props.subsidyProgram.enrollment_decision_accept_text;
  }

  if (window.location.href.includes('pfa')) {
    return `Accepting this offer will not ${terms.value?.enroll.toLowerCase()} your child in the preschool. If you accept, we will send you more information about how to ${terms.value?.enroll.toLowerCase()} in the preschool program. Would you like to continue?`;
  }

  return `Accepting this placement will automatically change the status of all other placements to ${
    terms.value?.withdrawn
  }. By accepting this placement, you acknowledge that you intend to ${terms.value?.enroll.toLowerCase()} in this program. Would you like to continue?`;
});

const actionItems = computed(() => {
  const items = [];

  if (props.subsidyProgram.transfer_form_schema_id) {
    items.push({ event: 'transfer', title: 'Request transfer' });
  }
  if (canWithdraw.value) {
    items.push({ event: 'withdraw', title: 'Withdraw choice' });
  }

  return items;
});

const canWithdraw = computed(() => {
  return (
    props.subsidyProgram.enable_additional_parent_enrollment_withdraw &&
    withdrawableEnrollmentStatus.value.includes(props.enrollment.public_status)
  );
});

const rankingClass = computed(() => {
  if (display.mdAndUp.value) {
    return 'd-flex justify-start';
  }

  return 'd-flex justify-center';
});

const statusClass = computed(() => {
  if (display.mdAndUp.value) {
    return 'd-flex justify-end';
  }

  return 'd-flex justify-center';
});

const declineButtonText = computed(() => {
  return `Yes, ${terms.value?.decline?.toLowerCase()} placement`;
});

const declineDialogText = computed(() => {
  if (props.subsidyProgram.enrollment_decision_decline_text) {
    return props.subsidyProgram.enrollment_decision_decline_text;
  }

  if (window.location.href.includes('pfa')) {
    return `You are about to ${terms.value?.decline.toLowerCase()} this placement offer. Would you like to continue?`;
  }

  return (
    'By declining this placement, you acknowledge that this application will be returned to the ' +
    'matching algorithm and the student is not guaranteed a seat in any other selected programs. ' +
    'The student will not be matched with this program again. Would you like to continue?'
  );
});

const withdrawDialogText = computed(() => {
  return t(
    'Withdrawing this choice removes it from consideration for placement and immediately releases any seats that may be reserved in this program. Do you wish to continue?',
  );
});

const withdrawButtonText = computed(() => {
  return `Yes, ${terms.value?.withdraw.toLowerCase()} choice`;
});

const canRemoveProviderSelection = computed(() => {
  return props.length - 1 >= (props.subsidyProgram.selection_minimum || 1);
});

function accept() {
  if (props.subsidyProgram.accept_form_schema_id) {
    acceptDialog.value?.open();
  } else {
    void updateEnrollmentStatus({ status: ENROLLMENT_STATUSES.ACCEPTED });
  }
}

async function draftTransfer() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you certain you want to request a transfer?',
  );
  if (!confirm) return;

  transferDialog.value?.open();
}

async function load() {
  const providerResp = await Api.public_api.organization.provider.get(props.enrollment.provider_id);
  provider.value = providerResp?.data;

  const programResp = await Api.public_api.organization.program.index({
    provider_id: props.enrollment.provider_id,
  });
  programs.value = programResp?.data;

  const formResp = await Api.parent.form.index({ enrollment_id: props.enrollment.id });
  forms.value = formResp.data.filter(
    (form) => props.subsidyProgram.transfer_form_schema_id === form.schema_id,
  );
  formsLoaded.value = true;
}

function getAcceptButtonText() {
  return 'Yes, accept placement';
}

async function updateEnrollmentStatus({ status, acceptForm }) {
  const event = { status };

  if (acceptForm) {
    event.accept_form_id = acceptForm.id;
  }

  emit('change:status', event);
  acceptDecisionDialog.value?.close();
  declineDecisionDialog.value?.close();
}

function providerAddress() {
  if (!provider.value) return null;

  return formatAddress(provider.value.address, provider.value.city, provider.value.zip);
}

function transfer() {
  emit('transfer', 'Your transfer request has been sent');
}

function displayPreferencesRequiredError() {
  let message = `${props.subsidyProgram.selection_minimum} preferences are required. Please add more preferences.`;

  if (!props.subsidyProgram.selection_minimum || props.subsidyProgram.selection_minimum <= 1) {
    message = 'Application must have at least one preference';
  }

  eventBus.error(t(message));
}

function withdraw() {
  withdrawConfirmationDialog.value?.close();

  if (props.subsidyProgram.withdraw_form_schema_id) {
    withdrawDialog.value?.open();
  } else {
    void updateEnrollmentStatus({ status: ENROLLMENT_STATUSES.WITHDRAWN });
  }
}

function handleRemove() {
  if (!canRemoveProviderSelection.value) {
    displayPreferencesRequiredError();
    return;
  }

  emit('remove');
}

onMounted(() => {
  acceptButtonText.value = getAcceptButtonText();
  void load();
});
</script>
