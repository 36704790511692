<template>
  <div class="mb-3">
    <v-radio
      :label="radioTitle"
      :value="value"
      class="c-black mt-0 mb-1"
      color="primary"
      hide-details
    />
    <div
      v-if="item.subtitle"
      class="c-light-black fs-15 mb-1 ps-8"
    >
      <span>{{ t(item.subtitle) }}</span>
      <span
        v-show="item.text"
        @click="readMore = !readMore"
        class="ms-1 c-primary pointer"
      >
        {{ t('Read more.') }}
      </span>
    </div>
    <transition name="fade">
      <div
        v-show="item.text && readMore"
        class="c-light-black fs-15 ps-8 mt-2"
      >
        {{ t(item.text) }}
      </div>
    </transition>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
  item: {
    type: Object,
    required: true,
  },
  suffix: {
    type: String,
    default: null,
  },
  value: {
    type: [Number, String],
    default: null,
  },
});

const { t } = useI18n();

const readMore = ref(false);

const radioTitle = computed(() => {
  if (props.suffix) {
    return t([props.item.title, props.suffix].join(' '));
  }
  return t(props.item.title);
});
</script>
