<template>
  <v-card border>
    <v-card-text>
      <div
        v-if="child.editing"
        class="mb-4"
      >
        <v-row class="fs-16">
          <LabeledTextfield
            v-model="child.first_name"
            :aria-label="$t('Enter a first name')"
            :autofocus="autofocus"
            :dense="dense"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            field="first_name"
            message="First name"
            sm="4"
            mandatory
          />
          <LabeledTextfield
            v-model="child.middle_name"
            :aria-label="$t('Enter an optional middle name')"
            :dense="dense"
            :placeholder="$t('Optional')"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            field="middle_name"
            message="Middle name"
            sm="4"
          />
          <LabeledTextfield
            v-model="child.last_name"
            :aria-label="$t('Enter a last name')"
            :dense="dense"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            field="last_name"
            message="Last name"
            sm="4"
            mandatory
          />
        </v-row>
        <v-row class="fs-16">
          <LabeledDateOfBirth
            v-model="child.dob"
            :aria-label="$t('Enter a date of birth')"
            :dense="dense"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            data-cy="dob-select"
            field="dob"
            message="Date of birth"
            mandatory
          />
        </v-row>
        <v-row class="d-flex align-center">
          <v-col class="d-flex justify-end">
            <v-btn
              @click="update()"
              :disabled="invalid"
              :loading="processing"
              :ripple="false"
              class="focus-very-visible me-3"
              color="primary"
              data-cy="done-button"
            >
              <span v-t="'Done'" />
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-row
        v-else
        class="d-flex align-center"
      >
        <v-col class="d-flex align-center">
          <v-icon
            v-if="invalid"
            class="c-red me-3"
            size="26"
          >
            error_outline
          </v-icon>
          <div>
            <template v-if="child.first_name && child.last_name">
              <div
                v-text="[child.first_name, child.last_name].join(' ')"
                class="d-block fs-16 fw-600"
                data-cy="child-name"
              />
            </template>
            <template v-else>
              <div
                v-t="'Incomplete name'"
                class="d-block fs-16 fw-600 c-red"
              />
            </template>
            <template v-if="child.dob">
              <div class="d-block">
                <LongDate
                  :date="child.dob"
                  class="fs-16"
                  data-cy="child-dob"
                  timezone="UTC"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-t="'Missing date of birth'"
                class="d-block fs-16 fw-600 c-red"
              />
            </template>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-btn
            @click="child.editing = true"
            :ripple="false"
            class="focus-very-visible"
            color="primary"
            variant="text"
          >
            {{ $t('Edit') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            @click="child.included = !child.included"
            :loading="processing"
            :ripple="false"
            :variant="child.included ? 'flat' : 'outlined'"
            class="focus-very-visible"
            color="primary"
          >
            <v-icon start>
              {{ child.included ? 'check_box' : 'check_box_outline_blank' }}
            </v-icon>
            <span>
              {{ child.included ? 'Included in application' : 'Include in application' }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDate from '@/shared/components/LongDate.vue';

const props = defineProps({
  autofocus: Boolean,
  dense: Boolean,
  index: { type: Number, default: null },
  initialChild: { default: null, type: Object },
  preventUpdate: Boolean,
  readonly: Boolean,
  removeDisabled: { default: false, type: Boolean },
});

const emit = defineEmits(['change', 'removal']);

const child = ref(props.initialChild);
const processing = ref(false);

const invalid = computed(() => {
  return (
    !child.value.first_name || !child.value.last_name || !child.value.dob || !child.value.schema_id
  );
});

const update = async () => {
  processing.value = true;
  if (child.value.id) {
    const resp = await Api.child.update(child.value.id, child.value);
    const updatedChild = resp.data;
    updatedChild.included = true;
    updatedChild.editing = false;
    child.value = updatedChild;
    emit('change', child.value);
    processing.value = false;
  } else {
    const resp = await Api.child.create(child.value);
    const newChild = resp.data;
    newChild.included = true;
    newChild.editing = false;
    child.value = newChild;
    emit('change', child.value);
    processing.value = false;
  }
};

watch(
  () => props.initialChild,
  (newVal) => {
    child.value = newVal;
  },
);
</script>
