import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/public/eligibility_determinations`;
  },

  create(params) {
    return axios.post(this.baseUrl(), params).catch(handleError);
  },
};
